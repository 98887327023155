<template>
  <div class="own_h5">
    <div class="search_h5_content" v-if="projectList.length || orgList.length || fastList.length || deepList.length">
      <p class="search_h5_num">搜索包含<span>{{ searchValue }}</span>的结果<span>共{{ searchTotal1+searchTotal2 }}条</span></p>
      <div v-if="!loading1">
        <div class="search_h5_result" v-if="projectList.length">
          <p class="search_h5_result_title">项目</p>
          <div class="search_h5_result_project">
            <div
              class="search_h5_ptoject_item" 
              v-for="(item, index) in projectList" :key="index + 'ptoject'"
              :style="index % 2 != 0 && { marginRight: 0 }"
              @click="$router.push('/h5/projectDetail/'+item.id)"
            >
              <img :src="item.icon" alt="">
              <p v-html="item.title"></p>
            </div>
          </div>
        </div>
        <div class="search_h5_result" v-if="orgList.length">
          <p class="search_h5_result_title">机构</p>
          <div class="search_h5_org_item" v-for="(item, index) in orgList" :key="index + 'org'" @click="goDetail('/h5/organizationDetail/'+item.id)">
            <img :src="item.icon" alt="">
            <p v-html="item.title"></p>
          </div>
        </div>
      </div>
      <div v-else class="searchLoading">
        <van-loading color="#f0b200" :size="34" />
      </div>
      <!--  -->
      <div v-if="!loading2">
        <div class="search_h5_result" v-if="fastList.length">
          <p class="search_h5_result_title">快讯</p>
          <div class="search_h5_news_item" v-for="(item,index) in fastList" :key="index + 'flash'"  @click="goDetail('/h5/fastDetail/'+item.id)">
            <newsItem :info="item" :hideBottomBorder="true" />
          </div>
        </div>

        <div class="search_h5_result" v-if="deepList.length">
          <p class="search_h5_result_title">文章</p>
          <div class="search_h5_news_item" v-for="(item,index) in deepList" :key="index+'deep'" @click="goDetail('/h5/deepDetail/'+item.id)">
            <newsItem :info="item" :hideBottomBorder="true" />
          </div>
        </div>
      </div>
      <div v-else class="searchLoading">
        <van-loading color="#f0b200" :size="34" />
      </div>
    </div>
    <div v-else class="searchLoading">
      <p v-if="!loading1 && !loading2">暂无数据</p>
      <!-- <van-loading color="#f0b200" :size="34" v-else/> -->
    </div>
  </div>
</template>
<script>
import newsItem from './listItem';
import { searchResult1, searchResult2 } from '@/api/index';
export default {
  name: "detail",
  components: {
    newsItem
  },
  props: [ 'searchValue' ],
  data() {
    return {
      projectList: [],
      orgList: [],
      deepList: [],
      fastList: [],
      searchTotal1: 0,
      searchTotal2: 0,
      loading1: true,
      loading2: true,
    };
  },
  watch: {
    searchValue() {
      this.searchOwn();
    }
  },
  methods: {
    goDetail(route) {
      const scrollTop = document.documentElement.scrollTop;
      sessionStorage.setItem('searchPosition',scrollTop);
      this.$router.push(route)
    },
    searchOwn() {
      this.search1();
      this.search2();
    },
    search1() {
      this.loading1 = true;
      searchResult1({ keyword: this.searchValue })
      .then(res=>{
        const { data } = res;
        if(data.items && data.items.length) {
          const regex = new RegExp(this.searchValue, 'gi'); 
          const result = data.items.reduce((acc,item)=>{
            if(item._type == 'institution') {
              acc.org.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            if(item._type == 'project') {
              acc.pro.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            return acc
          },{pro:[],org:[],fast:[],deep:[]});
          this.projectList = result.pro;
          this.orgList = result.org;
          
          this.searchTotal1 = data.total;
        } else {
          this.projectList =  [];
          this.orgList =  [];
         
          this.searchTotal1 =  0;
        }
        this.loading1 = false;
      })
      .catch(()=>{
        this.loading1 = false;
      })
    },
    search2() {
      this.loading2 = true;
      searchResult2({ keyword: this.searchValue })
      .then(res=>{
        const { data } = res;
        if(data.items && data.items.length) {
          const regex = new RegExp(this.searchValue, 'gi'); 
          const result = data.items.reduce((acc,item)=>{
            if(item._type == 'flash') {
              // 创建一个正则表达式对象，g表示全局匹配，i表示不区分大小写
              acc.fast.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            if(item._type == 'article') {
              acc.deep.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            return acc
          },{pro:[],org:[],fast:[],deep:[]});
          this.fastList = result.fast;
          this.deepList = result.deep;
          this.searchTotal2 = data.total;
        } else {
          this.deepList =  [];
          this.fastList =  [];
          this.searchTotal2 =  0;
        }
        this.loading2 = false;
      })
      .catch(()=>{
        this.loading2 = false;
      })
    },
  },
  mounted() {
  },

};
</script>
<style lang="less">
.own_h5 {
  .search_h5_content {
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;

  .search_h5_num {
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    color: #9C9C9C;
    line-height: 17px;

    span {
      color: #F0B200;

      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .search_h5_result_title {
    margin: 19px 0;
    width: 48px;
    height: 21px;
    background: url('./../../../assets/h5Image/linearBg.png') no-repeat;
    background-size: 100% 100%;
    line-height: 21px;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    // border-radius: 15px
  }

  .search_h5_result_project {
    display: flex;
    flex-wrap: wrap;

    .search_h5_ptoject_item {
      width: calc(50% - 4px);
      min-height: 63px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(238, 238, 238, 1);
      border-radius: 10px;
      margin-right: 8px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      padding: 12px 10px;

      img {
        width: 38px;
        height: 38px;
        border-radius: 100%;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        color: #444444;
        line-height: 19px;
        margin-left: 19px;
        word-break: break-all;
      }
    }
  }

  .search_h5_org_item {
    width: 100%;
    min-height: 63px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(238, 238, 238, 1);
    border-radius: 10px;
    margin-right: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 12px 10px;

    img {
      width: 38px;
      height: 38px;
      border-radius: 100%;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: #444444;
      line-height: 19px;
      margin-left: 21px;
      word-break: break-all;
    }
  }
  }
  .searchLoading {
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
  