<template>
  <div class="mirror_h5">
    <p class="search_h5_num">搜索包含<span>{{ searchValue }}</span>的结果<span>共45条</span></p>
    <div v-if="listData.list.length">
      <van-list
          v-model="listData.loading"
          :finished="!listData.hasNext"
          finished-text="没有更多了"
          @load="onLoad"
        >
        <a class="otherItem" v-for="item in listData.list" :href="item.homepage" target="_blank">
          <p class="otherItemTitle showline1" v-html="item.title"></p>
          <div class="otherItemContent">
            <p class="showline3" v-html="item.news_describe"></p>
            <img :src="item.image" alt="" v-if="item.image"/>
          </div>
          <div class="otherItemAuthor">
            <img :src="item.avatar || require('./../../../assets/h5Image/mirror.png')" alt="" />
            <p>{{ item.nickname }} · {{ $dayjs(new Date(item.creat_time)).format('YYYY/MM/DD') }}</p>
          </div>
        </a>
      </van-list>
    </div>
    <div v-else class="searchLoading">
      <van-loading color="#f0b200" :size="34" v-if="listData.loading"/>
      <p v-else>暂无数据</p>
    </div>
  </div>
</template>
<script>
import newsItem from './../deep/component/listItem';
import { searchMirror } from '@/api/index';
export default {
  props: [ 'searchValue' ],
  name: "detail",
  components: {
    newsItem
  },
  data() {
    return {
      listData: {
        list: [],
        page: 1,
        loading: false,
        hasNext: true
      },
      loadMore: false,
      searchTotal: 0,
    };
  },
  watch: {
    searchValue() {
      this.listData = {
        list: [],
        page: 1,
        loading: true,
        hasNext: true
      },
      this.loadMore = false,
      this.searchTotal = 0,
      this.searchOther();
    }
  },
  methods: {
    searchOther(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      searchMirror({ keyword: this.searchValue,page: this.listData.page })
      .then(res=>{
        const data = res.data;
        let result = []
        if(data.data && data.data.length) {
          result = data.data.map(item=>{
            return {
              ...item,
              title: item.title.replaceAll(this.searchValue,`<span style='color:#F0B200'>${this.searchValue}</span>`),
              news_describe: item.news_describe.replaceAll(this.searchValue,`<span style='color:#F0B200'>${this.searchValue}</span>`),
            }
          })
        }
        if(loadMore) {
          this.listData.list = this.listData.list.concat(result);
          this.loadMore = false;
        } else {
          this.listData.list = result;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = (result.length != 0);
        this.listData.loading = false;
      })
      .catch(()=>{
        this.listData.loading = false;
      })
    },
    onLoad() {
      if(!this.loadMore && this.listData.page!=1) {
        this.loadMore = true;
        this.searchOther(true)
      }
    },
  },
  mounted() {
  },
};
</script>
<style lang="less">
.mirror_h5 {
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;

  .search_h5_num {
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    color: #9C9C9C;
    line-height: 17px;

    span {
      color: #F0B200;

      &:last-child {
        padding-left: 5px;
      }
    }
  }
  .listView {
    margin-bottom: 15px;
  }
  .otherItem {
    display: block;
    width: 100%;
    margin-bottom: 11px;
    cursor: pointer;
    .otherItemTitle {
      font-size: 16px;
      font-weight: 600;
      color: #444444;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .otherItemContent {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      p {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: #9C9C9C;
        line-height: 23px;
        word-break: break-all;
      }
      img {
        width: 119px;
        height: 79px;
        flex-shrink: 0;
        margin-left: 11px;
        border-radius: 11px;
      }
    }
    .otherItemAuthor {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 10px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        color: #737373;
      }
    }
  }
  .moreDeepBtn {
      margin-left: 64px;
      margin-right: 99px;
      margin-bottom: 15px;
      margin-top: 30px;
      height: 38px;
      line-height: 38px;
      background: #f3f2f0;
      border-radius: 19px;
      font-size: 15px;
      font-weight: 600;
      color: #9c9c9c;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
  }

  .searchLoading {
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
  