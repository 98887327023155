<template>
  <div style="overflow:auto;height:100vh" ref="deepDetailScroll">
    <div class="deepDetail">
      <div class="deepDetail_left" v-if="info.id">
        <div class="backBtn" @click="$router.go(-1)">
          <img src="@/assets/images/back.png" alt="" />
          <p>深度列表</p>
        </div>
        <p class="detailTitle">{{ info.title }}</p>
        <div class="author" v-if="info.author" @click="$router.push('/author/'+info.author.id)">
          <!-- <img :src="info.author.logo || require('@/assets/images/phoneicon.png')" alt=""/> -->
          <p>{{ info.author.name }}  · {{ $dayjs(info.publishTime).format('YYYY-MM-DD HH:mm') }}</p>
        </div>
        <div class="detailTag">
          <p v-for="(item,index) in info.tags" :key="index">{{ item.name }}</p>
        </div>
        <div class="detailTxt" v-html="info.content"></div>
        <div v-if="info.relates && info.relates.length" style="display: flex; align-items: stretch;flex-wrap: wrap;">
          <div class="otherPro" v-for="rel in info.relates" @click="$router.push('/projectDetail/'+rel.id)" :key="rel.id">
            <div class="infoView">
              <img :src="rel.icon" alt="" />
              <p>{{ rel.title }}</p>
            </div>
            <p class="otherProIntro">{{ rel.excerpt }}</p>
          </div>
        </div>
        <p class="bottomTips" v-if="info.author">作者：{{ info.author.name }}</p>
        <p class="bottomTips">本文不代表WEB3CN立场，不承担法律责任。文章及观点也不构成投资意见。</p>
        <p class="bottomTips" v-if="info.author">图片来源：{{ info.author.name }} 如有侵权，请联系网站删除。</p>
      </div>
      <div class="deepDetail_left deepDetail_loading" v-else>
        <van-loading color="#f0b200"  :size="54"/>
      </div>
      <div class="deepDetail_right">
        <div class="stickyView">
          <newFastmessage />
        </div>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.deepDetailScroll" />
  </div>
</template>
<script>
import newFastmessage from '../components/newFastmessage';
import { getDetail } from '@/api/index';
import footerView from './../components/footerView';
export default {
  name: "detail",
  components: {
    newFastmessage,
    footerView
  },
  data() {
    return {
      info: {}
    };
  },
  methods: {
    getDetailData(id) {
      getDetail(id).then(res=>{
        document.title = res.data.title;
        this.info = res.data;
      })
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDetailData(id);
    }
  },
 
};
</script>
<style lang="less">
.deepDetail {
  padding-top: 37px;
  display: flex;
  width: 1220px;
  min-height: calc(100vh - 59px);
  margin: 0 auto;
  .deepDetail_loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .deepDetail_left {
    width: 786px;
    .backBtn {
      display: flex;
      align-items: center;
      width: 80px;
      cursor: pointer;
      margin-bottom: 45px;
      img {
        width: 10px;
        height: auto;
        margin-right: 8px;
      }
      p {
        font-size: 15px;
        font-weight: 500;
        color: #9C9C9C;
      }
    }
    .detailTitle {
      font-size: 21px;
      font-weight: 600;
      color: #000;
      line-height: 36px;
      margin-top: 45px;
      margin-bottom: 23px;
    }
    .author {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      img {
        width: 25px;
        height: 25px;
        border-radius: 25px;
        margin-right: 5px;
        border-radius: 100%;
        box-shadow: 0px 2px 8px 0px #eeeeee;
      }
      p {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #737373;
      }
    }
    .detailTag {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 38px;
      p {
        padding: 3px 9px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #737373;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #FFF6D8;
      }
    }
    .detailTxt {
      width: 100%;
      font-size: 1rem!important;
      // font-weight: 500;
      color: #4a4a4a;
      line-height: 26px;
      text-align: justify;
      margin-bottom: 30px;
      font-family: auto;
      * {
        word-break: break-all;
        // font: revert
      }
      a {
        color: #CDCDCD;
        text-decoration: underline;
      }
      img {
        min-width: 110px;
        display: block;
        max-width: 100%;
        margin: auto;
      }
      p {
        margin-bottom: 10px;
        line-height: 2.08333rem;
        word-break: break-all!important;
        word-wrap: break-word!important;
        text-align: justify!important;
        margin: 0 0 1.25rem;
      }
      h1{
        font-size: 1.3rem !important;
        line-height: 2.08333rem;
        margin: 0 0 16px;
      }
      ul, ol {
        padding-left: 2.5em;
        list-style: auto;
      }
    }
    .otherPro {
      margin-right: 17px;
      flex: 1;
      min-width: 40%;
      background: rgba(206,202,190,0.2);
      padding: 23px 15px 17px 15px;
      margin-bottom: 30px;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        box-shadow: 0 -12px 20px -6px rgba(140,150,162,.2);
        border-radius: 6px;
        z-index: 2;
      }
      .infoView {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin-right: 15px;
          box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
        }
        p {
          font-size: 17px;
          font-weight: 600;
          color: #000;
        }
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    .otherProIntro {
      font-size: 14px;
      font-weight: 500;
      color: #444444;
      line-height: 23px;
      word-break: break-all;
      text-align: justify;
    }
    .bottomTips {
      font-size: 14px;
      font-weight: 500;
      color: #9C9C9C;
      line-height: 26px;
      // margin-bottom: 15px;
    }
    
  }
  .deepDetail_right {
    flex-shrink: 0;
    width: 374px;
    margin-left: 60px;
    .stickyView {
      position: sticky;
      top: 37px;
    }
  }
}
</style>
  