<template>
  <div class="infinite-list-wrapper" style="overflow:auto;height:100vh" ref="projectScroll">
    <div class="projectLib_page" v-infinite-scroll="load" :infinite-scroll-disabled="!tableData.hasNext" :infinite-scroll-distance="100">
      <div class="projectLib_title">
        <p>热门项目</p>
        <img src="@/assets/images/projectLib/hot.png" alt="">
      </div>
      <!-- swiper -->
      <div class="swiper-container hotProswiper" v-if="hotList.length">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(it,index) in hotList" :key="index+'hot'">
            <div class="swiper-slide_item"  v-for="(item,index) in it"  @click="goDetail(item,index)">
              <div class="swiperLeft">
                <img :src="item.icon" alt="">
                <div>
                  <p>{{ item.title }}</p>
                  <span>{{ item.type }}</span>
                </div>
              </div>
              <div class="swiperRight">
                <p>{{ item.financingAmount }}</p>
                <span>融资金额</span>
              </div>
            </div>
          </div>
        </div>
        <div class="swiperBtn0" @click="slideSwiper(1)" v-if="showPreBtn"></div>
        <div class="swiperBtn" @click="slideSwiper(2)" v-if="(hotList.flat(1)).length > 4 && showNextBtn"></div>
      </div>
      <div v-else class="swiperNodata">
        <van-loading color="#f0b200"  :size="54"/>
      </div>
      <div class="allPrijectTitle">
        <div class="projectLib_title" style="width: 75px">
          <p>全部</p>
        </div>
        <p class="projectNumber">项目数<span>{{ totalNums }}</span></p>
      </div>
      <div class="nav_swiper">
        <p v-for="(item,index) in tabList" :key="index" :class="`nav_item ${tabIndex==item.id && 'nav_item_active'}`" @click="clickTab(item)">{{ item.name }}</p>
      </div>
      <!-- list -->
      <div class="projectLibTable">
        <div class="tableView">
          <div class="tableTitle">
            <p class="tableTd1">项目名称</p>
            <p class="tableTd2" style="padding-left: 8px">赛道</p>
            <p class="tableTd3">融资时间</p>
            <p class="tableTd4">融资金额</p>
            <p class="tableTd5">融资状态</p>
            <p class="tableTd6">投资机构</p>
            <p class="tableTd7"></p>
          </div>
          <div v-if="tableData.list.length">
            <div class="tableItem" v-for="(item,index) in tableData.list" :key="index" @click="goDetail(item,index)">
              <div class="tableTd1 itemTd1">
                <img :src="item.icon" alt="" />
                <p>{{item.title}}</p>
              </div>
              <div class="tableTd2 itemTd2" v-if="tabIndex===null">
                <p v-if="item.tags && item.tags.length">{{ item.tags[0].name }}</p>
              </div>
              <div class="tableTd2 itemTd2" v-else>
                <p v-if="item.tags && item.tags.length">{{ getOtherTag(item.tags) }}</p>
              </div>
              <p class="tableTd3 itemTd3">{{ $dayjs(item.financingTime).format('YYYY/MM/DD') }}</p>
              <p class="tableTd4 itemTd4">{{item.financingAmount}}</p>
              <p class="tableTd5 itemTd5">{{ item.stage }}</p>
              <p class="tableTd6 itemTd6 showline1">
                <span v-for="(it,index) in item.institutions">&emsp;{{ it.name }}</span>
              </p>
              <div class="tableTd7 itemTd7">
                <img src="@/assets/images/projectLib/tableitemPoint.png" alt="" class="itemDetailPoint">
              </div>
            </div>
          </div>
          <div class="loadingView" v-else>
            <van-loading color="#f0b200" v-if="tableData.loading" :size="54"/>
            <p v-else>暂无数据</p>
          </div>

        </div>
        <div class="moreDeepBtn" v-if="tableData.hasNext">
          <span>加载更多</span>
          <div class="moreFastMsg_loading" v-if="loadMore">
            <van-loading color="#9c9c9c" size="44px"/>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.projectScroll"/>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { getCategories, getProjectList } from '@/api/index'
import footerView from './../components/footerView'
export default {
  name: "topic",
  components: {
    footerView
  },
  data() {
    return {
      hotList: [],
      tabIndex: '',
      tabName: '全部',
      tabList: [],
      tableData: {
        page: 1,
        size: 15,
        list: [],
        hasNext: false,
        loading: true
      },
      showPreBtn: false,
      showNextBtn: true,
      swiperProObj: null,
      loadMore: false,
      totalNums: 0,
    };
  },
  watch: {
    tabIndex(newVal,oldVal) {
      if(oldVal!=='') {
        this.tableData = {
          page: 1,
          size: 15,
          list: [],
          hasNext: false,
          loading: true
        }
        this.$nextTick(()=>{
          this.getListData();
        })
      }
    },
  },
  mounted() {
    this.getHot();
    this.getTabs();
  },
  methods: {
    getOtherTag(data) {
      const res = data.filter(item=>item.name==this.tabName);
      return res.length ? res[0].name : ''
    },
    clickTab(val) {
      this.tabIndex = val.id;
      this.tabName = val.name;
    },
    initSwiper() {
      const self = this;
      this.swiperProObj = new Swiper('.hotProswiper', {
        loop: false,
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 21,
        on:{
          slideChangeTransitionEnd: function(){
            if(this.activeIndex == 0) {
              self.showPreBtn = false;
            }
            if(this.activeIndex > 0) {
              self.showPreBtn = true;
            }
            if(this.activeIndex < self.hotList.length-1) {
              self.showNextBtn = true;
            }
            if(this.activeIndex == self.hotList.length-1) {
              self.showNextBtn = false;
            }
          },
        },
      })
    },
    slideSwiper(type) {
      if(type==1) {
        this.swiperProObj.slidePrev();
      } else {
        this.swiperProObj.slideNext();
      }
    },
    getHot() {
      getProjectList({pinned: true})
      .then(res=>{
        this.loading = false;
        if(res.data && res.data.items) {
          this.hotList = this.$utils.chunk(res.data.items,4);
          this.$nextTick(()=>{
            this.initSwiper();
          })
        }
      })
    },
    getTabs() {
      getCategories('project')
      .then(res=> {
        const { data } = res;
        if(data) {
          if(data.items.length) {
            this.tabList = [{name:'全部',id: null}].concat(data.items);
            this.tabIndex = null;
          }
          this.getListData();
        }
      })
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.tableData.loading = true;
      }
      let params = {
        page: this.tableData.page,
        size: this.tableData.size,
        order: 'SORT_VALUE',
        orderSort: true
      }
      if(this.tabIndex !== null) {
        params.category = this.tabIndex
      }
      getProjectList(params)
      .then(res=> {
        const data = res.data;
        const result = data.items;
        if(loadMore) {
          this.tableData.list = this.tableData.list.concat(result);
          this.loadMore = false;
        } else {
          this.tableData.list = result;
        }
        this.totalNums = res.data.totalPages * this.tableData.size;
        this.tableData.page = this.tableData.page+1;
        this.tableData.hasNext = data.hasNext;
        this.tableData.loading = false;
      })
    },
    load() {
      if(!this.loadMore) {
        this.loadMore = true;
        setTimeout(() => {
          this.getListData(true)
        }, 500);
      }
    },
    goDetail(item,index) {
      // localStorage.setItem('PROJECT_LIST',JSON.stringify(this.tableData.list))
      // this.$router.push('/projectDetail/'+item.id);
      window.open(window.location.origin+'/projectDetail/'+item.id)
    }
  },
 
 
};
</script>
<style lang="less">
.projectLib_page {
  padding-top: 30px;
  width: 1220px;
  margin: 0 auto;
  .projectLib_title {
    width: 117px;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    background: url('../../../assets/images/projectLib/projectlibTitleicon.png') no-repeat left bottom / 100% 15px;
    margin-bottom: 15px;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
    }
    img {
      width: 15px;
      height: 19px;
      margin-left: 4px;
    }
  }
  .swiperNodata {
    width: 100%;
    height: 113px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #999;
    }
  }
  .hotProswiper {
    width: 100%;
    height: 113px;
    margin-bottom: 30px;
    .swiper-slide {
      width: 100%;
      display: flex;
    }
    .swiper-slide_item {
      width: calc(25% - 15.75px);
      background: url('./../../../assets/images/projectLib/swiperBg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 15px;
      box-sizing: border-box;
      cursor: pointer;
      margin-right: 21px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        .swiperLeft img {
          width: 65px;
          height: 65px;
        }
      }
      .swiperLeft {
        flex: 1;
        display: flex;
        align-items: center;
        img {
          width: 54px;
          height: 54px;
          margin-right: 8px;
          border-radius: 100%;
          box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.5);
          transition: .3s;
        }
        p {
          font-size: 16px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 21px;
        }
        span {
          font-size: 12px;
          color: #FFFFFF;
        }
      }
      .swiperRight {
        height: 100%;
        text-align: right;
        p {
          font-size: 17px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 23px;
        }
        span {
          font-size: 12px;
          color: #FFFFFF;
        }
      }
    }
    .swiperBtn0 {
      position: absolute;
      left: 15px;
      top: 50%;
      margin-top: -17px;
      width: 34px;
      height: 34px;
      background: url('../../../assets/images/projectLib/slideSwiper.png') no-repeat center center / 100% 100%;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
      transform: rotate(180deg);
    }
    .swiperBtn {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -17px;
      width: 34px;
      height: 34px;
      background: url('../../../assets/images/projectLib/slideSwiper.png') no-repeat center center / 100% 100%;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
    }
  }
  .allPrijectTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .projectNumber {
      font-size: 15px;
      font-weight: 600;
      color: #4A4A4A;
      span {
        padding-left: 5px;
        color: #F0B200;
      }
    }
  }
  .nav_swiper {
    width: 100%;
    max-height: 228px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
    border-radius: 11px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 23px;
    overflow-y: auto;
    .nav_item {
      padding: 16px 20px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #737373;
      line-height: 21px;
      cursor: pointer;
      &:hover {
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
        border-radius: 11px;
        color: #F0B200;
      }
    }
    .nav_item_active {
      background: #F0B200;
      box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
      border-radius: 11px;
      color: #fff;
      &:hover {
        color: #F0B200;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #F0B200;
      border-radius: 11px;
      width: 6px;
    }
    
  }
  .tabView {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
    border-radius: 11px;
    padding-top: 16px;
    padding-left: 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .tabItem  {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #737373;
      margin-right: 30px;
      cursor: pointer;
      margin-right: 56px;
      margin-bottom: 16px;
      padding: 2px 3px;
      box-sizing: border-box;
      &:hover {
        color: #9c9c9c;
      }
    }
  }
  // 
  .projectLibTable {
    width: 100%;
    min-height: calc(100vh - 316px);
    .tableView {
      width: 100%;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      margin-bottom: 30px;
      .tableTitle {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 22px;
        padding-right: 32px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #000;
        }
        .timeTd {
          cursor: pointer;
          img {
            width: 8px;
            height: 17px;
            margin-left: 8px;
          }
        }
      }
      .tableItem {
        width: 100%;
        min-height: 80px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 22px;
        border-top: 1px solid #EDEDED;
        &:hover {
          background: linear-gradient(270deg,#fff,#FFE8A6);
          box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
          .itemTd7 .itemDetail {
            display: flex;
          }
        }
        .itemTd1 {
          display: flex;
          align-items: center;
          img {
            width: 54px;
            height: 54px;
            margin-right: 15px;
            border-radius: 100%;
            box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
          }
          p {
            font-size: 12px;
            font-weight: 600;
            color: #000;
          }
        }
        .itemTd2 {
          display: flex;
          flex-wrap: wrap;
          margin-right: 8px;
          p {
            padding: 3px 13px;
            background: #FFF6D8;
            font-size: 12px;
            font-weight: 600;
            color: #000;
            transform: scale(0.9);
            margin-right: 8px;
          }
        }
        .itemTd3,.itemTd4,.itemTd5,.itemTd6 {
          font-size: 12px;
          font-weight: 600;
          color: #000;
        }
        .itemTd6 {
          height: 30px;
          line-height: 30px;
        }
        .itemTd7 {
          min-height: 71px;
          position: relative;
          .itemDetailPoint {
            width: 31px;
            height: 17px;
          }
          .itemDetail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #F0B200;
            border-radius: 8px;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 7px;
              height: 12px;
              margin-left: 7px;
            }
            p {
              font-size: 12px;
              color: #FFFFFF;
            }
          }
        }
      }
      .tableTd1 {
        width: 207px;
        margin-right: 8px;
      }
      .tableTd2 {
        width: 240px;
        margin-right: 8px;
      }
      .tableTd3 {
        width: 120px;
        text-align: center;
        margin-right: 8px;
      }
      .tableTd4 {
        width: 100px;
        text-align: left;
        margin-right: 8px;
      }
      .tableTd5 {
        width: 100px;
        text-align: left;
        margin-right: 8px;
      }
      .tableTd6 {
        flex: 1;
        margin-right: 8px;
        text-align: left;
      }
      .tableTd7 {
        width: 94px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .moreDeepBtn {
      margin-left: 50px;
      margin-right: 26px;
      height: 49px;
      line-height: 49px;
      text-align: center;
      background: #F3F2F0;
      border-radius: 11px;
      font-size: 15px;
      font-weight: 600;
      color: #9C9C9C;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
    .loadingView {
      width: 100%;
      min-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
  }
}
</style>
  