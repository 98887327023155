<template>
  <div class="porject_detail_h5_info">
    <div class="detail_info_year_picker" @click="showPicker = true" v-if="dateList.length">
      <p>{{ dateValue }}年</p>
      <img src="@/assets/images/projectDetail/bottom.png" alt="">
    </div>
    <div v-if="listData.list.length">
      <van-list
        v-model="listData.loading"
        :finished="!listData.hasNext"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="timelineh5View">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in listData.list" :key="index">
              <div class="timelineItem">
                <div class="itemDate" v-if="showDate(item,index)">
                  <p style="color: #737373;font-size: 13px;" v-if="showMonth(item,index)">{{ $dayjs(item.publishTime).format('MM') }}月</p>
                  <p>{{ $dayjs(item.publishTime).format('DD') }}日</p>
                </div>
                <p class="itemHour">{{ $dayjs(item.publishTime).format('hh:mm') }}</p>
                <div class="itemContent" @click="goDetail(item)">
                  <div class="itemContentTxt">
                    <div class="itemTitle">
                      <!-- <span style="padding-top: 5px">
                        <img src="@/assets/images/projectDetail/gf.png" alt="" v-if="item.official">
                        <img src="@/assets/images/kxicon.png" alt="" v-else-if="item._type=='flash'">
                      </span> -->
                      <span class="offical" v-if="item.official"></span>
                      <span class="flash" v-else-if="item._type=='flash'"></span>
                      <span class="yanbao" v-else-if="item.report"></span>
                      {{ item.title }}
                    </div>
                    <div class="itemTxt">
                      <p v-html="item.excerpt"></p>
                      <img :src="item.cover" alt="" class="itemContentLogo" v-if="item.cover"/>
                    </div>
                  </div>
                  
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </van-list>
    </div>
    <div class="loadingView" v-else>
      <van-loading color="#f0b200" v-if="listData.loading" :size="34"/>
      <p v-else>暂无数据</p>
    </div>
    <van-popup
      v-model="showPicker"
      position="bottom"
    >
      <van-picker
        title=""
        show-toolbar
        :columns="dateList"
        @confirm="onConfirm"
        @cancel="showPicker=false"
      />
    </van-popup>
  </div>
</template>
<script>
import { getProjectDetail, getProjectDynamic } from '@/api/index';
export default {
  name: "detaildynamic",
  data() {
    return {
      dateValue: '',
      dateList: [],
      loadMore: false,
      listData: {
        list: [],
        page: 1,
        size: 10,
        loading: true,
        hasNext: false,
      },
      showPicker: false
    };
  },
  methods: {
    onConfirm(val) {
      this.dateValue = val;
      this.showPicker = false;
      this.listData = {
        list: [],
        page: 1,
        size: 10,
        loading: true,
        hasNext: false,
      };
      this.$nextTick(()=>{
        this.getListData()
      })
    },
    showDate(item,index) {
      let type = false;
      if(index == 0) {
        type = true;
      } else {
        if(this.$dayjs(item.publishTime).format('DD') != this.$dayjs(this.listData.list[index-1].publishTime).format('DD')) {
          type = true
        }
      }
      return type
    },
    showMonth(item,index) {
      let type = false;
      if(index == 0) {
        type = true;
      } else {
        if(this.$dayjs(item.publishTime).format('YYYY/MM') != this.$dayjs(this.listData.list[index-1].publishTime).format('YYYY/MM')) {
          type = true
        }
      }
      return type
    },
    onLoad() {
      if(!this.loadMore  && this.listData.page!=1) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      getProjectDynamic({
        relate: this.$route.params.id,
        year: this.dateValue,
        page: this.listData.page,
        size: this.listData.size,
      })
      .then(res=> {
        const data = res.data;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(data.items);
          this.loadMore = false;
        } else {
          this.listData.list = data.items;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    getDate(id) {
      getProjectDetail(id+'/post_years')
      .then(res=>{
        if(res.data) {
          this.dateList = res.data;
          this.dateValue = res.data[0];
          if(!res.data.length) {
            this.listData.loading = false;
            return 
          }
          this.$nextTick(()=>{
            this.getListData()
          })
        }
      })
    },
    goDetail(item) {
      if(item._type == 'article') {
        this.$router.push('/h5/deepDetail/'+item.id)
      }
      if(item._type == 'flash') {
        this.$router.push('/h5/fastDetail/'+item.id)
      }
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDate(id);
    }
  },

};
</script>
<style lang="less">
.porject_detail_h5_info {
  .detail_info_year_picker {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    p {
      font-size: 20px;
      font-weight: 500;
      color: #737373;
      line-height: 28px;
      margin-right: 14px;
    }
    img {
      width: 13px;
    }
  }
  .timelineh5View {
    width: 100%;
    padding-left: 40px;
    box-sizing: border-box;
    margin-bottom: 19px;

    li {
      list-style: none;
    }

    .timelineItem {
      width: 100%;
      background: #FAFAFA;
      border-radius: 19px;
      padding: 8px 32px 9px 13px;
      position: relative;
      .itemDate {
        position: absolute;
        top: 0;
        left: -55px;
        width: 35px;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-align: right;
        line-height: 18px;
      }

      .itemHour {
        font-size: 13px;
        font-weight: 500;
        color: #CECABE;
        margin-bottom: 10px;
      }

      .itemContent {
        display: flex;
        .itemContentTxt {
          flex: 1;
          .itemTitle {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 600;
            color: #000;
            line-height: 21px;
            text-align: justify;
            span {
              width: 35px;
              height: 21px;
              display: inline-block;
              vertical-align: top;
            }
            .offical {
              background: url('./../../../assets/images/projectDetail/gf.png') no-repeat left center / auto 15px;
            }
            .flash {
              background: url('./../../../assets/images/kxicon.png') no-repeat left center / auto 15px;
            }
            .yanbao {
              background: url('./../../../assets/images/projectDetail/yb.png') no-repeat left center / auto 15px;
            }
          }

          .itemTxt {
            display: flex;
            p {
              flex: 1;
              font-size: 13px;
              font-weight: 500;
              color: #444;
              line-height: 24px;
              text-align: justify;
              word-break: break-all;
            }
            .itemContentLogo {
              margin-top: 5px;
              width: 114px;
              height: 75px;
              margin-left: 14px;
              border-radius: 10px;
            }
          }
        }
      }

      &:hover {
        .itemContentTxt {
          .itemTitle span {
            color: #737373;
          }

          .itemTxt {
            color: #9C9C9C;
          }
        }
      }
    }

    // ele css
    .el-timeline-item {
      padding-bottom: 1rem;
    }

    .el-timeline-item__node {
      background: #fff;
      border: 2px solid #F0B200;
    }

    .el-timeline-item__tail {
      border-left: 2px solid rgba(206, 202, 190, 0.2);
    }
    .el-timeline-item__wrapper {
      padding-left: 16px;
    }
  }
  .loadingView {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
  