<template>
  <div style="overflow:auto;height:100vh" ref="orgDetailScroll">
    <div class="orgDetail_page">
      <div class="detailIntroduce">
        <div class="projectLogo">
          <img :src="info.icon" alt="">
        </div>
        <div class="projectInfo">
          <div class="info1">
            <p>{{ info.title }}</p>
            <span>{{ info.fundedYear }}</span>
          </div>
          <p class="info2">{{ info.excerpt }}</p>
          <div class="info3">
            <a :href="info.contact.medium" target="_blank"><img src="@/assets/images/orgDetail/web.png" alt=""></a>
            <a :href="info.contact.twitter" target="_blank"><img src="@/assets/images/orgDetail/twitter.png" alt=""></a>
            <a :href="info.contact && info.contact.medium" target="_blank" v-if="info.contact && info.contact.medium" ><img src="@/assets/images/detailmedium.png" alt=""></a>
          </div>
        </div>
      </div>
      <div class="projectDetail_Content">
        <p class="orgNumber">投资回报率</p>
        <div class="returnPrecent">
          <div class="swiper-container returnPrecentSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide returnItem" v-for="(item,index) in info.rois" :key="index+'return'">
                <p class="times">{{ item.roi }}倍</p>
                <div>
                  <span class="bgpercent" :style="{height: ((80-index*2)/100)*98 +'px'}"></span>
                  <span class="itemBorder"></span>
                </div>
                <div @click="$router.push(`/projectDetail/project_${item.name.replace(/[^\w]|_/g, '').toLowerCase()}`)">
                  <img :src="item.logo" alt="" />
                </div>
                <p class="proName">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <p class="orgNumber">投资组合<span>{{ info.projects.length || '' }}</span></p>
        <div class="orgTable">
          <div class="tableView">
            <div class="tableTitle">
              <p class="tableTd1">项目名称</p>
              <p class="tableTd2" style="padding-left: 8px">赛道</p>
              <p class="tableTd3">融资金额</p>
              <p class="tableTd4">私募价格</p>
              <p class="tableTd5">私募回报率</p>
              <p class="tableTd6">投资机构</p>
              <p class="tableTd6">生态</p>
            </div>
            <div v-if="info.projects.length">
              <div class="tableItem" v-for="(item,index) in info.projects" :key="index" @click="goProDetail(item)">
                <div class="tableTd1 itemTd1">
                  <img :src="item.logo" alt="">
                  <div>
                    <p>{{ item.name }}</p>
                    <span>{{ item.ticker }}</span>
                  </div>
                </div>
                <div class="tableTd2 itemTd2">
                  <p v-for="(it,index) in item.tags" :key="index+'tag'">{{ it }}</p>
                </div>
                <p class="tableTd3 itemTd3">{{ item.privateRaise }}</p>
                <p class="tableTd4 itemTd4">{{ item.privatePrice }}</p>
                <p class="tableTd5 itemTd5">{{ item.privateRoi }}倍</p>
                <div class="tableTd6 itemTd6 orgView">
                  <div class="orgImg">
                    <img v-for="(i,a) in item.institutions" :src="i.logo" alt="" :style="{left: 15*a+'px',zIndex: 3-a+'' }" v-show="a < 3"/>
                  </div>
                  <p>+{{ item.institutions.length }}</p>
                </div>
                <p class="tableTd6 itemTd6">{{ item.blockChains.join('') }}</p>
              </div>
            </div>
            <div class="loadingView" v-else>
              <van-loading color="#f0b200" v-if="loading" :size="54"/>
              <p v-else>暂无数据</p>
            </div>

          </div>
          <!-- <div class="moreDeepBtn" @click="loadMoreList" v-if="tableData.isEnd">
            <span>加载更多</span>
            <div class="moreFastMsg_loading" v-if="loadMore">
              <van-loading color="#9c9c9c" size="44px"/>
            </div>
          </div> -->
        </div>
      </div>
      <div style="height: 150px"></div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.orgDetailScroll"/>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { getOrgDetail } from '@/api/index';
import footerView from './../components/footerView';
export default {
  name: "detail",
  components: {
    footerView
  },
  data() {
    return {
      loading: true,
      info: {
        contact: {},
        rois: [], // 回报率
        projects: [],
      }
     
    };
  },
 
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getInfo(id);
    }
  },
  methods: {
    initSwiper() {
      this.swiperObj = new Swiper('.returnPrecentSwiper', {
        slidesPerView: 'auto',
        spaceBetween: 30,
        freeMode:  true
      })
    },
    getInfo(id) {
      getOrgDetail(id).then(res=>{
        document.title = res.data.title;
        this.info = res.data;
        this.loading = false;
        this.$nextTick(()=>{
          this.initSwiper();
        })
      })
    },
    goProDetail(item,index) {
      // localStorage.setItem('PROJECT_LIST',JSON.stringify(this.tableData.list))
      // this.$router.push('/projectDetail/'+item.id);
      window.open(window.location.origin+'/projectDetail/'+item.id)
    }
  },
  
 
};
</script>
<style lang="less">
.orgDetail_page {
  margin: 0 auto;
  .detailIntroduce {
    width: 100%;
    height: 188px;
    background: url('./../../../assets/images/orgDetail/detailtopbg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 42px;
    .projectLogo {
      width: 90px;
      height: 90px;
      border-radius: 100%;
      overflow: hidden;
      box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.5);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .projectInfo {
      margin-left: 23px;
      .info1 {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        p {
          font-size: 23px;
          font-weight: 600;
          color: #fff;
          margin-right: 8px;
          line-height: 31px;
        }
        span {
          padding: 2px 11px;
          background: #FFF6D8;
          font-size: 12px;
          color: #000;
        }
      }
      .info2 {
        font-size: 14px;
        font-weight: 600;
        color: #9C9C9C;
        margin-bottom: 15px;
      }
      .info3 {
        display: flex;
        align-items: center;
        a {
          width: 23px;
          height: 23px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .projectDetail_Content {
    width: 1220px;
    margin: 0 auto;
    .orgNumber {
      font-size: 17px;
      font-weight: 600;
      color: #444444;
      margin-bottom: 30px;
      span {
        padding-left: 8px;
        color: #9C9C9C;
      }
    }
    .returnPrecent {
      width: 100%;
      min-height: 289px;;
      background: #FFFFFF;
      box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
      border-radius: 11px;
      margin-bottom: 34px;
      padding: 38px 32px;
      display: flex;
      align-items: stretch;
      .returnItem {
        width: 100px !important;
        .times {
          font-size: 15px;
          font-weight: 600;
          color: #000000;
          text-align: center;
          margin-bottom: 8px;
        }
        div {
          margin: 0 auto; 
          width: 21px;
          height: 98px;
          border-radius: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .bgpercent {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(180deg, #FFD14D 0%, #FFFFFF 100%);
            border-radius: 11px;
          }
          .itemBorder {
            width: auto;
            height: 100%;
            border-left: 1px dashed #ADB0C2;
          }
        }
        img {
          display: block;
          margin: 0 auto;
          width: 54px;
          height: 54px;
          border-radius: 100%;
          box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
          cursor: pointer;
        }
        .proName {
          font-size: 12px;
          color: #000;
          font-weight: 600;
          line-height: 16px;
          margin-top: 5px;
          text-align: center;
          word-break: break-all;
          display: none;
        }
        &:hover {
          .proName {
            display: block;
          }
        }
      }
    }
  
    .orgTable {
      width: 100%;
      min-height: calc(100vh - 316px);
      .tableView {
      width: 100%;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      margin-bottom: 30px;
      .tableTitle {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 22px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #000;
          line-height: 19px;
        }
        .timeTd {
          cursor: pointer;
          img {
            width: 8px;
            height: 17px;
            margin-left: 8px;
          }
        }
      }
      .tableItem {
        width: 100%;
        min-height: 80px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 22px;
        border-top: 1px solid #EDEDED;
        &:hover {
          background: linear-gradient(270deg,#fff,#FFE8A6);
          box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
          .itemTd7 .itemDetail {
            display: flex;
            
          }
        }
        .itemTd1 {
          display: flex;
          align-items: center;
          img {
            width: 54px;
            height: 54px;
            margin-right: 15px;
            border-radius: 100%;
            box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
          }
          p {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            line-height: 17px;
          }
          span {
            font-size: 11px;
            color: #9C9C9C;
            transform: scale(0.9);
          }
        }
        .itemTd2 {
          display: flex;
          flex-wrap: wrap;
          margin-right: 8px;
          p {
            padding: 3px 13px;
            background: #FFF6D8;
            font-size: 12px;
            font-weight: 600;
            color: #000;
            transform: scale(0.9);
            margin-right: 8px;
          }
        }
        .itemTd3,.itemTd4,.itemTd5,.itemTd6 {
          font-size: 12px;
          font-weight: 600;
          color: #000;
          line-height: 17px;
        }
        .itemTd6 {
          line-height: 20px;
          word-break: break-all;
        }
        .orgView {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0 15px;
          .orgImg {
            width: 62px;
            height: 30px;
            position: relative;
            img {
              position: absolute;
              left: 0;
              top: 0;
              width: 30px;
              height: 30px;
              border-radius: 100%;
              box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
            }
          }
        }
        .itemTd7 {
          min-height: 71px;
          position: relative;
          .itemDetailPoint {
            width: 31px;
            height: 17px;
          }
          .itemDetail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #F0B200;
            border-radius: 8px;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 7px;
              height: 12px;
              margin-left: 7px;
            }
            p {
              font-size: 12px;
              color: #FFFFFF;
            }
          }
        }
      }
      .tableTd1 {
        width: 207px;
        margin-right: 8px;
      }
      .tableTd2 {
        width: 240px;
        margin-right: 8px;
      }
      .tableTd3 {
        width: 140px;
        text-align: center;
        margin-right: 8px;
      }
      .tableTd4 {
        width: 140px;
        text-align: center;
        margin-right: 8px;
      }
      .tableTd5 {
        width: 140px;
        text-align: center;
        margin-right: 8px;
      }
      .tableTd6 {
        width: 140px;
        margin-right: 8px;
        text-align: center;
      }
      .tableTd7 {
        width: 140px;
        height: 100%;
      }
    }
    .moreDeepBtn {
      margin-left: 50px;
      margin-right: 26px;
      height: 49px;
      line-height: 49px;
      text-align: center;
      background: #F3F2F0;
      border-radius: 11px;
      font-size: 15px;
      font-weight: 600;
      color: #9C9C9C;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
    .loadingView {
      width: 100%;
      min-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
    }
  }
}
</style>
  