<template>
  <div>
    <div class="tools_pc_page"  ref="sliderBody">
      <!-- 右侧导航 -->
      <div :class="current==navList.length-1 ? 'rightAbsolute' : 'rightFixed'" v-if="navList.length"  ref="sliderMenu">
        <div class="fixedItem" v-for="(item,index) in navList" :key="index" :class="{'fixedItem_active': current === index}" @click="scrollToView(index)">
          <div>
            <span></span>
          </div>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <!-- 内容 -->
      <div ref="sliderInfo" v-show="navList.length">
        <div class="collapseView" v-for="(item,index) in navList" :key="index"  ref="scrollItem">
          <div class="titleView" @click="changeCollapse(item)">
            <div class="leftTitle">
              <div class="left">
                <img src="@/assets/images/nav_hot.png" alt="" style="width:17px">
                <p>{{ item.name }}</p>
              </div>
            </div>
            <img src="@/assets/images/bottom.png" alt="" class="right" :style="!item.show && {transform: 'rotate(180deg)'}">
          </div>
          <div class="contentView" v-show="item.show">
            <a class="contentItem" v-for="(it,index) in item.navigations" :key="index" :style="((index+1)%5)==0 && {marginRight:0}" :href="it.url" target="_blank">
              <img :src="it.icon" alt="">
              <div class="txtView">
                <p class="showline1">{{ it.title }}</p>
                <span class="showline1">{{ it.excerpt }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-show="!navList.length" class="toolLoadingView">
        <van-loading color="#f0b200" v-if="loading" :size="54"/>
        <p v-else>暂无数据</p>
      </div>
      <!-- 150高度 -->
    </div>
    <div style="height: 300px;"></div>
    <footerView @goTop="goTop"/>
  </div>
</template>
<script>
import { getNavigation } from '@/api/index';
import footerView from './../components/footerView'
export default {
  name: "detail",
  components: {
    footerView
  },
  data() {
    return {
      loading: true,
      navList: [],
      // 
      current: 0,
      bodyOffset: 10,
      topOffset: 10,
      oneMenuHeight: 39,
      minViewMenuCount: 3,
      windowViewHeight: this.getWindowHeight(),
      menuHeight: 0,
      bodyEle: document.body,
    };
  },
  computed: {
    top () {
      if (this.topOffset >= this.bodyOffset) {
        return this.bodyOffset
      }
      return this.topOffset
    },
    currentSliderHeight () { // 当前滑动的菜单高度
      return (this.current + 1) * this.oneMenuHeight
    },
    minViewMenuHeight () { // 菜单最小可见高度
      return this.minViewMenuCount * this.oneMenuHeight
    },
    viewHeight () { // 可视图中，最大能显示的可见高度
      return this.windowViewHeight - this.bodyOffset
    }
  },
  mounted() {
    this.getData();
  },
  created() {
    document.body.style.overflow = 'auto';
  },
  destroyed () {
    document.body.style.overflow = 'hidden';
    window.removeEventListener('scroll', this.handleScroll, false)
    window.removeEventListener('resize', this.handleScroll, false)
  },
  methods: {
    goTop() {
      var timer = setInterval(function(){
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5); 
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if(osTop === 0){
          clearInterval(timer);
        }
      },30)
    },
    getData() {
      getNavigation().then(res=>{
        this.loading = false;
        const data = res.data.items;
        const result = data.map((item,index)=> {
          return {
            ...item,
            show: true,
          }
        });
        this.navList = result;
        setTimeout(() => {
          this.init()
        }, 500);
      })
      .catch(()=>{
        this.loading = false;
      })
    },
    changeCollapse(item) {
      item.show = !item.show;
      this.$forceUpdate();
    },
    init() {
      this.bodyOffset = 58
      this.menuHeight = (this.getOffset(this.$refs.sliderMenu) || {}).height || 0 
      this.topOffset = this.bodyOffset
      window.addEventListener('scroll', this.handleScroll, false)
      window.addEventListener('resize', this.handleScroll, false)
      this.handleScroll()
    },
    scrollToView (index) { // 滑动到指定组件位置
      
      let data = this.getScrollItems()
      if (index >= 0 && index < data.length) {
        let top = this.getNodeTop(data[index]) - this.bodyOffset
        window.scrollTo({top: (top < 0 ? 0 : top) - 30, behavior: 'smooth'})
        this.navList[index].show = true;
        this.$forceUpdate();
      }
    },
    handleScroll () {
      console.log(this.current)
      let data = this.getScrollItems()
      this.windowViewHeight = this.getWindowHeight()
      const scrollTop = this.getScroll(window, true)
      let baseTop = scrollTop + this.bodyOffset // 滑动高度 + 起始位置
      let maxTop = -99999
      let maxVisibleBottom = this.getMenuTop() + this.currentSliderHeight + this.minViewMenuHeight // 当前最大能显示的菜单所在位置
      // 小于最小显示数量 || 菜单完全显示在可视视图中 || 当前选中的菜单 + 最小展示的菜单 在视图中
      if (this.current < this.minViewMenuCount || this.menuHeight + this.bodyOffset < this.windowViewHeight
        || maxVisibleBottom < this.windowViewHeight) {
        this.topOffset = this.bodyOffset
      }
      let oldCurrent = this.current
      data.forEach((target, index) => {
        // const elOffset = this.getOffset(target)
        let realTop = this.getNodeTop(target) - baseTop - 30
        if (realTop <= 0 && maxTop <= realTop) {
          maxTop = realTop
          this.current = index
        }
      })
      let minMenuOffset = this.menuHeight - this.viewHeight
      let minTopOffset = this.bodyOffset - minMenuOffset // 菜单完全显示到底时，最大偏移量
      let bottom = this.getInfoBottom() - baseTop - this.viewHeight // 内容显示到底时：bottom=0
      if (this.current >= data.length - 1 && bottom < 0) {
        let lastNodeHeight = (this.getOffset(data[this.current]) || {}).height || 0
        let minHeight = Math.min(this.menuHeight, this.viewHeight) // 判断菜单 和可见视图 哪个小
        // 在 minHeight < lastNodeHeight 时：必定能完全显示最后一个组件页面
        // 他们的差值：代表 （组件页面 + 多少）能与菜单对齐，或者与视图窗口对齐
        let bottomOffset = minHeight - lastNodeHeight
        this.topOffset = minTopOffset + bottom + (bottomOffset > 0 ? bottomOffset : 0)
      } else if (oldCurrent != this.current && this.current >= this.minViewMenuCount
        && this.windowViewHeight < maxVisibleBottom) {
        let moveHeight = (oldCurrent > this.current ? 1 : -1) * this.oneMenuHeight
        this.topOffset = (this.topOffset < minTopOffset ? minTopOffset : this.topOffset) + moveHeight
      }
    },
    getWindowHeight () {
      return window.innerHeight || document.documentElement.clientHeight
    },
    getNodeBottom (node) {
      const currentOffset = this.getOffset(node) || {}
      return (currentOffset.top || 0) + (currentOffset.height || 0)
    },
    getNodeTop (node) {
      const currentOffset = this.getOffset(node) || {}
      return (currentOffset.top || 0)
    },
    getMenuTop () {
      if (this.$refs.sliderMenu) {
        return this.getNodeTop(this.$refs.sliderMenu)
      }
      return 0
    },
    getMenuBottom () {
      if (this.$refs.sliderMenu) {
        return this.getNodeBottom(this.$refs.sliderMenu)
      }
      return 0
    },
    getInfoBottom () {
      if (this.$refs.sliderInfo) {
        return this.getNodeBottom(this.$refs.sliderInfo)
      }
      return 0
    },
    getScrollItems () {
      let refs = []
      if (this.$refs.scrollItem && this.$refs.scrollItem.style) {
        refs.push(this.$refs.scrollItem)
      } else if (this.$refs.scrollItem && this.$refs.scrollItem.length) {
        refs = this.$refs.scrollItem
      }
      return refs
    },
    getScroll (target, top) {
      const prop = top ? 'pageYOffset' : 'pageXOffset'
      const method = top ? 'scrollTop' : 'scrollLeft'
      let ret = target[prop]
      if (typeof ret !== 'number') {
        ret = window.document.documentElement[method]
      }
      return ret
    },
    getOffset (element) {
      const rect = element.getBoundingClientRect()
      const scrollTop = this.getScroll(window, true)
      const scrollLeft = this.getScroll(window)
 
      const docEl = window.document.body
      const clientTop = docEl.clientTop || 0
      const clientLeft = docEl.clientLeft || 0
      let height = rect.bottom - rect.top
      if (height === 0 && element.parentNode) {
        let parentRect = element.parentNode.getBoundingClientRect()
        height = parentRect ? parentRect.height || 0 : 0
      }
      return {
        top: rect.top + scrollTop - clientTop,
        left: rect.left + scrollLeft - clientLeft,
        height: height,
        width: rect.right - rect.left
      }
    }
  },
};
</script>
<style lang="less">
.tools_pc_page {
  min-height: 100vh;
  padding-top: 30px;
  width: 1220px;
  margin: 0 auto;
  position: relative;
  padding-right: 53px;
  .collapseView {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    .titleView {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 24px;
      margin-bottom: 1px;
      cursor: pointer;
      .leftTitle {
        width: 75px;
        height: 24px;
        background: url('./../../../assets/images/linearBg.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        .left {
          position: absolute;
          left: 8px;
          top: 0;
          width: auto;
          height: 24px;
          display: flex;
          align-items: center;
        }
        img {
          width: 17px;
          margin-right: 7px;
        }
        p {
          font-size: 18px;
          font-weight: 600;
          color: #444444;
          flex-shrink: 0;
        }
      }
      .right {
        width: 16px;
        height: 11px;
        transition: .5s;
      }
    }
    .contentView {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      .contentItem {
        width: calc(20% - 8.8px);
        height: 65px;
        background: #FFFFFF;
        box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
        border-radius: 11px;
        display: flex;
        align-items: center;
        margin-right: 11px;
        margin-top: 11px;
        padding: 0 7px;
        cursor: pointer;
        &:hover {
          background: linear-gradient(270deg,#fff,#FFE8A6);
          box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
        }
        img {
          width: 34px;
          height: 34px;
          margin-right: 7px;
          border-radius: 34px;
          flex-shrink: 0;
          box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
        }
        .txtView {
          flex: 1;
          p {
            font-size: 14px;
            font-weight: 600;
            color: #444444;
            line-height: 19px;
          }
          span {
            font-size: 12px;
            font-weight: 500;
            color: #9C9C9C;
          }
        }
        &:hover {
          .txtView {
            p {
              color: #737373;
            }
          }
        }
      }
    }
  }
  .rightFixed {
    position: fixed;
    left: calc((100% - 1220px) / 2 + 1197px);
    top: 104px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 30px 20px;
    width: 50px;
    overflow: hidden;
    &:hover {
      width: auto;
      span {
        margin-right: 10px;
        
      }
      .fixedItem {
        overflow: initial;
      }
    }
    .fixedItem {
      width: 100%;
      height: 19px;
      border-radius: 100%;
      margin-bottom: 7px;
      cursor: pointer;
      display: flex;
      align-items: center;
      overflow: hidden;
      &:last-child {
        margin-bottom: 0;
      }
      div {
        width: 11px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #E8E3D4;
        flex-shrink: 0;
      }
      p {
        font-size: 14px;
        color: #444444;
        font-weight: 600;
      }
      &:hover {
        span {
          width: 11px;
          height: 11px;
          background: #FFF6D8;
          border: 2px solid #F0B200;
          box-sizing: border-box;
        }
        p {
          color: #F0B200;
        }
      }
    }
    .fixedItem_active {
      min-width: 11px;
      span {
        width: 11px;
        height: 11px;
        background: #FFF6D8;
        border: 2px solid #F0B200;
        box-sizing: border-box;
      }
      p {
        color: #F0B200;
      }
    }
  }
  .rightAbsolute {
    position: absolute;
    left: calc((100% - 1220px) / 2 + 1197px);
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 30px 20px;
    width: 50px;
    overflow: hidden;
    &:hover {
      width: auto;
      span {
        margin-right: 10px;
        
      }
      .fixedItem {
        overflow: initial;
      }
    }
    .fixedItem {
      width: 100%;
      height: 19px;
      border-radius: 100%;
      margin-bottom: 7px;
      cursor: pointer;
      display: flex;
      align-items: center;
      overflow: hidden;
      &:last-child {
        margin-bottom: 0;
      }
      div {
        width: 11px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #E8E3D4;
        flex-shrink: 0;
      }
      p {
        font-size: 14px;
        color: #444444;
        font-weight: 600;
      }
      &:hover {
        span {
          width: 11px;
          height: 11px;
          background: #FFF6D8;
          border: 2px solid #F0B200;
          box-sizing: border-box;
        }
        p {
          color: #F0B200;
        }
      }
    }
    .fixedItem_active {
      min-width: 11px;
      span {
        width: 11px;
        height: 11px;
        background: #FFF6D8;
        border: 2px solid #F0B200;
        box-sizing: border-box;
      }
      p {
        color: #F0B200;
      }
    }
  }
  .toolLoadingView {
    width: 100%;
    min-height: calc(100vh - 240px);
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
  