<template>
  <div class="shareDialog" @click="close">
    <div class="shareContent" ref="imageTofile">
      <div class="topImg">
        <img src="@/assets/images/sharedialogImg1.png" alt="" class="left">
        <img src="@/assets/images/sharedialogImg2.png" alt="" class="right">
      </div>
      <div class="shareTime">
        <img src="@/assets/images/kxicon.png" alt="" />
        <p>{{ $dayjs(item.publishTime).format('YYYY年MM月DD日 HH:mm') }}</p>
      </div>
      <p class="shareTitle">{{item.title}}</p>
      <p class="shareTxt" v-html="item.excerpt"></p>
      <p class="shareTips">风险提示：本文仅作为传递信息用途，不代表WEB3CN立场，且不构成投资理财建议。</p>
      
      <div class="shareBottom">
        <div class="left">
          <img src="@/assets/images/sharedialogBottomImg1.png" alt="" class="web3cnLogo" />
          <p>基于DID的精准内容分发<br/>更懂你的WEB3资讯平台</p>
        </div>
        <div class="right">
          <img src="@/assets/images/wx1.png" alt="">
        </div>
      </div>

      <img :src="shareImg" alt=""  class="shareImage"/>
    </div>
    
    <div class="shareTool">
      <div class="toolView">
        <div class="toolItem">
          <a title='twitter' :href="`https://twitter.com/intent/tweet?url=${shareUrl}&text=${item.title}`" target="_blank"><img src="@/assets/images/sharedialogIcon1.png" alt="" /></a>
        </div>
        <div class="toolItem wxShare">
          <a  title='微信' href="javascript:;"><img src="@/assets/images/sharedialogIcon2.png" alt="" /></a>
          <div class="wxShareCode"><img :src="wxCode" alt=""/></div>
        </div>
        <div class="toolItem">
          <a  title='复制链接' href="javascript:;" @click="copyLink"><img src="@/assets/images/sharedialogIcon3.png" alt="" /></a>
        </div>
        <div class="toolItem">
          <a  title='保存图片' href="javascript:;"><img src="@/assets/images/sharedialogIcon4.png" alt="" style="width:1.5rem" @click="download"/></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import QRCode from 'qrcode'
export default {
  name: "detail",
  props: ['index', 'closeNotify', 'item'],
  data() {
    return {
      wxCode: '',
      shareImg: '',
    };
  },
  computed: {
    shareUrl() {
      if(this.item) {
        return window.location.origin +'/fastDetail/'+ this.item.id
      }
      return ''
    }
  },

  methods: {
    close(e) {
      if (e.target.className == 'shareDialog') {
        this.$emit('close');
      }
    },
    productImg() {
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        this.shareImg= url;
        // this.download(url);
      })
    },
    download(){
      const url = this.shareImg;
      downloadFileByBase64(url,'web3cn_'+this.item.id)
      function dataURLtoBlob(dataurl) {
          var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
          while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
      }
      function downloadFile(url,name='What\'s the fuvk'){
          var a = document.createElement("a")
          a.setAttribute("href",url)
          a.setAttribute("download",name)
          a.setAttribute("target","_blank")
          let clickEvent = document.createEvent("MouseEvents");
          clickEvent.initEvent("click", true, true);  
          a.dispatchEvent(clickEvent);
      }
      function downloadFileByBase64(base64,name){
          var myBlob = dataURLtoBlob(base64)
          var myUrl = URL.createObjectURL(myBlob)
          downloadFile(myUrl,name)
      }
    },
    copyLink() {
      this.$utils.copy(this.shareUrl,()=>{
        this.$toast.success("复制成功");
      })
    },
  },
  mounted() {
    const str = window.location.origin +'/h5/fastDetail/'+ this.item.id;
    QRCode.toDataURL(str)
    .then(url => {
      this.wxCode = url;
      this.productImg();
    })
    .catch(err => {
      console.error(err)
    })
  },
 
};
</script>
<style lang="less">
.shareDialog {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  align-items: center;
  overflow: auto;
  padding: 20px 0;
  .shareContent {
    margin-top: 100px;
    width: 350px;
    // background: #FAFAFA;
    position: relative;
    padding-bottom: 30px;
    background: url('./../../../assets/images/sharebg.png') no-repeat right bottom 120px / 240px auto;
    background-color: #FAFAFA;
    .shareImage {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    .topImg {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 2rem;
      position: relative;
      .left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      }
      .right {
        width: 68px;
        height: 86px;
        position: relative;
        z-index: 2;
      }
    }
    .shareTime {
      padding-left: 29px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 50px;
        height: 23px;
        margin-right:8px;
      }
      p {
        font-size: 12px;
        font-family: SFProText-Semibold, SFProText;
        font-weight: 600;
        color: #9C9C9C;
        transform: scale(0.9);
      }
    }
    .shareTitle {
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      box-sizing: border-box;
      font-size: 17px;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #000;
      line-height: 24px;
      -webkit-text-stroke: 0px #000;
      text-stroke: 0px #000;
      margin-bottom: 13px;
      text-align: justify;
    }
    .shareTxt {
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      font-size: 13px;
      font-family: SFProText-Medium, SFProText;
      font-weight: 500;
      color: #4a4a4a;
      line-height: 23px;
      margin-bottom: 40px;
      word-break: break-all;
      text-align: justify;
      p {
        text-align: justify;
      }
    }
    .shareTips {
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      box-sizing: border-box;
      font-size: 12px;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #9C9C9C;
      line-height: 18px;
      margin-bottom: 15px;
    }
    .web3cnLogo {
      width: 109px;
      height: 29px;
      display: block;
      margin-bottom: 20px;
    }
    .shareBottom {
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          font-size: 12px;
          font-family: SFProText-Semibold, SFProText;
          font-weight: 600;
          color: #444444;
          line-height: 16px;
          letter-spacing: 3px;
        }
      }
      .right {
        img {
          width: 81px;
          height: 81px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
  .shareTool {
    margin-top: 30px;
    width: 350px;
    display: flex;
    justify-content: flex-end;
    .toolView {
      width: 186px;
      height: 34px;
      background: rgba(0,0,0,0.2);
      border-radius: 18px;
      display: flex;
      align-items: center;
      .toolItem {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        img {
          width: 17px;
        }
        
      }
      .wxShare {
        &:hover {
          .wxShareCode {
            display: block;
          }
        }
        .wxShareCode {
          position: absolute;
          left: 50%;
          margin-left: -30px;
          bottom: -65px;
          width: 60px;
          height: 60px;
          border-radius: 8px;
          background: #fff;
          display: none;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      } 
    }
  }
}
</style>
  