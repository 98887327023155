<template>
  <div class="download_app_h5">
    <div class="app_left">
      <img src="@/assets/h5Image/downloadLogo.png" alt="">
      <div class="app_intro">
        <p>WEB3CN APP</p>
        <p>更懂你的WEB3资讯平台</p>
      </div>
    </div>
    <div class="app_right" @click="$router.push('/h5/download')">打开APP</div>
  </div>
</template>

<script>
export default {
  name: "toptab",
  props: {
   
  },
  data() {
    return {};
  },
  created() {
    // 

  },
  
  mounted() {
  },
  methods: {
   
  },
  
};
</script>

<style lang="less">
.download_app_h5 {
  width: 100%;
  padding: 10px 14px;
  background: #f5f4f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .app_left {
    display: flex;
    align-items: center;
    img {
      width: 58px;
      height: 58px;
      margin-right: 10px;
    }
    p {
      &:first-child {
        font-size: 13px;
        font-weight: 500;
        color: #444444;
        line-height: 19px;
        margin-bottom: 5px;
      }
      &:last-child {
        font-size: 12px;
        font-weight: 500;
        color: #9C9C9C;
        line-height: 16px;
      }
    }
  }
  .app_right {
    width: 96px;
    height: 29px;
    background: linear-gradient(135deg, #191919 0%, #F0B200 100%);
    border-radius: 17px;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 29px;
  }
}
</style>