import Vue from 'vue'
import Router from 'vue-router'
import pcindex from '@/views/pc/index'

import pchome from '@/views/pc/home/index'
import fastDetail from '@/views/pc/fastDetail/index'
import deepNews from '@/views/pc/deep/index'
import deepDetail from '@/views/pc/deepDetail/index'
import project from '@/views/pc/project/index'
import projectDetail from '@/views/pc/projectDetail/index'
import organization from '@/views/pc/organization/index'
import organizationDetail from '@/views/pc/organizationDetail/index'
import tools from '@/views/pc/tools/index'
import search from '@/views/pc/search/index'
import searchIndex from '@/views/pc/search/search'
import info1 from '@/views/pc/info/privacypolicy'
import info2 from '@/views/pc/info/job'
import info3 from '@/views/pc/info/copyright'
// import organizationDetail from '@/views/pc/information/organizationDetail'
import author from '@/views/pc/columns/index'

import h5index from '@/views/h5/index'

import h5home from '@/views/h5/home/index'
import h5fast from '@/views/h5/fast/index'
import h5fastDetail from '@/views/h5/fastDetail/index'
import h5deep from '@/views/h5/deep/index'
import h5deepDetail from '@/views/h5/deepDetail/index'
import h5project from '@/views/h5/project/index'
import h5organization from '@/views/h5/organization/index'
import h5projectDetail from '@/views/h5/projectDetail/index'
import h5organizationDetail from '@/views/h5/organizationDetail/index'
import h5search from '@/views/h5/search/index';
import h5author from '@/views/h5/columns/index';
import h5download from '@/views/h5/download/index';
Vue.use(Router)

const pageRouter =  new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'PC首页',
      component: pcindex,
      redirect: '/home',
      children:[
        {
          path: '加载中...',
          redirect: '/home'
        },
        {
          path: 'home',
          name: 'pchome',
          component: pchome,
          meta: {
            title: 'WEB3CN-领先的web3资讯与数据平台'
          }
        },
        {
          path: 'fastDetail/:id',
          name: 'pchome1',
          component: fastDetail,
          meta: {
            title: '加载中...'
          }
        },
        {
          path: 'deep',
          name: 'pcdeep',
          component: deepNews,
          meta: {
            title: '深度-WEB3CN'
          }
        },
        {
          path: 'deepDetail/:id',
          name: 'pcdeep1',
          component: deepDetail,
          meta: {
            title: '加载中...'
          }
        },
        {
          path: 'project',
          name: 'pcproject',
          component: project,
          meta: {
            title: '项目库-WEB3CN'
          }
        },
        {
          path: 'projectDetail/:id',
          name: 'pcprojectDetail',
          component: projectDetail,
        },
        {
          path: 'organization',
          name: 'pcorganization',
          component: organization,
          meta: {
            title: '机构-WEB3CN'
          }
        },
        {
          path: 'organizationDetail/:id',
          name: 'pcorganization1',
          component: organizationDetail,
        },
        {
          path: 'tools',
          name: 'pctools',
          component: tools,
          meta: {
            title: '工具-WEB3CN'
          }
        },
        {
          path: 'result/:value',
          name: 'pcsearch',
          component: search,
          meta: {
            title: '加载中...'
          }
        },
        {
          path: 'copyright',
          name: 'pcinfo3',
          component: info3,
          meta: {
            title: '版权申明-WEB3CN'
          }
        },
        {
          path: 'privacypolicy',
          name: 'pcinfo1',
          component: info1,
          meta: {
            title: '隐私政策-WEB3CN'
          }
        },
        {
          path: 'job',
          name: 'pcinfo2',
          component: info2,
          meta: {
            title: '工作机会-WEB3CN'
          }
        },
        {
          path: 'author/:id',
          name: 'pcdeep2',
          component: author,
        },
        {
          path: 'search',
          name: 'pcinfo3',
          component: searchIndex,
          meta: {
            title: '搜索-WEB3CN'
          }
        },
      ]
    },
    {
      path: '/h5',
      name: 'h5index',
      component: h5index,
      redirect: '/h5/home',
      children:[
        {
          path: '加载中...',
          redirect: '/h5/home',
          meta: {
            keepLive: true,
            isback: false,
          },
        },
        {
          path: 'home',
          name: 'h5home',
          component: h5home,
          meta: {
            keepLive: true,
            isback: false,
            title: 'WEB3CN-领先的web3资讯与数据平台'
          },
        },
        {
          path: 'deep',
          name: 'h5deep',
          component: h5deep,
          meta: {
            keepLive: true,
            isback: false,
            title: '深度-WEB3CN'
          },
        },
        {
          path: 'project',
          name: 'h5project',
          component: h5project,
          meta: {
            keepLive: true,
            isback: false,
            title: '项目-WEB3CN'
          },
        },
        {
          path: 'organization',
          name: 'h5organization',
          component: h5organization,
          meta: {
            keepLive: true,
            isback: false,
            title: '机构-WEB3CN'
          },
        },
      ]
    },
    {
      path: '/h5/fastDetail/:id',
      name: 'h5fastDetail',
      component: h5fastDetail,
      meta: {
        title: '加载中...'
      }
    },
    {
      path: '/h5/deepDetail/:id',
      name: 'h5deepDetail',
      component: h5deepDetail,
      meta: {
        title: '加载中...'
      }
    },
    {
      path: '/h5/projectDetail/:id',
      name: 'h5projectDetail',
      component: h5projectDetail,
      meta: {
        title: '项目详情-WEB3CN'
      }
    },
    {
      path: '/h5/organizationDetail/:id',
      name: 'h5organizationDetail',
      component: h5organizationDetail,
      meta: {
        title: '机构详情-WEB3CN'
      }
    },
    {
      path: '/h5/search',
      name: 'h5search',
      component: h5search,
      meta: {
        keepLive: true,
        isback: false,
      },
    },
    {
      path: '/h5/author/:id',
      name: 'h5author',
      component: h5author,
    },
    {
      path: '/h5/download',
      name: 'h5download',
      component: h5download,
    },
  ]
})
pageRouter.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
});
export default pageRouter;
