<template>
  <div class="h5_economic">
    <p class="economicTitle">代币分布</p>
    <div class="coinView">
      <div class="coinChart">
        <div id="chartView"></div>
        <div class="supply">
          <div>
            <p class="supply_title">总筹款</p>
            <p class="supply_value">{{ sum }}M</p>
          </div>
          <div>
            <p class="supply_title">最大供应</p>
            <p class="supply_value">{{ sum }}B</p>
          </div>
        </div>
      </div>
      <div class="coinTable">
        <div class="tableTitle">
          <p class="td1">阶段</p>
          <p class="td2">价格</p>
          <p class="td3">总筹款</p>
        </div>
        <div class="tableItem" v-for="(item,index) in tokenList" :key="index">
          <div class="td1 tableItemtd1">
            <span :style="{background: item.color}"></span>
            <p>{{ item.stage }}</p>
          </div>
          <p class="td2">{{ item.price || '--' }}</p>
          <p class="td3">{{ item.valuation }}</p>
        </div>
      </div>
    </div>
    <p class="economicTitle">解锁时间</p>
    <div class="timeView">
      <div class="swiper-slide" v-for="(item,index) in unlocks" :key="index+'unlock'">
        <div class="swiperItemTop">
          <div>
            <p>从</p>
            <p>{{ item.startDate }}</p>
          </div>
          <img src="@/assets/images/projectDetail/lock.png" alt="">
        </div>
        <div class="dashBorder"></div>
        <div class="swiperItemBottom">
          <div class="progress">
            <el-progress type="circle" :percentage="getPrecent(item.percent)" :width="90" color="#F0B200"></el-progress>
          </div>
          <div class="bottomInfo">
            <p class="stsystemTitle">{{ item.tokenomics_round }}</p>
            <p class="stsystemValue">{{item.days}}天内</p>
            <div class="num_value">
              <div class="num_valueItem num">
                <p>数量</p>
                <p>{{ item.tokens }}</p>
              </div>
              <div class="num_valueItem">
                <p>价值</p>
                <p>{{ item.tokenValue }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  name: "economic",
  props: [ "info" ],
  data() {
    return {
      loadMore: false,
      unlocks: [],
      tokenList: [],
      sum: 0
    };
  },
  watch: {
    info() {
      if(this.info.unlocks || this.info.tokens) {
        this.unlocks = this.info.unlocks;
        this.dealchart();
        // this.chartList = this.
        setTimeout(()=>{
          this.initBannerSwiper();
        },100);
      }
    }
  },
  methods: {
    getPrecent(val) {
      const res = (val+'').split('%');
      return res[0]*1
    },
    inifChart() {
      const dataRouce = this.tokenList.reduce((acc,item)=>{
        acc.color.push(item.color || '#DC8201');
        acc.data.push({value: item.precentValue, name: item.stage});
        return acc
      },{color: [],data: []});
      console.log(dataRouce)
      var myChart = this.$echarts.init(document.getElementById('chartView'));
      //配置图表
      var option = {
        tooltip: {
          trigger: 'item',
          confine: true
        },
        color: dataRouce.color,
        series: [
          {
            type: 'pie',
            radius: ['65%', '90%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            data:dataRouce.data
          }
        ]
      };
      myChart.setOption(option);
    },
    initBannerSwiper() {
      new Swiper('.swiper-container', {
        loop: true,
        autoplay: true,
        slidesPerView: 'auto',
        spaceBetween: 15,
          // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: false, // 分页器可以点击
        },
      })
    },
    dealchart() {
      let sum = 0;
      this.tokenList = this.info.tokens.map(item=>{
        const result = item.tokenPercent.split('%')[0];
        sum = sum + (item.valuation ? (item.valuation.split('M')[0]*1) : 0);
        return {
          ...item,
          precentValue: result * 1,
          color: '#'+item.color
        }
      })
      this.sum = sum; 
      setTimeout(()=>{
        this.inifChart();
      },100);
    }
  },
  mounted() {
    
    if(this.info.unlocks || this.info.tokens) {
      this.unlocks = this.info.unlocks;
      this.dealchart();
      // this.chartList = this.
      setTimeout(()=>{
        this.initBannerSwiper();
      },100);
    }
  },

};
</script>
<style lang="less">
.h5_economic {
  .economicTitle {
    font-size: 16px;
    font-weight: 600;
    color: #444444;
    margin-bottom: 30px;
  }
  .coinView {
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #EDEDED;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 20px 10px;
    .coinChart {
      width: 100%;
      min-height: 200px;
      background: #FFFFFF;
      border-radius: 11px;
      display: flex;
      align-items: center;
      #chartView {
        width: 240px;
        height: 240px;
      }
      .supply {
        flex-shrink: 0;
        width: 80px;
        text-align: right;
        div {
          margin-bottom: 5px;
        }
        .supply_title {
          font-size: 14px;
          font-weight: 500;
          color: #737373;
          line-height: 21px;
        }
        .supply_value {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          padding-left: 5px;
        }
      }
    }
    .coinTable {
      width: 100%;
      background: #FFFFFF;
      border-radius: 11px;

      .tableTitle {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #9C9C9C;
        }
      }
      .tableItem {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        border-top: 1px solid #EDEDED;
        padding: 0 10px;
        .tableItemtd1 {
          display: flex;
          align-items: center;
          span {
            width: 19px;
            height: 19px;
            background: #DC8201;
            margin-right: 10px;
            border-radius: 100%;
            flex-shrink: 0;
          }
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #000;
        }
      }
      .td1 {
        text-align: left;
        flex: 1;
        margin-right: 8px;
      }
      .td2 {
        width: 60px;
        text-align: center;
        margin-right: 8px;
      }
      .td3 {
        width: auto;
        width: 60px;
        text-align: right;
        margin-right: 0;
      }
     
    }
  }
  .timeView {
    width: 100%;
    .swiper-slide {
      width: 100%;
      height: 220px;
      background: #FFFFFF;
      border-radius: 15px;
      border: 1px solid #EDEDED;
      padding: 20px;
      margin-bottom: 15px;
      .swiperItemTop {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          &:first-child {
            font-size: 12px;
            font-weight: 500;
            color: #9C9C9C;
            line-height: 21px;
          }
          &:last-child {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            line-height: 21px;
          }
        }
        img {
          width: 16px;
          height: 20px;
          margin-right: 7px;
        }
      }
      .dashBorder {
        width: 100%;
        border-top: 1px dashed #ADB0C2;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .swiperItemBottom {
        display: flex;
        .progress {
          margin-top: 6px;
          width: 90px;
          height: 90px;
          margin-right: 50px;
        }
      }
      .bottomInfo {
        .stsystemTitle {
          font-size: 15px;
          font-weight: 500;
          color: #000000;
          line-height: 21px;
        }
        .stsystemValue {
          padding-left: 19px;
          background: url('./../../../assets/images/projectDetail/lockTime.png') no-repeat left center / 15px 15px;
          font-size: 12px;
          font-weight: 500;
          color: #9C9C9C;
          line-height: 17px;
        }
        .num_value {
          display: flex;
          margin-top: 25px;
          .num_valueItem {
            p {
              &:first-child {
                font-size: 12px;
                font-weight: 500;
                color: #9C9C9C;
                line-height: 17px;
              }
              &:last-child {
                font-size: 14px;
                font-weight: 500;
                color: #000000;
                line-height: 19px;
              }
            }
          }
          .num {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
</style>
  