<template>
<div class="hotArticle_h5">
  <div class="hotArticleTitle">
    深度文章
  </div>
  <div v-if="fastMsgList.length">
    <div class="hotArticleList">
      <div class="hotArticleItem" v-for="(item,index) in fastMsgList" :key="index" @click="goDetail(item)">
        <p class="hotArticleItemTxt showline2">{{ item.title }}</p>
        <div class="midInfo">
          <p class="hotArticleItemExport showline3">{{ item.excerpt }}</p>
          <img :src="item.cover" alt="" class="itemImg"/>
        </div>
        <p class="hotArticleItemTime showline1" @click.stop="goAuthor(item)">{{ item.author&&item.author.name }} · {{ $dayjs(new Date(item.publishTime)).fromNow() }}</p>
      </div>
    </div>
  </div>
  <div class="loadingView" v-else>
    <van-loading color="#f0b200" v-if="loading" :size="54"/>
    <p v-else>暂无数据</p>
  </div>
</div>
</template>
<script>
import { getList } from '@/api/index';
export default {
  name: "hot",
  props: [],
  data() {
    return {
      weekMap: {
        '0': '日',
        '1': '一',
        '2': '二',
        '3': '三',
        '4': '四',
        '5': '五',
        '6': '六',
      },
      fastMsgList: [],
      loading: true
    }
  },
  methods: {
    goAuthor(item) {
      this.$router.push('/h5/author/'+item.author.id)
    },
    goDetail(item) {
      this.$router.push('/h5/deepDetail/'+item.id)
    },
  },
  mounted() {
    getList('article',{
      page: 1,
      size: 6,
    })
    .then(res=> {
      const data = res.data;
      this.fastMsgList = data.items;
      this.loading = false;
    })
  },
  
};
</script>
<style lang="less">
.hotArticle_h5 {
  padding: 15px;
  width: 100%;
  margin-bottom: 23px;
  .hotArticleTitle {
    width: 77px;
    height: 35px;
    background: url('./../../../assets/images/articelTitleIcon.png') no-repeat left center / auto 100%;
    margin-bottom: 23px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-right: 8px;
    text-align: center;
    line-height: 35px;
  }
  .hotArticleList {
    .hotArticleItem {
      width: 100%;
      margin-bottom: 25px;
      .itemImg {
        width: 82px;
        height: 59px;
        border-radius: 4px;
        margin-right: 8px;
        flex-shrink: 0;
      }
      .hotArticleItemTxt {
        font-size: 15px;
        font-weight: 600;
        color: #4A4A4A;
        line-height: 21px;
        margin-bottom: 7px;
        text-align: justify;
      }
      .hotArticleItemTime {
        font-size: 12px;
        font-weight: 500;
        color: #CCCCCB;
      }
      .midInfo {
        display: flex;
        margin-bottom: 6px;
        .hotArticleItemExport {
          flex: 1;
          margin-right: 10px;
          font-size: 13px;
          font-weight: 500;
          color: #444444;
          line-height: 24px;
          text-align: justify;
        }
        img {
          flex-shrink: 0;
          width: 114px;
          height: 75px;
          border-radius: 10px;
        }
      }
    }
  }
  .morehotArticle {
    width: 285px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 4px;
    line-height: 34px;
    font-size: 14px;
    font-weight: 600;
    color: #F0B200;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .loadingView {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
