<template>
  <div class="infinite-list-wrapper" style="overflow:auto;height:100vh" ref="deepScroll">
    <div class="deep_pc_page"  v-infinite-scroll="load" :infinite-scroll-disabled="!listData.hasNext" :infinite-scroll-distance="100">
      <img src="@/assets/images/deepBanner.png" alt="" class="deepBanner">
      <div class="deep_pc_page_content">
        <div class="deep_pc_page_left">
          <!-- 导航 -->
          <div class="nav_swiper">
            <van-tabs @click="clickTab">
              <van-tab v-for="(item,index) in tabList" :key="index" :title="item.name" style="" >
              </van-tab>
            </van-tabs>
          </div>
          <div class="deep_view" v-if="listData.list.length">
            <div class="deepNews">
              <div class="article_view" v-for="(item,index) in listData.list" :key="index">
                <articleItem :info="item"  :otherFlex="true"  @goDetail="goDetail(item)"/>
                <articleBorder :direction="index%2==0?'left':'right'"/>
              </div>
            </div>
            <div class="moreDeepBtn" v-if="listData.hasNext">
              <span>更多深度文章</span>
              <div class="moreFastMsg_loading" v-if="loadMore">
                <van-loading color="#9c9c9c" size="44px"/>
              </div>
            </div>
          </div>
          <div class="deep_view loadingView" v-else>
            <van-loading color="#f0b200" v-if="listData.loading" :size="54"/>
            <p v-else>暂无数据</p>
          </div>
          
        </div> 
        <div class="deep_pc_page_right">
          <van-sticky :offset-top="88"><newFastmessage /></van-sticky>
          
        </div>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.deepScroll"/>
  </div>
</template>
<script>
import articleBorder from './articleBorder';
import articleItem from './articleItem';
import newFastmessage from '../components/newFastmessage';
import footerView from './../components/footerView'
import { getCategories, getList } from '@/api/index';
export default {
  name: "detail",
  components: {
    articleBorder,
    articleItem,
    newFastmessage,
    footerView
  },
  data() {
    return {
      tabIndex: '',
      tabList: [],
      listData: {
        list: [],
        page: 1,
        size: 10,
        loading: true,
        hasNext: false,
      },
      loadMore: false,
    };
  },
  watch: {
    tabIndex(newVal,oldVal) {
      if(oldVal!=='') {
        this.listData = {
          list: [],
          page: 1,
          size: 20,
          loading: true
        }
        this.$nextTick(()=>{
          this.getListData();
        })
      }
    },
  },
  mounted() {
    this.getTabs();
  },
  methods: {
    clickTab(val) {
      this.tabIndex = this.tabList[val].id;
    },
    goDetail(item) {
      // this.$router.push('/deepDetail/'+item.id)
      window.open(window.location.origin+'/deepDetail/'+item.id)
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      let params = {
          page: this.listData.page,
          size: this.listData.size,
          category: this.tabIndex
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      getList('article',params)
      .then(res=> {
        const data = res.data;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(data.items);
          this.loadMore = false;
        } else {
          this.listData.list = data.items;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    load() {
      if(!this.loadMore) {
        this.loadMore = true;
        setTimeout(() => {
          this.getListData(true)
        }, 500);
      }
    },
    getTabs() {
      getCategories('article')
      .then(res=> {
        const { data } = res;
        if(data) {
          if(data.items.length) {
            this.tabList = [{name:'全部',id: null}].concat(data.items);
            this.tabIndex = null;
          }
          this.getListData();
          
        }
      })
    },
  }
};
</script>
<style lang="less">
.deep_pc_page {
  padding-top: 159px;
  position: relative;
  .deepBanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;
  }
  .deep_pc_page_content {
    width: 1220px;
    margin: 0 auto;
    display: flex;
  }
  .deep_pc_page_left {
    flex: 1;
    .nav_swiper {
      width: 786px;
      height: 53px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
      border-radius: 11px;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      .van-tabs__nav--line.van-tabs__nav--complete {
        padding: 0;
      }
      .van-tabs {
        width: 100%;
      }
      .van-tab {
        font-size: 15px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          font-weight: 600;
          box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
          border-radius: 11px;
          color: rgb(240, 178, 0);
          background: #fff;
        }
      }
      .van-tab--active {
        font-weight: 600;
        border-radius: 11px;
        background: rgb(240, 178, 0);
        color: #fff;
        &:hover {
          color: rgb(240, 178, 0);
        }
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs--line .van-tabs__wrap {
        height: 53px;
        border-radius: 11px;
      }
    }
    .loadingView {
      width: 100%;
      min-height: calc(100vh - 240px);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
    .deep_view {
      padding: 15px 0px;
      width: 100%;
      min-height: calc(100vh - 240px);
      margin-bottom: 23px;
    }
    .moreDeepBtn {
      margin-left: 64px;
      margin-bottom: 15px;
      margin-top: 30px;
      height: 38px;
      line-height: 38px;
      background: #f3f2f0;
      border-radius: 19px;
      font-size: 15px;
      font-weight: 600;
      color: #9c9c9c;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
   
  }
  .deep_pc_page_right {
    flex-shrink: 0;
    width: 374px;
    margin-left: 60px;
    .van-sticky--fixed {
      width: 374px;
      left: calc((100% - 1220px) / 2 + 786px + 50px);
      right: auto;
    }
  }
}
</style>
  