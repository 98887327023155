<template>
  <div class="project_h5_page">
    <div class="top_bar">
      <topInput />
    </div>
    <topTabpro :tabList="tabList" :top="64" @clickTab="clickTab"/>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="projectLib_title">
        <p>热门项目</p>
        <img src="@/assets/images/projectLib/hot.png" alt="">
      </div>
      <!-- swiper -->
      <div class="swiper-container hotProswiper" v-if="hotList.length">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in hotList" :key="index+'hot'" @click="goDetail(item,index)">
            <div class="swiperLeft">
              <img :src="item.icon" alt="">
              <div>
                <p class="showline1">{{ item.title }}</p>
                <span class="showline1">{{ item.type }}</span>
              </div>
            </div>
            <div class="swiperRight">
              <p>{{ item.financingAmount }}</p>
              <span>融资金额</span>
            </div>
          </div>
        </div>
        <!-- <div class="swiperBtn0" @click="slideSwiper(1)" v-if="showPreBtn"></div>
        <div class="swiperBtn" @click="slideSwiper(2)" v-if="(hotList.flat(1)).length > 4 && showNextBtn"></div> -->
      </div>
      <div v-else class="swiperNodata">
        <van-loading color="#f0b200"  :size="34"/>
      </div>
      <!-- 列表 -->
      <div style="padding: 0 15px;">
        <div class="allPrijectTitle">
          <div class="projectLib_title" style="width: 59px;margin: 0;">
            <p>全部</p>
          </div>
          <p class="projectNumber">项目数<span>{{ totalNums }}</span></p>
        </div>
        <div class="projectLibTable">
          <div class="tableView">
            <div class="tableTitle">
              <p class="tableTd1">项目名称</p>
              <p class="tableTd2">融资金额/融资状态</p>
            </div>
            <div v-if="tableData.list.length">
              <van-list
                v-model="tableData.loading"
                :finished="!tableData.hasNext"
                finished-text=""
                @load="onLoad"
              >
              <div class="tableItem" v-for="(item,index) in tableData.list" :key="index" @click="goDetail(item,index)" >
                <div class="tableTd1 itemTd1">
                  <img :src="item.icon" alt=""/>
                  <div>
                    <p>{{item.title}}</p>
                    <div class="tags" v-if="item.tags && item.tags.length && tabIndex===null">
                      <span>{{ item.tags[0].name }}</span>
                    </div>
                    <div class="tags" v-if="item.tags && item.tags.length && tabIndex">
                      <span>{{ getOtherTag(item.tags) }}</span>
                    </div>
                  </div>
                  
                </div>
                <div class="tableTd2 itemTd2" style="flex:inherit">
                  <p>{{item.financingAmount}}</p>
                  <span>{{ item.stage }}</span>
                </div>
              </div>
              </van-list>
            </div>
            <div class="loadingView" v-else>
              <van-loading color="#f0b200" v-if="tableData.loading" :size="34"/>
              <p v-else>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { getCategories, getProjectList } from '@/api/index'
import topInput from './../components/topInput'
import topTabpro from './../components/topTabpro'
export default {
  name: "project",
  components: {
    topInput,
    topTabpro
  },
  data() {
    return {
      hotList: [],
      tabIndex: '',
      tabName: '全部',
      tabList: [],
      tableData: {
        page: 1,
        size: 30,
        list: [],
        hasNext: false,
        loading: true
      },
      loadMore: false,
      totalNums: 0,
      refreshing: false,
    };
  },
  watch: {
    tabIndex(newVal,oldVal) {
      if(oldVal!=='') {
        this.tableData = {
          page: 1,
          size: 30,
          list: [],
          hasNext: false,
          loading: true
        }
        this.$nextTick(()=>{
          this.getListData();
        })
      }
    },
  },
  mounted() {
    this.getHot();
    this.getTabs();
  },
  methods: {
    getOtherTag(data) {
      const res = data.filter(item=>item.name==this.tabName);
      return res.length ? res[0].name : ''
    },
    clickTab({val,status}) {
      this.tabIndex = val.id;
      this.tabName = val.name;
      // if(status) {
      //   const res = this.tabList.reduce((acc,item)=>{
      //     if(item.name == val.name) {
      //       acc.unshift(item)
      //     } else {
      //       acc.push(item);
      //     }
      //     return acc;
      //   },[]);
      //   this.tabList = res;
      // }
    },
    initSwiper() {
      const self = this;
      this.swiperProObj = new Swiper('.hotProswiper', {
        loop: false,
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 5,
      })
    },
    slideSwiper(type) {
      if(type==1) {
        this.swiperProObj.slidePrev();
      } else {
        this.swiperProObj.slideNext();
      }
    },
    getHot() {
      getProjectList({pinned: true})
      .then(res=>{
        this.loading = false;
        if(res.data && res.data.items) {
          this.hotList = res.data.items;
          this.$nextTick(()=>{
            this.initSwiper();
          })
        }
      })
    },
    getTabs() {
      getCategories('project')
      .then(res=> {
        const { data } = res;
        if(data) {
          if(data.items.length) {
            this.tabList = [{name:'全部',id: null}].concat(data.items);
            this.tabIndex = null;
          }
          this.getListData();
        }
      })
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.tableData.loading = true;
      }
      let params = {
        page: this.tableData.page,
        size: this.tableData.size,
      }
      if(this.tabIndex !== null) {
        params.category = this.tabIndex
      }
      getProjectList(params)
      .then(res=> {
        const data = res.data;
        const result = data.items;
        if(loadMore) {
          this.tableData.list = this.tableData.list.concat(result);
          this.loadMore = false;
        } else {
          this.tableData.list = result;
        }
        this.totalNums = res.data.totalPages * this.tableData.size;
        this.tableData.page = this.tableData.page+1;
        this.tableData.hasNext = data.hasNext;
        this.tableData.loading = false;
        this.refreshing = false;
      })
    },
    onLoad() {
      if(!this.loadMore && this.tableData.page!=1) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    onRefresh() {
      this.tableData.page = 1;
      this.loadMore = false;
      this.$nextTick(()=>{
        this.getListData();
      })
    },
    goDetail(item,index) {
      const scrollTop = document.documentElement.scrollTop
      sessionStorage.setItem('proPosition',scrollTop)
      this.$router.push('/h5/projectDetail/'+item.id);
    }
  },
  activated() {
    const value = sessionStorage.getItem('proPosition') || 0;
    setTimeout(() => {
      document.documentElement.scrollTop = value;
    }, 0);
  },
};
</script>
<style lang="less">
.project_h5_page {
  padding-top: 64px;
  padding-bottom: 68px;
  .top_bar {
    width: 100%;
    height: 64px;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .projectLib_title {
    margin-left: 15px;
    width: 90px;
    height: 21px;
    line-height: 21px;
    display: flex;
    align-items: center;
    background: url('../../../assets/images/projectLib/projectlibTitleicon.png') no-repeat left bottom / 100% 13px;
    margin-bottom: 15px;
    p {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
    }
    img {
      width: 13px;
      height: 16px;
      margin-left: 5px;
    }
  }
  .swiperNodata {
    width: 100%;
    padding: 0 10px;
    height: 72px;
    margin-bottom: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #999;
    }
  }
  .hotProswiper {
    height: 72px;
    margin-bottom: 19px;
    margin-left: 15px;
    .swiper-slide {
      width: 200px;
      height: 72px;
      background: url('./../../../assets/images/projectLib/swiperBg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .swiperLeft {
        flex: 1;
        display: flex;
        align-items: center;
        img {
          width: 38px;
          height: 38px;
          margin-right: 5px;
          border-radius: 100%;
        }
        p {
          font-size: 12px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 3px;
        }
        span {
          font-size: 10px;
          color: #E8E3D4;
          line-height: 13px;
        }
      }
      .swiperRight {
        height: 100%;
        text-align: right;
        p {
          font-size: 12px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 16px;
        }
        span {
          font-size: 10px;
          color: #E8E3D4;
        }
      }
    }
  }
  .allPrijectTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;
    .projectNumber {
      font-size: 14px;
      font-weight: 600;
      color: #4A4A4A;
      span {
        padding-left: 5px;
        color: #F0B200;
      }
    }
  }
  // 
  .projectLibTable {
    width: 100%;
    min-height: calc(100vh - 316px);
    .tableView {
      width: 100%;
      // min-height: 700px;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      margin-bottom: 30px;
      .tableTitle {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #737373;
        }
      }
      .tableItem {
        width: 100%;
        min-height: 75px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 14px;
        border-top: 1px solid #EDEDED;
        .itemTd1 {
          flex: 1;
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 100%;
          }
          p {
            font-size: 16px;
            line-height: 26px;
            font-weight: 600;
            color: #000;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
            span {
              padding: 2px 5px;
              font-size: 8px;
              font-weight: 500;
              color: #000;
              margin-right: 5px;
              margin-bottom: 5px;
              background: #FFF6D8;
            }
          }
        }
        .itemTd2 {
          text-align: right;
          p {
            font-size: 14px;
            font-weight: 600;
            color: #444444;
            line-height: 20px;
            margin-bottom: 3px;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #737373;
            line-height: 17px;
          }
        }
       
      }
      .tableTd1 {
        flex: 1;
        margin-right: 8px;
        text-align: left;
      }
      .tableTd2 {
        flex: 1;
        text-align: right;
      }
      
    }
    .moreDeepBtn {
      margin-left: 50px;
      margin-right: 26px;
      height: 49px;
      line-height: 49px;
      text-align: center;
      background: #F3F2F0;
      border-radius: 11px;
      font-size: 15px;
      font-weight: 600;
      color: #9C9C9C;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
    .loadingView {
      width: 100%;
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
  }
}
</style>
  