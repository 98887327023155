<template>
 <div class="top_input_view">
    <div class="leftImg">
      <img src="./../../../assets/h5Image/logo.png" alt="" @click="$router.replace('/h5/home')"/>
    </div>
    <div class="inputView" @click="$router.push('/h5/search')">
      <input type="text" readonly placeholder="搜索项目/机构/资讯"/>
      <div class="searchbtn"><img src="./../../../assets/h5Image/search_black.png" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5home",
  components: {
  },
  data() {
    return {
      searchVal: ''
    };
  },
  created() {
    // 

  },
  
  mounted() {
  },
  methods: { },
  
};
</script>

<style lang="less">
.top_input_view {
  width: 100%;
  height: 64px;
  padding-left: 25px;
  padding-right: 15px;
  background: url('./../../../assets/h5Image/tabbarTopBg1.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .leftImg {
      width: 103px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .inputView {
      flex: 1;
      display: flex;
      align-items: center;
      height: 34px;
      background: rgba(255,255,255,1);
      border-radius: 17px;
      padding: 0 18px;
      input {
        width: 100px;
        flex: 1;
        border: none;
        outline: none;
        font-size: 12px;
        background: transparent;
        margin-right: 5px;
        font-weight: 600;
        color: #000;
      }
      input::-webkit-input-placeholder {
        color: #CCCCCB;
        font-size: 12px;
      }
      .searchbtn {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        img {
          width: 22px;
          height: 22px;
          display: block;
        }
      }
    }
}
</style>