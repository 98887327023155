<template>
  <div class="projectInfo">
    <div class="projectTeam">
      <p class="teamNumber">团队成员<span>{{ info.members.length }}</span></p>
      <div class="teamList">
        <div class="teamItem" v-for="(item, index) in info.members" :key="index" :style="(index + 1) % 3 == 0 && { marginRight: 0 }">
          <img :src="item.avatar" alt="" class="personImg">
          <p class="personName">{{ item.name }}</p>
          <!-- <a href="" class="linkImg"><img src="@/assets/images/detailTwitterIcon.png" alt=""></a> -->
          <a :href="item.linkin" target="_blank" class="linkImg"><img src="@/assets/images/detailInsIcon.png" alt=""></a>
          <a :href="item.medium" v-if="item.medium" target="_blank" class="linkImg"><img src="@/assets/images/detailmedium.png" alt=""></a>
        </div>
      </div>
    </div>
    <div class="projectTable">
      <p class="orgNumber">投资机构<span>{{ info.institutions.length }}</span></p>
      <div class="tableView">
        <div class="tableTitle">
          <p class="tableTd1">投资人名称</p>
          <p class="tableTd2">类型</p>
          <p class="tableTd3">所在地区</p>
          <p class="tableTd4">成立年份</p>
          <p class="tableTd5">投资组合</p>
          <p class="tableTd7">社交</p>
        </div>
        <div class="tableItem" v-for="(item, index) in info.institutions"  @click="$router.push(`/organizationDetail/institution_${item.name.replace(/[^\w]|_/g, '').toLowerCase()}`)" :key="index"  >
          <div class="tableTd1 itemTd1">
            <img :src="item.logo" alt="">
            <p>{{ item.name }}</p>
          </div>
          <p class="tableTd2 itemTd2">{{ item.type }}</p>
          <p class="tableTd3 itemTd2">{{ item.country }}</p>
          <p class="tableTd4 itemTd2">{{ item.establishTime }}</p>
          <p class="tableTd5 itemTd2">{{ item.companyCount }}</p>
          <div class="tableTd7 itemTd7" @click.stop="">
            <a :href="item.homepage" target="_blank" v-if="item.homepage"><img src="@/assets/images/orgDetail/web.png" alt=""></a>
            <a :href="item.discord" target="_blank" v-if="item.discord"><img src="@/assets/images/detailDiscordIcon.png" alt=""></a>
            <a :href="item.twitter" target="_blank" v-if="item.twitter"><img src="@/assets/images/detailTwitterIcon.png" alt=""></a>
            <a :href="item.medium" target="_blank" v-if="item.medium" ><img src="@/assets/images/detailmedium.png" alt=""></a>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  name: "projectInfo",
  props: ['info'],
  data() {
    return {}
  },
  methods: {
  },
  mounted() {
  },

};
</script>
<style lang="less">
.projectInfo {
  .projectTeam {
    .teamNumber {
      font-size: 17px;
      font-weight: 600;
      color: #444444;
      margin-bottom: 30px;
      span {
        padding-left: 8px;
        color: #9C9C9C;
      }
    }
    .teamList {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      .teamItem {
        width: 382px;
        height: 68px;
        background: #FFFFFF;
        box-shadow: 0 2px 15px 0 #EEEEEE;
        border-radius: 11px;
        margin-right: 23px;
        margin-bottom: 23px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        .personImg {
          width: 34px;
          height: 34px;
          border-radius: 100%;
          flex-shrink: 0;
        }
        .personName {
          flex: 1;
          margin: 0 15px;
          font-size: 17px;
          
          font-weight: 600;
          color: #444444;
          line-height: 23px;
        }
        .linkImg {
          width: 23px;
          height: 23px;
          margin-right: 8px;
          flex-shrink: 0;
          border-radius: 100%;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          background: linear-gradient(270deg,#fff,#FFE8A6);
          box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
        }
      }
    }
  }

  .projectTable {
    width: 100%;
    min-height: calc(100vh - 316px);
    margin-top: 23px;
    .orgNumber {
      font-size: 17px;
      font-weight: 600;
      color: #444444;
      margin-bottom: 30px;
      span {
        padding-left: 8px;
        color: #9C9C9C;
      }
    }
    .tableView {
      width: 100%;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      margin-bottom: 30px;
      .tableTitle {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 22px;
        padding-right: 32px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #000;
        }
      }
      .tableItem {
        width: 100%;
        min-height: 80px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 22px;
        padding-right: 32px;
        border-top: 1px solid #EDEDED;
        &:hover {
          background: linear-gradient(270deg,#fff,#FFE8A6);
          box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
        }
        .itemTd1 {
          display: flex;
          align-items: center;
          img {
            width: 54px;
            height: 54px;
            margin-right: 15px;
            border-radius: 100%;
            box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
          }
          p {
            font-size: 12px;
            font-weight: 600;
            color: #000;
          }
        }
        .itemTd2 {
          font-size: 12px;
          
          font-weight: 600;
          color: #000;
        }
        .itemTd7 {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          a {
            width: 23px;
            height: 23px;
            margin-left: 15px;
            border-radius: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
      .tableTd1 {
        flex: 1;
        margin-right: 8px;
      }
      .tableTd2,.tableTd3,.tableTd4,.tableTd5 {
        width: 180px;
        margin-right: 8px;
        text-align: center;
      }
      
      .tableTd7 {
        width: 120px;
        text-align: right;
      }
    }
    .moreDeepBtn {
      height: 49px;
      margin-bottom: 150px;
      line-height: 49px;
      background: #f3f2f0;
      border-radius: 11px;
      font-size: 15px;
      font-weight: 600;
      color: #9c9c9c;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
  }
}
</style>
  