<template>
  <div class="economic">
    <p class="economicTitle">代币模型</p>
    <div class="coinView">
      <div class="coinChart">
        <div class="chartTitle">
          <p>代币分布</p>
          <div>总筹款&nbsp;&nbsp;<span>{{ sum }}M</span></div>
        </div>
        <div id="chartView"></div>
        <div class="supply">
          <p>最大供应</p>
          <div>10B</div>
        </div>
      </div>
      <div class="coinTable">
        <div class="tableTitle">
          <p class="td1">阶段</p>
          <p class="td2">价格</p>
          <p class="td3">总筹款</p>
          <p class="td4">估值</p>
          <p class="td5">解锁周期</p>
        </div>
        <div class="tableItem" v-for="(item,index) in tokenList" :key="index">
          <div class="td1 tableItemtd1">
            <span :style="{background: item.color}"></span>
            <p>{{ item.stage }}</p>
          </div>
          <p class="td2">{{ item.price || '--' }}</p>
          <p class="td3">{{ item.valuation }}</p>
          <p class="td4">{{ item.roundRaise }}</p>
          <p class="td5">{{ item.vestingPeriod }}</p>
        </div>
      </div>
    </div>
    <p class="economicTitle">解锁时间</p>
    <div class="timeView">
      <div class="swiper-container time_swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in unlocks" :key="index+'unlock'">
            <div class="swiperItemTop">
              <div>
                <p>从</p>
                <p>{{ item.startDate }}</p>
              </div>
              <img src="@/assets/images/projectDetail/lock.png" alt="">
            </div>
            <div class="dashBorder"></div>
            <div class="swiperItemBottom">
              <div class="progress">
                <el-progress type="circle" :percentage="getPrecent(item.percent)" :width="90" color="#F0B200"></el-progress>
              </div>
              <div class="bottomInfo">
                <p class="stsystemTitle">{{ item.tokenomics_round }}</p>
                <p class="stsystemValue">{{item.days}}天内</p>
                <div class="num_value">
                  <div class="num_valueItem num">
                    <p>数量</p>
                    <p>{{ item.tokens }}</p>
                  </div>
                  <div class="num_valueItem">
                    <p>价值</p>
                    <p>{{ item.tokenValue }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  name: "economic",
  props: [ "info" ],
  data() {
    return {
      loadMore: false,
      unlocks: [],
      tokenList: [],
      sum: 0
    };
  },
  watch: {
    info() {
      if(this.info.unlocks || this.info.tokens) {
        this.unlocks = this.info.unlocks;
        this.dealchart();
        // this.chartList = this.
        setTimeout(()=>{
          this.initBannerSwiper();
        },100);
      }
    }
  },
  methods: {
    getPrecent(val) {
      const res = (val+'').split('%');
      return res[0]
    },
    inifChart() {
      const dataRouce = this.tokenList.reduce((acc,item)=>{
        acc.color.push(item.color || '#DC8201');
        acc.data.push({value: item.precentValue, name: item.stage});
        return acc
      },{color: [],data: []});
      console.log(dataRouce)
      var myChart = this.$echarts.init(document.getElementById('chartView'));
      //配置图表
      var option = {
        tooltip: {
          trigger: 'item'
        },
        color: dataRouce.color,
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            
            labelLine: {
              show: false
            },
            data:dataRouce.data
          }
        ]
      };
      myChart.setOption(option);
    },
    initBannerSwiper() {
      new Swiper('.swiper-container', {
        loop: false,
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 15,
          // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 分页器可以点击
        },
      })
    },
    dealchart() {
      let sum = 0;
      this.tokenList = this.info.tokens.map(item=>{
        const result = item.tokenPercent.split('%')[0];
        sum = sum + (item.valuation ? (item.valuation.split('M')[0]*1) : 0);
        return {
          ...item,
          precentValue: result * 1,
          color: '#'+item.color
        }
      })
      this.sum = sum; 
      setTimeout(()=>{
        this.inifChart();
      },100);
    }
  },
  mounted() {
    
    if(this.info.unlocks || this.info.tokens) {
      this.unlocks = this.info.unlocks;
      this.dealchart();
      // this.chartList = this.
      setTimeout(()=>{
        this.initBannerSwiper();
      },100);
    }
  },

};
</script>
<style lang="less">
.economic {
  .economicTitle {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    margin-bottom: 30px;
  }
  .coinView {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    .coinChart {
      width: 375px;
      height: 454px;
      background: #FFFFFF;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      .chartTitle {
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 17px;
        margin-top: 15px;
        p {
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
        div {
          font-size: 15px;
          font-weight: 500;
          color: #000000;
          span {
            padding: 5px 14px;
            background: #000000;
            border-radius: 15px;
            font-size: 15px;
            font-weight: 600;
            color: #FFFFFF;
          }
        }
      }
      #chartView {
        width: 375px;
        height: 344px;
      }
      .supply {
        padding-left: 30px;
        padding-right: 17px;
        p {
          font-size: 15px;
          font-weight: 500;
          color: #737373;
          line-height: 21px;
          margin-bottom: 5px;
        }
        div {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .coinTable {
      width: 793px;
      background: #FFFFFF;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      .tableTitle {
        width: 100%;
        height: 57px;
        display: flex;
        align-items: center;
        padding: 0 38px;
        p {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          line-height: 21px;
        }
      }
      .tableItem {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 38px;
        border-top: 1px solid #EDEDED;
        cursor: pointer;
        &:hover {
          background: linear-gradient(270deg,#fff,#FFE8A6);
          box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
        }
        .tableItemtd1 {
          display: flex;
          align-items: center;
          span {
            width: 19px;
            height: 19px;
            background: #DC8201;
            margin-right: 10px;
            border-radius: 100%;
            flex-shrink: 0;
          }
        }
        p {
          font-size: 12px;
          color: #000;
          font-weight: 600;
          line-height: 17px;
        }
      }
      .td1 {
        text-align: left;
        width: 90px;
        margin-right: 8px;
      }
      .td2,.td3,.td4 {
        width: 100px;
        text-align: center;
        margin-right: 8px;
      }
      .td5 {
        margin-left: 18px;
        flex: 1;
        text-align: left;
      }
    }
  }
  .timeView {
    width: 1220px;
    height: 295px;
    border-radius: 11px;
    border: 1px solid #EDEDED;
    margin-bottom: 150px;
    padding-top: 30px;
    padding-left: 30px;
    .time_swiper {
      width: 100%;
      height: 265px;
      .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 23px;
      }
      .swiper-pagination-bullet {
        width: 40px;
        height: 5px;
        background: #EDEDF2;
        opacity: 1;
        border-radius: 0;
      }
      .swiper-pagination-bullet-active {
        background: #FFD14D;
      }
      // 
      .swiper-slide {
        width: 339px;
        height: 220px;
        background: #FFFFFF;
        border-radius: 11px;
        border: 1px solid #EDEDED;
        padding: 0 15px;
        .swiperItemTop {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 13px 0;
          p {
            &:first-child {
              font-size: 15px;
              font-weight: 500;
              color: #9C9C9C;
              line-height: 21px;
            }
            &:last-child {
              font-size: 15px;
              font-weight: 500;
              color: #000000;
              line-height: 21px;
            }
          }
          img {
            width: 14px;
            height: 18px;
            margin-right: 20px;
          }
        }
        .dashBorder {
          width: 100%;
          border-top: 1px dashed #ADB0C2;
          margin-bottom: 15px;
        }
        .swiperItemBottom {
          display: flex;
          .progress {
            margin-top: 6px;
            width: 90px;
            height: 90px;
            margin-right: 50px;
          }
        }
        .bottomInfo {
          .stsystemTitle {
            font-size: 15px;
            font-weight: 500;
            color: #000000;
            line-height: 21px;
          }
          .stsystemValue {
            padding-left: 19px;
            background: url('./../../../../assets/images/projectDetail/lockTime.png') no-repeat left center / 15px 15px;
            font-size: 12px;
            font-weight: 500;
            color: #9C9C9C;
            line-height: 17px;
          }
          .num_value {
            display: flex;
            margin-top: 25px;
            .num_valueItem {
              p {
                &:first-child {
                  font-size: 12px;
                  font-weight: 500;
                  color: #9C9C9C;
                  line-height: 17px;
                }
                &:last-child {
                  font-size: 14px;
                  font-weight: 500;
                  color: #000000;
                  line-height: 19px;
                }
              }
            }
            .num {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
  