<template>
  <div class="fast_detail_h5">
    <van-sticky>
      <topInput />
      <downloadApp />
    </van-sticky>
    <div class="detail_h5_content" v-if="info.title">
      <p class="detail_h5_content_title">{{ info.title }}</p>
      <p class="detail_h5_content_time">{{ $dayjs(info.publishTime).format('YYYY-MM-DD HH:mm') }}</p>
      <div style="display: flex;" v-if="info.relates && info.relates.length">
        <div class="detail_h5_otherpro" v-for="rel in info.relates"  @click="$router.push('/h5/projectDetail/'+rel.id)">
          <img :src="rel.icon" alt="">
          <div>
            <p class="otherpro_name">{{ rel.title }}</p>
            <span v-if="rel.tags && rel.tags.length">{{ rel.tags[0].name }}</span>
          </div>
        </div>
      </div>
      
      <div class="detail_h5_content_txt" v-html="info.content"></div>
      <!-- 提示文案 -->
      <a class="oldLink" :href="info.url" target="_blank" v-if="info.url">原文链接↗</a>
      <p class="detailTips" v-if="info.id">本内容只为提供市场信息，不构成投资建议。</p>
    </div>
    <div v-else class="nodata">
      <van-loading color="#f0b200" :size="34"/>
    </div>
    <div class="deep_list">
      <hotArticle />
    </div>
    <footerView />
    <div class="detail_h5_btn">
      <!-- <div class="detail_h5_btn_item" @click="copy(info)">
        <img src="./../../../assets/h5Image/copy0.png" alt="">
      </div> -->
      <div class="detail_h5_btn_item" @click="shareModal=true">
        <img src="./../../../assets/h5Image/share0.png" alt="">
      </div>
    </div>
    <!-- 分享 -->
    <van-popup v-model="shareModal"  position="top"> 
      <sharedialog :item="info" ref="imgele" @close="shareModal=false"  v-if="shareModal" />
    </van-popup>
    <!-- 分享图片 -->
    <!-- <sharedialog :item="info" ref="imgele" v-if="shareModal"/> -->
  </div>
</template>
<script>
import { getDetail } from '../../../api';
import sharedialog from '../home/component/sharedialog';
import downloadApp from '../components/downloadApp.vue';
import footerView from '../components/footer.vue';
import QRCode from 'qrcode';
import topInput from './../components/topInput';
import hotArticle from './hotArticle'
export default {
  name: "detail",
  components: {
    sharedialog,
    downloadApp,
    footerView,
    topInput,
    hotArticle
  },
  data() {
    return {
      info: {
        title: '',
        owner: {},
        cover: '',
        icon: '',
        publishTime: '',
        excerpt: '',
        categories: [],
        tags: [],
        content: ''
      },
      weborigin: window.location.origin,
      shareModal: false,
    };
  },
  methods: {
    getDetailData(id) {
      getDetail(id).then(res=>{
        this.$utils.shareWX({title: res.data.title, intro: res.data.excerpt.replace(/<\/?p[^>]*>/g, ''),img: 'https://web3cn.pro/logo.png',url: window.location.href})
        document.title = res.data.title;
        this.info = res.data;
      })
    },
    copy(item) {
      this.$utils.copy(window.location.origin +'/h5/fastDetail/'+ item.id,()=>{
        this.$toast.success("复制成功");
      })
    },
    shareWx() {
      const str = window.location.origin +'/h5/fastDetail/'+ this.info.id;
      QRCode.toDataURL(str)
      .then(url => {
        this.$dialog.alert({
          title: '微信扫一扫分享',
          message: `<img src="${url}"/>`,
        })
        this.shareModal = false;
      })
      .catch(err => {
        console.error(err)
      })
      
    },
    shareCopy() {
      this.copy(this.info);
      this.shareModal = false;
    },
    shareSave() {
      this.$refs.imgele.downloadImg();
      this.shareModal = false;
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDetailData(id);
    }
  },
 
};
</script>
<style lang="less">
.fast_detail_h5 {
  .van-nav-bar .van-icon {
    color: #444444;
    font-size: 22px;
  }
  .van-nav-bar__title{
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }

  .detail_h5_content {
    width: 100%;
    // min-height: calc(100vh - 78px );
    padding: 15px;
    padding-bottom: 60px;
    .detail_h5_content_title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      line-height: 29px;
      margin-bottom: 15px;
    }
    .detail_h5_content_time {
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9C9C9C;
      margin-bottom: 14px;
    }
    .detail_h5_otherpro {
      width: 212px;
      height: 53px;
      background: #FAF9F8;
      margin-bottom: 15px;
      padding: 10px;
      display: flex;
      align-items: center;
      margin-right: 5px;
      border-radius: 6px;
      :last-child {
        margin-right: 0;
      }
      img {
        width: 34px;
        height: 34px;
        border-radius: 4px;
        margin-right: 5px;
      }
      .otherpro_name {
        font-size: 12px;
        font-weight: 500;
        color: #4A4A4A;
        line-height: 16px;
      }
      span {
        padding: 2px 6px;
        background: #FFF6D8;
        color: #000;
        font-size: 10px;
      }
     
    }
    .detail_h5_content_txt {
      font-size: 15px;
      font-weight: 500;
      color: #444444;
      line-height: 24px;
      word-break: break-all;
      text-align: justify;
      * {
        word-break: break-all;
      }
      a {
        color: #CDCDCD;
      }
      img {
        max-width: 100%;
        min-width: 110px;
        display: block;
        max-width: 100%;
        height: auto !important;
        margin: auto;
        object-fit: contain;
        overflow-clip-margin: content-box;
        overflow: clip;
      }
      p {
        margin-bottom: 10px;
      }
    }
    .oldLink {
      display: block;
      margin-top: 15px;
      font-size: 13px;
      font-weight: 500;
      color: #F0B200;
      cursor: pointer;
    }
    .detailTips {
      margin-top: 15px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #CCCCCB;
      margin-bottom: 30px;
    }
  }
  .detail_h5_btn {
    position: fixed;
    bottom: 20px;
    right: 22px;
    width: 47px;
    div {
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 21px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .van-popup {
    background: none;
  }
  .shareModal {
    padding-top: 10px;
    padding-bottom: 21px;
    background: #F8F8F8;
    border-radius: 10px 0 10px 0;
    .shareTitle {
      font-size: 13px;
      font-weight: 500;
      color: #9C9C9C;
      margin-bottom: 20px;
      text-align: center;
    }
    .shareIconView {
      display: flex;
      align-items: center;
      padding: 0 27px;
      margin-bottom: 14px;
      div,a {
        width: 48px;
        margin-right: 29px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        color: #9C9C9C;
        line-height: 16px;
        margin-top: 5px;
        text-align: center;
      }
      img {
        width: 48px;
        height: 48px;
        
      }
    }
    .shareBtn {
      margin: 0 15px;
      height: 46px;
      background: #FFFFFF;
      border-radius: 23px;
      font-size: 17px;
      font-weight: 500;
      color: #444444;
      text-align: center;
      line-height: 46px;
    }
  }
  .van-overlay {
    background: rgba(0,0,0,0.1);
  }
  .nodata {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
  