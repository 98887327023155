<template>
<div class="recommendOrg">
  <div class="hotprojectTitle">
    <p>活跃机构</p>
    <span @click="$router.push('/organization')">查看更多</span>
  </div>
  <div class="hotprojectTable">
    <div class="hotprojectTable_title">
      <p class="td1">机构名称</p>
      <p class="td4">项目数</p>
    </div>
    <div class="hotprojectTableContent">
      <div v-if="fastMsgList.length">
        <div v-for="item in fastMsgList" class="hotprojectTableItem" :key="item.id" @click="$router.push('/organizationDetail/'+item.id)">
          <div class="projectName td1">
            <img :src="item.icon" alt="">
            <div>
              <p>{{ item.title }}</p>
              <!-- <span class="showline1">{{ item.excerpt }}</span> -->
            </div>
          </div>
          <p class="infoTxt td4">{{ item.projectCount }}</p>
        </div>
      </div>
      <div class="loadingView" v-else>
        <van-loading color="#f0b200" v-if="loading" :size="54"/>
        <p v-else>暂无数据</p>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { getOrgList } from '@/api/index'
export default {
  name: "hot",
  props: [],
  data() {
    return {
      fastMsgList: [],
      loading: true
    }
  },
  methods: {
    getHot() {
      getOrgList({pinned: true,sort:'SORT_VALUE',sortOrder:false,size:5})
      .then(res=>{
        if(res.data) {
          this.fastMsgList = res.data.items;
          this.loading = false;
        }
      })
    },
  },
  mounted() {
   this.getHot();
  },
  
};
</script>
<style lang="less">
.recommendOrg {
  padding: 15px 0;
  width: 100%;
  margin-bottom: 30px;
  .hotprojectTitle {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
    p {
      width: 117px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      color: #444444;
      background: url('./../../../assets/images/projectLib/projectlibTitleicon.png') no-repeat left bottom / 100% auto ;
    }
    span {
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      color: #9C9C9C;
      background: url('./../../../assets/images/arrow_right.png') no-repeat right center / 7px 11px;
      padding-right: 10px;
      cursor: pointer;
    }
  }
  .hotprojectTable {
    width: 100%;
    min-height: 366px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #EDEDED;
    padding: 20px 8px;
    .hotprojectTable_title {
      // padding: 0 8px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      p {
        font-size: 11px;
        font-weight: 500;
        color: #737373;
      }
    }
    .hotprojectTableItem {
      width: 100%;
      height: 80px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;
      &:hover {
        background: rgba(206,202,190,0.2);
      }
      .projectName {
        display: flex;
        align-items: center;
        img {
          width: 54px;
          height: 54px;
          margin-right: 8px;
          border-radius: 8px;
          box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
        }
        p {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          line-height: 20px;
        }
        span {
          font-size: 12px;
          color: #9C9C9C;
          line-height: 17px;
        }
      }
      .infoTxt {
        font-size: 12px;
        font-weight: 600;
        color: #000000;
      }
    }
    .td1 {
      flex: 1;
    }
    .td2,.td3 {
      padding: 0 9px;
      text-align: center;
      flex-shrink: 0;
    }
    .td4 {
      padding-left: 9px;
      width: 58px;
      text-align: right;
    }
  }
  .morehotproject {
    width: 285px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 11px;
    line-height: 38px;
    font-size: 15px;
    font-weight: 600;
    color: #9C9C9C;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .loadingView {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
