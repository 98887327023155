<template>
<div class="financ">
  <div class="financTitle">
    <p>最新收录</p>
    <span>{{$dayjs(new Date()).format('YYYY年MM月DD日')}}星期{{ weekMap[$dayjs(new Date()).day()]}}</span>
  </div>
  <div v-if="fastMsgList.length">
    <div class="financList">
      <div class="financItem" v-for="(item,index) in fastMsgList" :key="index" @click="$router.push('/projectDetail/'+item.id)">
        <div class="financItemLogo">
          <img :src="item.icon" alt="">
        </div>
        <div style="flex:1">
          <div style="display: flex;">
            <p class="financItemTitle showline1" style="flex:1">{{ item.title }}<span>{{ $dayjs(item.publishTime).format('MM/DD') }}</span></p>
            <p class="financItemMoney">{{ item.financingAmount }}</p>
          </div>
          
          <p class="financItemIntro showline2">{{ item.excerpt }}</p>
        </div>
        
      </div>
    </div>
  </div>
  <div class="loadingView" v-else>
    <van-loading color="#f0b200" v-if="loading" :size="54"/>
    <p v-else>暂无数据</p>
  </div>
  
</div>
</template>
<script>
import { getProjectList } from '@/api/index';
export default {
  name: "hot",
  props: [],
  data() {
    return {
      weekMap: {
        '0': '日',
        '1': '一',
        '2': '二',
        '3': '三',
        '4': '四',
        '5': '五',
        '6': '六',
      },
      fastMsgList: [],
      loading: true
    }
  },
  methods: {
    
  },
  mounted() {
    getProjectList({page: 0, size: 5, order: 'PUBLISH_TIME', orderSort: false})
    .then(res=> {
      console.log(res)
      const data = res.data;
      this.fastMsgList = data.items;
      this.loading = false;
    })
  },
  
};
</script>
<style lang="less">
.financ {
  padding: 15px 14px;
  width: 100%;
  background: linear-gradient(180deg, rgba(249,217,0,0.2) 0%, rgba(240,178,0,0.1) 100%);
  border-radius: 8px;
  margin-bottom: 23px;
  .financTitle {
    width: 100%;
    height: 37px;
    background: url('./../../../assets/images/articelTitleIcon.png') no-repeat left center / auto 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 23px;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #444444;
      margin-right: 30px;
    }
    span {
      font-size: 15px;
      font-weight: 500;
      color: #737373;
    }
  }
  .financList {
    .financItem {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding-bottom: 23px;
      cursor: pointer;
      .financItemLogo {
        width: 54px;
        height: 54px;
        margin-right: 8px;
        border-radius: 100%;
        box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .financItemTitle,.financItemMoney {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        line-height: 21px;
        margin-bottom: 5px;
        span {
          color: #737373;
          padding-left: 5px;
          font-weight: 500;
        }
      }
      .financItemIntro {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        color: #737373;
        line-height: 18px;
      }
      &:last-child {
        border: none;
      }
      &:hover {
        .financItemTitle, .financItemMoney{
          color: #737373;
        }
        .financItemIntro { 
          color: #9c9c9c;
        }
      }
    }
  }
  .morefinanc {
    width: 285px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 11px;
    line-height: 38px;
    font-size: 15px;
    font-weight: 600;
    color: #9C9C9C;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .loadingView {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
