<template>
  <div class="search_page_left">
    <div class="tabView">
      <div class="tab">
        <p class="tab_active">本站</p>
        <p @click="$emit('changeTab')">Mirror</p>
      </div>
      <p class="searchNum">包含<span>{{searchValue}}</span>的结果<span style="padding-left:5px">共{{ (loading1 || loading2 )? 0 : searchTotal1+searchTotal2 }}条</span></p>
    </div>
    <div v-if="!loading1">
      <div v-if="projectList.length">
        <div class="searchResultTitle">
          项目
        </div>
        <div class="listView">
          <div class="org" v-for="item in projectList" :key="item.id" @click="$router.push('/projectDetail/'+item.id)">
            <div class="orgTitle">
              <img :src="item.icon" alt="">
              <p v-html="item.title"></p>
            </div>
            <p class="orgContent" v-html="item.excerpt"></p>
          </div>
        </div>
      </div>
      
      <div v-if="orgList.length">
        <div class="searchResultTitle">
          机构
        </div>
        <div class="listView">
          <div class="orgItem" v-for="item in orgList" :key="item.id" @click="$router.push('/organizationDetail/'+item.id)">
            <img :src="item.icon" alt="">
            <p v-html="item.title"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="searchLoading">
      <van-loading color="#f0b200" :size="54"/>
    </div>
<!--  -->
    <div v-if="!loading2">
      <div v-if="fastList.length">
        <div class="searchResultTitle">
          快讯
        </div>
        <div class="listView fastItem" v-for="item in fastList" :key="item.id" @click="$router.push('/fastDetail/'+item.id)">
          <div class="hoverbg"></div>
          <p class="fastItemTitle" v-html="item.title"></p>
          <p class="fastItemIntro" v-html="item.excerpt"></p>
        </div>
      </div>

      <div v-if="deepList.length">
        <div class="searchResultTitle">
          文章
        </div>
        <div class="listView" v-for="(item,index) in deepList" :key="index" @click="$router.push('/deepDetail/'+item.id)">
          <articleItem :info="item"  :otherFlex="true" :search="true"/>
          <articleBorder :direction="index%2==0?'left':'right'" />
        </div>
      </div>
    </div>
    <div v-else class="searchLoading">
      <van-loading color="#f0b200" :size="54"/>
    </div>

  </div>
</template>
<script>
import articleBorder from './articleBorder';
import articleItem from './articleItem';
import { searchResult1,searchResult2 } from '@/api/index';
export default {
  name: "detail",
  props: [ 'searchValue' ],
  components: {
    articleBorder,
    articleItem,
  },
  data() {
    return {
      projectList: [],
      orgList: [],
      deepList: [],
      fastList: [],

      searchTotal1: 0,
      searchTotal2: 0,
      loading1: true,
      loading2: true
    };
  },
  watch: {
    searchValue() {
      this.searchOwn();
    }
  },
  methods: {
    searchOwn() {
      this.search1();
      this.search2();
    },
    search1() {
      this.loading1 = true;
      searchResult1({ keyword: this.searchValue })
      .then(res=>{
        const { data } = res;
        if(data.items && data.items.length) {
          const regex = new RegExp(this.searchValue, 'gi'); 
          const result = data.items.reduce((acc,item)=>{
            if(item._type == 'institution') {
              acc.org.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            if(item._type == 'project') {
              acc.pro.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            return acc
          },{pro:[],org:[],fast:[],deep:[]});
          this.projectList = result.pro;
          this.orgList = result.org;
          
          this.searchTotal1 = data.total;
        } else {
          this.projectList =  [];
          this.orgList =  [];
         
          this.searchTotal1 =  0;
        }
        this.loading1 = false;
      })
      .catch(()=>{
        this.loading1 = false;
      })
    },
    search2() {
      this.loading2 = true;
      searchResult2({ keyword: this.searchValue })
      .then(res=>{
        const { data } = res;
        if(data.items && data.items.length) {
          const regex = new RegExp(this.searchValue, 'gi'); 
          const result = data.items.reduce((acc,item)=>{
            if(item._type == 'flash') {
              // 创建一个正则表达式对象，g表示全局匹配，i表示不区分大小写
              acc.fast.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            if(item._type == 'article') {
              acc.deep.push({
                ...item,
                title: item.title.replace(regex, (match) => `<span style='color:#F0B200'>${match}</span>`),
              })
            }
            return acc
          },{pro:[],org:[],fast:[],deep:[]});
          this.fastList = result.fast;
          this.deepList = result.deep;
          this.searchTotal2 = data.total;
        } else {
          this.deepList =  [];
          this.fastList =  [];
          this.searchTotal2 =  0;
        }
        this.loading2 = false;
      })
      .catch(()=>{
        this.loading2 = false;
      })
    },
  },
  mounted() {
  },
  destroyed () {
    localStorage.removeItem('ROUTE_NAME')
  },
 
};
</script>
<style lang="less">
.search_page {
  padding-top: 37px;
  display: flex;
  width: 1220px;
  margin: 0 auto;
  .search_page_left {
    flex: 1;
    .tabView {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tab {
        display: flex;
        width: 142px;
        height: 34px;
        border-radius: 8px;
        background: #EEEEEE;
        p {
          flex: 1;
          height: 34px;
          font-size: 14px;
          font-weight: 600;
          color: #9C9C9C;
          text-align: center;
          line-height: 34px;
          cursor: pointer;
        }
        .tab_active {
          border-radius: 8px;
          background: #444444;
          color: #fff;
        }
      }
      .searchNum {
        font-size: 14px;
        font-weight: 600;
        color: #9C9C9C;
        span {
          color: #F0B200;
        }
      }
    }
    .searchResultTitle {
      width: 75px;
      height: 32px;
      background: url('./../../../assets/images/linearBg.png') no-repeat;
      background-size: 100% 100%;
      border-radius: 16px;
      position: relative;
      margin-bottom: 23px;
      line-height: 32px;
      text-align: left;
      font-size: 23px;
      font-weight: 600;
      color: #4A4A4A;
    }
    .listView {
      margin-bottom: 25px;
    }

    .org {
      width: 100%;
      padding: 23px 21px 17px 15px;
      background: rgba(255,255,255,0.2);
      box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
      border-radius: 11px;
      margin-bottom: 30px;
      cursor: pointer;
      &:hover {
        background: linear-gradient(270deg,#fff,#FFE8A6);
        box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
      }
      .orgTitle {
        display: flex;
        align-items: center;
        img {
          width: 34px;
          height: 34px;
          border-radius: 100%;
          margin-right: 15px;
          box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
        }
        p {
          font-size: 17px;
          font-weight: 600;
          color: #000;
          text-align: justify;
        }
      }
      .orgContent {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 23px;
        margin-top: 21px;
        text-align: justify;
      }
    }

    .orgItem {
      width: 100%;
      padding: 23px 15px;
      background: rgba(255,255,255,0.2);
      box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
      border-radius: 11px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: linear-gradient(270deg,#fff,#FFE8A6);
        box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
      }
      img {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        margin-right: 15px;
        box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
      }
      p {
        font-size: 17px;
        font-weight: 600;
        color: #000;
      }
    }

    .fastItem {
      margin-top: 50px;
      cursor: pointer;
      position: relative;
      .hoverbg {
        position: absolute;
        left: -10px;
        top: -20px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        display: none;
        border-radius: 8px;
      }
      &:hover {
        .hoverbg {
          display: block;
          box-shadow: 0 -12px 20px -6px rgba(140,150,162,.2);
          border-radius: 6px;
          z-index: 2;
        }
        .fastItemTitle {
          color: #F0B200;
        }
      }
      .fastItemTitle {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        line-height: 24px;
        margin-bottom: 11px;
        text-align: justify;
      }
      .fastItemIntro {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 23px;
        text-align: justify;
      }
    }

    .searchLoading {
      width: 100%;
      min-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
  }
  .search_page_right {
    flex-shrink: 0;
    width: 374px;
    margin-left: 60px;
    
  }
}
</style>
  