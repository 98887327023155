<template>
 <div class="footer_view">
  <div class="footer_view_content">
    <div class="footer_top">
      <div class="footer_top_left">
        <div class="footer_webcn">
          <img src="@/assets/images/logo.png" alt="">
          <p>更懂你的WEB3资讯平台</p>
        </div>
        <p class="footer_intro">Web3CN是一个专业的web3信息服务平台，为用户提供及时、专业且有品质的web3资讯和数据，致力于让更多的人进入web3并捕捉到web3的市场机遇。</p>
        <div class="footer_link">
          <a href="https://twitter.com/Web3CN_Pro" target="_blank"><img src="@/assets/images/twitter.png" alt=""></a>
          <a href="https://discord.com/invite/CDAkZ8Kc3K" target="_blank"><img src="@/assets/images/discord.png" alt=""></a>
          <a href="mailto:marketing@web3cn.pro" target="_blank"><img src="@/assets/images/msg.png" alt=""></a>
        </div>
      </div>
      <div class="footer_top_right">
        <img src="@/assets/images/code1.png" alt="">
        <img src="@/assets/images/wx.png" alt="">
      </div>
    </div>
    <div class="footer_bottom">
      <a :href="url+'/privacypolicy'" target="_blank">隐私政策</a>
      <a :href="url+'/job'" target="_blank">工作机会</a>
      <a :href="url+'/copyright'" target="_blank">版权申明</a>
      <a>Copyright © 2021-2023 浙ICP备2021024901号</a>
    </div>
    <div class="footer_icon" :style="ele && { right: '40px'}" v-if="!hideTop">
      <div class="phone">
        <img src="@/assets/images/phoneicon.png" alt="" class="footer_fixedicon" @click="showDownload=!showDownload">
        <div class="download_view" v-show="showDownload">
          <div class="download_app">
            <img src="@/assets/images/downloadicon_pg.png" alt="">
            <p>IOS下载</p>
          </div>
          <div class="download_app">
            <img src="@/assets/images/downloadicon_az.png" alt="">
            <p>安卓下载</p>
          </div>
        </div>
      </div>
      <div class="totop" @click="backTop">
        <img src="@/assets/images/totopicon.png" alt="" class="footer_fixedicon">
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "border",
  props: [ 'ele', 'hideTop'],
  data() {
    return {
      showDownload: false,
      url: window.location.origin
    }
  },
  methods: {
    backTop() {
      let scrollElem = this.ele;
      if(!scrollElem) {
        this.$emit('goTop');
        return 
      }
      scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  mounted() {
    
  },
  
};
</script>
<style lang="less">
  .footer_view {
    width: 100%;
    padding: 36px 0 19px 0 ;
    background: linear-gradient(135deg, #191919 0%, #F0B200 100%);
    .footer_view_content {
      width: 1220px;
      min-height: 244px;
      margin: 0 auto;
    }
    .footer_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 13px;
      border-bottom: 1px solid rgba(206,202,190,0.2);
      .footer_top_left {
        .footer_webcn {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          img {
            width: auto;
            height: 29px;
            margin-right: 27px;
          }
          p {
            font-size: 19px;
            font-weight: 600;
            color: #FFFFFF;
            padding: 2px 0;
            padding-left: 27px;
            border-left: 1px solid #CECABE;
            letter-spacing: 1px;
          }
        }
        .footer_intro {
          width: 665px;
          font-size: 11px;
          font-weight: 600;
          color: #9C9C9C;
          line-height: 19px;
          margin-bottom: 22px;
        }
        .footer_link {
          display: flex;
          align-items: center;
          a {
            width: 45px;
            height: 45px;
            margin-right: 30px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }

      }
      .footer_top_right {
        display: flex;
        align-items: center;
        img {
          width: 105px;
          height: 105px;
          margin-right: 30px;
          border-radius: 11px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .footer_bottom {
      margin-top: 15px;
      display: flex;
      align-items: center;
      a {
        font-size: 12px;
        font-weight: 500;
        color: #9C9C9C;
        margin-right: 23px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .footer_icon {
    position: fixed;
    bottom: 30px;
    right: 15px;
    .footer_fixedicon {
      width: 41px;
      height: 41px;
      cursor: pointer;
    }
    .phone {
      position: relative;
      margin-bottom: 30px;
    }
    .download_view {
      position: fixed;
      right: 63px;
      bottom: 70px;
      width: 206px;
      height: 108px;
      background: url('./../../../assets/images/index_right_top_bg.png') no-repeat;
      background-size: 100% 100%;
      border-radius: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      .download_app {
        &:last-child {
          margin-left: 30px;
        }
      }
      img {
        width: 45px;
        height: 46px;
      }
      p {
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-top: 8px;
      }
    }
    
  }
  
</style>
