<template>
  <div id="app_view">
    <keep-alive>
      <router-view v-if="$route.meta.keepLive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepLive"/>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      
    }
  },
  
  mounted() {
    const url = window.location.origin;
    const href = window.location.href;
    const param = href.split('?')[1] || '';
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      
      if(!href.includes('h5')) {
        window.location.href = `${url}/h5${window.location.pathname}${param ? '?'+param :''}`
      }
      
    } else {
      if(href.includes('h5')) {
        const path = window.location.pathname.replace('/h5','')
        window.location.href = `${url}${path}${param ? '?'+param :''}`
      } else {
        
      }
     
    }

  },
  methods: {
  
  }
};
</script>

<style>
#app_view {
  
}
</style>
