<template>
  <div style="overflow:auto;height:100vh" ref="projectDetailScroll">
    <div class="projectDetail_page">
      <div class="detailIntroduce">
        <div class="projectLogo">
          <img :src="info.icon" alt="">
        </div>
        <div class="projectInfo">
          <div class="info1">
            <p>{{ info.title }}</p>
            <!-- <span>{{ info.type }}</span> -->
          </div>
          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <p class="info2">{{ info.excerpt }}</p>
            <div class="info3" v-if="info.tags || info.tags.length">
              <p v-for="(item,index) in info.tags" :key="index">{{ item.name }}</p>
            </div>
          </div>
          
          <div class="projectLink">
            <a :href="info.contact && info.contact.homepage" target="_blank" v-if="info.contact && info.contact.homepage"><img src="@/assets/images/orgDetail/web.png" alt=""></a>
            <a :href="info.contact && info.contact.telegram" target="_blank" v-if="info.contact && info.contact.telegram"><img src="@/assets/images/detailAirIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.twitter" target="_blank" v-if="info.contact && info.contact.twitter"><img src="@/assets/images/detailTwitterIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.facebook" target="_blank" v-if="info.contact && info.contact.facebook"><img src="@/assets/images/detailFaceIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.discord" target="_blank" v-if="info.contact && info.contact.discord"><img src="@/assets/images/detailDiscordIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.medium" target="_blank" v-if="info.contact && info.contact.medium" ><img src="@/assets/images/detailmedium.png" alt=""></a>
          </div>
        </div>
      </div>
      <div class="projectDetail_Content">
        <div class="detailTabView">
          <el-tabs v-model="activeName">
            <el-tab-pane label="项目动态" name="1"></el-tab-pane>
            <el-tab-pane label="项目信息" name="2"></el-tab-pane>
            <el-tab-pane label="经济模型" name="3" v-if="info.unlocks || info.token"></el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <!-- <div class="baseInfo">
            <p>基本信息</p>
          </div> -->
          <div class="projectIntro">
            <p :class="showMore && 'showline2'" >
              <span ref="moreText" v-html="info.content"></span>
            </p>
            <div class="showMoreBtn" @click="showMore = !showMore" v-if="isMoreBtn">
              <p v-if="showMore">...</p>
              <img src="@/assets/images/projectDetail/bottom.png" :style="!showMore&& {transform: 'rotate(180deg)'}" alt="">
            </div>
          </div>
          <!-- <div class="downloadFile">
            <div class="fileItem">
              <div class="fileTxt">
                <p class="fileName">项目投研报告</p>
                <p class="fileLink">
                  <span>点击查看</span>
                </p>
              </div>
              <img src="@/assets/images/projectDetail/baogao.png" alt="" />
            </div>
            <div class="fileItem">
              <div class="fileTxt">
                <p class="fileName">空投攻略</p>
                <p class="fileLink">
                  <span>点击查看</span>
                </p>
              </div>
              <img src="@/assets/images/projectDetail/gonglue.png" alt="" />
            </div>
          </div> -->
        </div>

        <!-- 项目动态 -->
        <projectDynamic v-if="activeName==1" />
        <!-- 项目信息 -->
        <projectInfo v-if="activeName==2" :info="info"/>
        <!-- 经济模型 -->
        <economic v-if="activeName==3" :info="info"/>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.projectDetailScroll"/>
  </div>
</template>
<script>
import projectInfo from './components/projectInfo';
import projectDynamic from './components/projectDynamic';
import economic from './components/economic';
import { getProjectDetail } from '@/api/index';
import footerView from './../components/footerView';
export default {
  components: {
    projectInfo,
    projectDynamic,
    economic,
    footerView
  },
  name: "detail",
  data() {
    return {
      activeName: '1',
      showMore: true,
      info: {
        tags: [],
        content: '',
        contact: {
          homepage: '',
          telegram: '',
          twitter: '',
          discord: '',
          facebook: ''
        },
        members: [],
        institutions: [],
      },
      isMoreBtn: false
    };
  },
  watch: {
    "info.content"() {
      if(this.info.content) {
        setTimeout(() => {
          const height = this.$refs.moreText.offsetHeight;
          if(height*1 > 52) {
            this.isMoreBtn = true
          }
        },0);
      }
    }
  },
  methods: {
    getDetailData(id) {
      getProjectDetail(id)
      .then(res=>{
        this.info = res.data;
        document.title = res.data.title;
      })
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDetailData(id);
    }
  },
 
};
</script>
<style lang="less">
.projectDetail_page {
  margin: 0 auto;
  .detailIntroduce {
    width: 100%;
    min-height: 188px;
    background: url('./../../../assets/images/projectDetail/introducebg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    padding: 45px 0;
    .projectLogo {
      display: flex;
      justify-content: flex-end;
      img {
        width: 90px;
        height: 90px;
        border-radius: 100%;
        box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
      }
    }
    .projectInfo {
      margin-left: 23px;
      .info1 {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        p {
          font-size: 23px;
          font-weight: 600;
          color: #000;
        }
        span {
          padding: 2px 11px;
          background: #FFF6D8;
          font-size: 12px;
          color: #F0B200;
        }
      }
      .info2 {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 26px;
        word-break: break-all;
        max-width: 800px;
      }
      .projectLink {
        display: flex;
        align-items: center;
        a {
          width: 23px;
          height: 23px;
          margin-right: 10px;
          border-radius: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info3 {
        margin-left: 8px;
        display: flex;
        align-items: center;
        p {
          padding: 4px 18px;
          background: #FFF6D8;
          font-size: 12px;
          color: #F0B200;
          margin-right: 8px;
        }
      }
    }
  }
  .projectDetail_Content {
    width: 1220px;
    margin: 0 auto;
    .detailTabView {
      margin-top: 15px;
      margin-bottom: 44px;
      .el-tabs__nav-wrap::after {
        display: none;
        opacity: 0;
      }
      .el-tabs__item {
        font-size: 15px;
        color: #444444;
      }
      .el-tabs__active-bar {
        width: 30px !important;
        height: 4px;
        background: #F0B200;
        border-radius: 11px;
        margin-left: 15px;
      }
    }
    .baseInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      p {
        font-size: 18px;
        font-weight: 600;
        color: #444444;
      }
      
    }
    .projectIntro {
      width: 100%;
      background: #FFFFFF;
      padding: 30px;
      box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
      border-radius: 11px;
      margin-bottom: 30px;
      position: relative;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 26px;
        word-break: break-all;
      }
      .showMoreBtn {
        padding-left: 10px;
        height: 23px;
        position: absolute;
        bottom: 30px;
        right: 30px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        cursor: pointer;
        p {
          color: #737373;
          font-size: 14px;
          
        }
        img {
          width: 12px;
          height: 7px;
          margin-left: 4px;
          transition: .5s;
        }
      }
    }
    .downloadFile {
      display: flex;
      align-items: center;
      margin-bottom: 45px;
      .fileItem {
        width: 260px;
        height: 68px;
        background: #FAFAFA;
        border-radius: 8px;
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 19px;
        .fileTxt {
          margin-top: 11px;
          .fileName {
            font-size: 14px;
            font-weight: 600;
            color: #4A4A4A;
            line-height: 19px;
          }
          .fileLink {
            font-size: 12px;
            font-weight: 600;
            color: #9C9C9C;
            line-height: 17px;
            cursor: pointer;
            span {
              color: #F0B200;
            }
          }
        }
        img {
          width: 47px;
          height: 41px;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
  