<template>
  <div class="download_h5_page">
    <img src="@/assets/h5Image/logo.png" alt="" class="logo /">
    <div class="btn_view">
      <div class="btn">App store</div>
      <div class="btn">Android</div>
    </div>
    <div class="icon">
      <img src="@/assets/h5Image/downloadicon.png" alt=""  />
      <p class="txt">更懂你的WEB3资讯平台</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "download",
  data() {
    return {
    };
  },
 
};
</script>
<style lang="less">
.download_h5_page {
  width: 100%;
  min-height: 100vh;
  background: url('./../../../assets/h5Image/downloadbg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 58px;
  .logo {
    display: block;
    width: auto;
    height: 29px;
    margin: 0 auto;
    margin-bottom: 58px;
  }
  .btn_view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 73px;
    .btn {
      width: 115px;
      height: 42px;
      background: linear-gradient(to bottom,rgba(182, 143, 8, 1),rgba(221, 161, 38, 1),rgba(241, 189, 40, 1));
      border-radius: 21px;
      line-height: 42px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      &:last-child {
        margin-left: 29px;
      }
    }
  }
  .icon {
    width: 100%;
    position: relative;
    img {
      width: 296px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .txt {
      position: absolute;
      left: 0;
      bottom: 30%;
      font-size: 16px;
      width: 100%;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 23px;
      letter-spacing: 2px;
      text-align: center;
    }
  }
  
}
</style>
  