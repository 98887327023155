<template>
  <div class="notifymsg">
    <img src="@/assets/images/close_yellow.png" alt="" class="closeBtn" @click="closeNotify(index)"/>
    <div class="notifyTitle">
      <img src="@/assets/images/kxicon.png" alt="" />
      <p>{{item.title}}</p>
    </div>
    <div class="notifyContent">
      <span>{{$dayjs(item.publishTime).format('HH:mm')}}</span>
      <p>{{item.excerpt}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "detail",
  props: ['index', 'closeNotify', 'item'],
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  mounted() {
    
  },
 
};
</script>
<style lang="less">
.notifymsg {
  width: 100%;
  background: #FCF0CC;
  position: relative;
  padding: 0.75rem 2.65rem 0.65rem 0.6rem;
  box-sizing: border-box;
  .closeBtn {
    position: absolute;
    top: 0.7rem;
    right: 0.9rem;
    width: 1rem;
    cursor: pointer;
  }
  .notifyTitle {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    img {
      width: 3.3rem;
      height: 1.5rem;
      margin-right: 0.85rem;
      flex-shrink: 0;
    }
    p {
      font-size: 1rem;
      
      font-weight: 600;
      color: #4A4A4A;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .notifyContent {
    display: flex;
    span {
      width: 3.3rem;
      color: #F0B200;
      text-align: center;
      margin-right: 0.85rem;
      flex-shrink: 0;
      line-height: 1.1rem;
    }
    p {
      flex: 1;
      font-size: 0.8rem;
      font-family: SFProText-Medium, SFProText;
      font-weight: 500;
      color: #737373;
      line-height: 1.1rem;
    }
  }
}
</style>
  