<template>
  <div class="columns_h5_page">
    <!-- <van-sticky>
      <van-nav-bar
        title="作者专栏"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </van-sticky> -->
    <van-sticky>
      <topInput />
    </van-sticky>
    <div class="columns_h5_content">
      <div class="columns_h5_top">
        <div class="columns_h5_logo">
          <img src="./../../../assets/h5Image/downloadLogo.png" alt="">
        </div>
        <p class="columns_h5_name">{{ authorInfo.name }}<span>{{ authorInfo.total }}篇文章</span></p>
      </div>
      

      <div v-if="listData.list.length" style="padding: 0 16px 0 14px;">
        <van-list
          v-model="listData.loading"
          :finished="!listData.hasNext"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="deep_list">
            <div class="deep_item" v-for="item in listData.list" :key="item" @click="$router.push('/h5/deepDetail/'+item.id)">
              <listItem :info="item"/>
            </div>
          </div>
        </van-list>
      </div>
      <div class="listNodata" style="height: 200px;" v-else>
        <van-loading color="#f0b200" v-if="listData.loading" :size="34"/>
        <p v-else>暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import listItem from './../deep/component/listItem'
import topInput from './../components/topInput'
import { getAuthor } from '@/api/index';
export default {
  name: "detail",
  components: {
    listItem,
    topInput
  },
  data() {
    return {
      listData: {
        list: [],
        page: 1,
        size: 30,
        loading: true,
        hasNext: false,
      },
      authorInfo: {
        total: 0,
        name: ''
      },
      loadMore: false,
    };
  },
  methods: {
    onLoad() {
      if(!this.loadMore && this.listData.page!=1) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    onRefresh() {
      this.listData.page = 1;
      this.loadMore = false;
      this.$nextTick(()=>{
        this.getListData();
      })
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      let params = {
          page: this.listData.page,
          size: this.listData.size,
          author: this.$route.params.id
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      getAuthor(params)
      .then(res=> {
        const data = res.data;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(data.items);
          this.loadMore = false;
        } else {
          this.$utils.shareWX({title: data.items[0].author.name, intro: '',img: 'https://web3cn.pro/logo.png',url: window.location.href})
          document.title = data.items[0].author.name+'-作者专栏';
          this.listData.list = data.items;
          this.authorInfo = {
            total: data.total,
            name: data.items[0].author.name,
          }
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    
    goDetail(item) {
      const scrollTop = document.documentElement.scrollTop
      sessionStorage.setItem('deeepPosition',scrollTop)
      this.$router.push('/h5/deepDetail/'+item.id)
    },
  },
  mounted() {
    this.getListData();
  },
 
};
</script>
<style lang="less">
.columns_h5_page {
  .van-nav-bar .van-icon {
    color: #444444;
    font-size: 22px;
  }
  .van-nav-bar__title{
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .columns_h5_content {
    .columns_h5_top {
      padding: 30px 0;
      background: url('./../../../assets/images/projectDetail/introducebg.png') no-repeat;
      background-size: 100% 100%;
    }
    .columns_h5_logo {
      margin: 0 auto;
      width: 58px;
      height: 58px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(238,238,238,1);
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .columns_h5_name {
      text-align: center;
      font-size: 17px;
      font-weight: 600;
      color: #444444;
      span {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #9C9C9C;
        padding-left: 10px;
      } 
    }
    
  }
}
</style>
  