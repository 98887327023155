
let data = {

  NFT_Addr: '0x90cBB1fc51076bF443d5c2e4BFFfE33c7D7125F2',

  ETH_Addr: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',

  ETH_Demical: 18,

  chainId: 1, // 测试网 3  主网 1

  apiUrl: 'https://api.web3cn.pro'
  // apiUrl: 'https://api-web3.umidao.org'
}


export default data