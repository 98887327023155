<template>
  <div class="deep_detail_h5">
    <van-sticky>
      <topInput />
      <downloadApp />
    </van-sticky>
    <div class="detail_h5_content" v-if="info.title">
      <p class="detail_h5_content_title">{{ info.title }}</p>
      <div class="detail_h5_content_author" v-if="info.author" @click="$router.push('/h5/author/'+info.author.id)">
        <!-- <img :src="info.author.logo || require('@/assets/images/phoneicon.png')" alt="" /> -->
        <p>{{ info.author.name }}  · {{ $dayjs(info.publishTime).format('YYYY-MM-DD HH:mm') }}</p>
      </div>
      <div class="detail_h5_content_tag">
        <p v-for="(item,index) in info.tags" :key="index">{{ item.name }}</p>
      </div>
      <div class="detail_h5_content_txt" v-html="info.content"></div>
      <!-- pro -->
      <div style="display:flex;flex-wrap: wrap;"  v-if="info.relates && info.relates.length">
        <div class="otherpro" v-for="rel in info.relates" @click="$router.push('/h5/projectDetail/'+rel.id)" :key="rel.id">
          <div class="otherpro_name">
            <img :src="rel.icon" alt="">
            <p>{{ rel.title }}</p>
          </div>
          <p class="otherpro_intro showline2">{{ rel.excerpt }}</p>
        </div>
      </div>

      <p class="bottomTips" v-if="info.id">本文不代表WEB3CN立场，不承担法律责任。文章及观点也不构成投资意见。</p>
      <p class="bottomTips" v-if="info.author">图片来源：{{ info.author.name }} 如有侵权，请联系网站删除。</p>
    </div>
    <div v-else class="nodata">
      <van-loading color="#f0b200" :size="34"/>
    </div>
    <footerView />
    <!-- <div class="detail_h5_btn">
      <div class="detail_h5_btn_item" @click="copy(info)">
        <img src="./../../../assets/h5Image/copy0.png" alt="">
      </div>
      <div class="detail_h5_btn_item" @click="shareModal=true">
        <img src="./../../../assets/h5Image/share0.png" alt="">
      </div>
    </div> -->
    <!-- 分享 -->
    <!-- <van-popup v-model="shareModal"  position="bottom"> 
      <div class="shareModal">
        <p class="shareTitle">分享至</p>
        <div class="shareIconView">
          <a title='twitter' :href="`https://twitter.com/intent/tweet?url=${weburl}&text=${info.title}`"><img src="@/assets/h5Image/home/share1.png" alt=""><p>twitter</p></a>
          <div><img src="@/assets/h5Image/home/share2.png" alt="" @click="shareWx"><p>微信</p></div>
          <div><img src="@/assets/h5Image/home/share3.png" alt="" @click="shareCopy"><p>复制链接</p></div>
          <div><img src="@/assets/h5Image/home/share4.png" alt="" @click="shareSave"><p>保存图片</p></div>
        </div>
        <div class="shareBtn" @click="shareModal=false">取消</div>
      </div>
    </van-popup> -->
    <!-- 分享图片 -->
    <!-- <sharedialog :item="info" ref="imgele" :isDeep="true" v-if="shareModal"/> -->
  </div>
</template>
<script>
// import sharedialog from '../home/component/sharedialog';
import downloadApp from '../components/downloadApp.vue';
import footerView from '../components/footer.vue';
import topInput from './../components/topInput';
import QRCode from 'qrcode';
import { getDetail } from '@/api/index';
export default {
  name: "detail",
  components: {
    downloadApp,
    footerView,
    topInput
  },
  data() {
    return {
      info: {},
      weburl: window.location.href,
      shareModal: false,
    };
  },
  methods: {
    getDetailData(id) {
      getDetail(id).then(res=>{
        this.$utils.shareWX({title: res.data.title, intro: res.data.excerpt.replace(/<\/?p[^>]*>/g, ''),img: 'https://web3cn.pro/logo.png',url: window.location.href})
        document.title = res.data.title;
        this.info = res.data;
      })
    },
    copy() {
      this.$utils.copy(window.location.href,()=>{
        this.$toast.success("复制成功");
      })
    },
    shareWx() {
      const str = window.location.href;
      QRCode.toDataURL(str)
      .then(url => {
        this.$dialog.alert({
          title: '微信扫一扫分享',
          message: `<img src="${url}"/>`,
        })
        this.shareModal = false;
      })
      .catch(err => {
        console.error(err)
      })
      
    },
    shareCopy() {
      this.copy(this.info);
      this.shareModal = false;
    },
    shareSave() {
      this.$refs.imgele.downloadImg();
      this.shareModal = false;
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDetailData(id);
    }
  },
 
};
</script>
<style lang="less">
.deep_detail_h5 {
  .van-nav-bar .van-icon {
    color: #444444;
    font-size: 22px;
  }
  .van-nav-bar__title{
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }

  .detail_h5_content {
    width: 100%;
    min-height: calc(100vh - 78px );
    padding: 15px;
    padding-bottom: 102px;
    .detail_h5_content_title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      line-height: 29px;
      margin-bottom: 14px;
    }
    .detail_h5_content_author {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 5px;
        box-shadow: 0px 2px 8px 0px #eeeeee;
      }
      p {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #9C9C9C;
      }
    }
    .detail_h5_content_tag {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 9px;
      p {
        padding: 2px 9px;
        font-size: 11px;
        font-weight: 500;
        color: #000;
        line-height: 15px;
        margin-right: 10px;
        margin-bottom: 10px;
        background: #FFF6D8;
        border-radius: 21px;
      }
    }
    .detail_h5_content_txt {
      font-size: 15px;
      font-weight: 500;
      color: #444444;
      line-height: 24px;
      word-break: break-all;
      text-align: justify;
      * {
        word-break: break-all;
      }
      a {
        color: #CDCDCD;
      }
      img {
        min-width: 110px;
        display: block;
        max-width: 100%;
        height: auto !important;
        margin: auto;
        object-fit: contain;
        overflow-clip-margin: content-box;
        overflow: clip;
      }
      p {
        margin-bottom: 10px;
        font-size: 1rem!important;
        line-height: 2.08333rem;
        word-break: break-all!important;
        word-wrap: break-word!important;
        text-align: justify!important;
      }
      h1{
        font-size: 1.2rem !important;
        line-height: 2.08333rem;
        margin: 0 0 16px;
      }
    }

    .otherpro {
      width: 100%;
      background: rgba(206,202,190,0.2);
      padding: 13px 10px;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 5px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .otherpro_name {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 10px;
        }
        p {
          font-size: 13px;
          font-weight: 600;
          color: #000;
        }
      }
      .otherpro_intro {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #444444;
        line-height: 26px;
      }
      
    }
    .bottomTips {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: #CCCCCB;
      margin-top: 19px;
    }
  }
  .detail_h5_btn {
    position: fixed;
    bottom: 20%;
    right: 22px;
    width: 47px;
    div {
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 21px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .van-popup {
    border-radius: 14px 14px 0 0;
  }
  .shareModal {
    padding-top: 10px;
    padding-bottom: 21px;
    background: #F8F8F8;
    border-radius: 10px 0 10px 0;
    .shareTitle {
      font-size: 13px;
      font-weight: 500;
      color: #9C9C9C;
      margin-bottom: 20px;
      text-align: center;
    }
    .shareIconView {
      display: flex;
      align-items: center;
      padding: 0 27px;
      margin-bottom: 14px;
      div,a {
        width: 48px;
        margin-right: 29px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        color: #9C9C9C;
        line-height: 16px;
        margin-top: 5px;
        text-align: center;
      }
      img {
        width: 48px;
        height: 48px;
        
      }
    }
    .shareBtn {
      margin: 0 15px;
      height: 46px;
      background: #FFFFFF;
      border-radius: 23px;
      font-size: 17px;
      font-weight: 500;
      color: #444444;
      text-align: center;
      line-height: 46px;
    }
  }
  .van-overlay {
    background: rgba(0,0,0,0.1);
  }
  .nodata {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
  