<template>
  <div class="search_h5_page">
    <van-sticky>
      <div class="search_view">
        <div class="input_view">
          <input v-model="value" type="text" />
          <div class="searchbtn"><img src="./../../../assets/h5Image/search_black.png" alt=""></div>
        </div>
        <div class="search_h5_cacel" @click="searchFn">搜索</div>
      </div>
      <div class="tabView">
        <p class="tabItem" :style="tab==1&&{color: '#000'}" @click="tab=1">本站</p>
        <p class="tabItem" :style="tab==2&&{color: '#000'}" @click="tab=2">Mirror</p>
      </div>
    </van-sticky>
    <own v-show="tab==1" :searchValue="searchValue"/>
    <mirror v-show="tab==2" :searchValue="searchValue"/>
  </div>
</template>
<script>
import own from './own';
import mirror from './mirror'
export default {
  name: "detail",
  components: {
    own,
    mirror
  },
  data() {
    return {
      value: '',
      tab: 1,
      searchValue: '',
    };
  },
  methods: {
    searchFn() {
      if(this.value) {
        this.searchValue = this.value;
      }
    },
  },
  mounted() {
    
  },
  activated() {
    const value = sessionStorage.getItem('searchPosition') || 0;
    setTimeout(() => {
      document.documentElement.scrollTop = value;
    }, 0);
    sessionStorage.removeItem('searchPosition');
  },
};
</script>
<style lang="less">
.search_h5_page {
  // padding-top: 64px;
  .search_view {
    width: 100%;
    height: 64px;
    background: url("./../../../assets/h5Image/tabbarTopBg1.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
    .input_view {
      flex: 1;
      height: 34px;
      background: #fff;
      border-radius: 17px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .searchbtn {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        img {
          width: 20px;
          height: 20px;
          display: block;
          margin: 5px auto;
        }
      }
      input {
        flex: 1;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        color: #444444;
        outline: none;
        border: none;
        background: none;
        caret-color: #F0B200;
      }
    }
    .search_h5_cacel {
      width: 64px;
      height: 34px;
      font-size: 14px;
      color: #fff;
      line-height: 34px;
      margin-left: 15px;
      flex-shrink: 0;
      background: linear-gradient(to right,#1f1d18,#eaae00);
      text-align: center;
      border-radius: 17px;
    }
  }
  .tabView {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    .tabItem {
      font-size: 14px;
      font-size: 14px;
      font-weight: 600;
      color: #9C9C9C;
      line-height: 20px;
      height: 20px;
      &:last-child {
        margin-left: 60px;
      }
    }
  }
}
</style>
  