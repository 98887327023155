<template>
  <div style="overflow:auto;height:100vh" ref="infoDetailScroll" class="">
    <div class="infobgView">
      <img src="@/assets/images/infobg.png" alt="" class="infoBg">
      <div class="infoDetail">
        <div class="infoContent">
          <p class="title">版权申明</p>
          <p class="text" style="margin: 0;">
            1、本站(web3cn.pro)所有内容，凡注明"来源：WEB3CN"的所有文字、图片和音视频资料，版权均属web3cn.pro所有，任何媒体、网站或个人在转载本站内容时必须注明"稿件来源：web3cn.pro"，违者本网将依法追究责任。<br/>
            凡本网注明"来源：XXX（非web3cn.pro） "的文/图等稿件，均为本站转载内容，如涉及作品内容、版权及其它问题，请联系本站！本站转载出于传递更多信息之目的，并不意味着赞同其观点或证实其内容的真实性，且不构成任何投资及应用建议。<br/>
            2、除注明"来源：web3cn.pro"的内容外，本网以下内容亦不可任意转载：<br/>
            (a) 本网所指向的非本网内容的相关链接内容；<br/>
            (b) 已作出不得转载或未经许可不得转载声明的内容；<br/>
            (c) 未由本网署名或本网引用、转载的他人作品等非本网版权内容；<br/>
            (d) 本网中特有的图形、标志、页面风格、编排方式、程序等；<br/>
            (e) 本网中必须具有特别授权或具有注册用户资格方可知晓的内容；<br/>
            (f) 其他法律不允许或本网认为不适合转载的内容。<br/>
            3、转载或引用本网内容必须是以新闻性或资料性公共免费信息为使用目的的合理、善意引用，不得对本网内容原意进行曲解、修改，同时必须保留本网注明的"稿件来源"，并自负版权等法律责任。<br/>
            4、转载或引用本网内容不得进行如下活动：<br/>
            (a) 损害本网或他人利益；<br/>
            (b) 任何违法行为；<br/>
            (c) 任何可能破坏公秩良俗的行为；<br/>
            (d) 擅自同意他人继续转载、引用本网内容；<br/>
            5、转载或引用本网版权所有之内容须注明“转自web3cn.pro”字样，并标明本网网址web3cn.pro<br/>
            (a) 转载或引用本网中的署名文章，请按规定向作者支付稿酬。<br/>
            (b) 对于不当转载或引用本网内容而引起的民事纷争、行政处理或其他损失，本网不承担责任。<br/>
            (c) 本网以“法定许可”方式使用作品，已与知识产权所有者签署合作协议并支付报酬。如有未尽事宜请相关权利人直接与本网媒体合作部联系，联系邮箱：marketing@web3cn.pro<br/>
            (d) 对不遵守本声明或其他违法、恶意使用本网内容者，本网保留追究其法律责任的权利。<br/>
          </p>
        </div>
      </div>
    </div>
    
    <footerView :ele="$refs.infoDetailScroll" :hideTop="true"/>
  </div>
</template>
<script>
import footerView from './../components/footerView'
export default {
  name: "detail",
  components: {
    footerView
  },
  data() {
    return {
      type: 1
    };
  },
  methods: {
   
  },
  mounted() {
    const res = this.$route.params.type;
    this.type = res || 1;
  },
 
};
</script>
<style lang="less">
.infobgView {
  width: 100%;
  position: relative;
  .infoBg {
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    height: calc(100% - 45px);
    z-index: 1;
  }
}
.infoDetail {
  position: relative;
  z-index: 2;
  padding-top: 45px;
  width: 1220px;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  .infoContent {
    width: 732px;
    padding-left: 10px;
    padding-bottom: 56px;
  }
  .title {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .title1 {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    line-height: 25px;
    margin-bottom: 7px;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    color: #737373;
    line-height: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }
  
}
</style>
  