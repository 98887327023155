<template>
  <div style="overflow:auto;height:100vh" ref="fastDetailScroll">
    <div class="fastDetail">
      <div class="fastDetail_left">
        <div class="backBtn" @click="$router.go(-1)">
          <img src="@/assets/images/back.png" alt="" />
          <p>快讯列表</p>
        </div>
        <p class="detailTitle">{{ info.title }}</p>
        <p class="detailTime">{{ $dayjs(info.publishTime).format('YYYY-MM-DD HH:mm')}}</p>
        <!-- other project -->
        <div class="otherProList" v-if="info.relates && info.relates.length">
          <div class="otherPro" v-for="rel in info.relates"  @click="$router.push('/projectDetail/'+rel.id)" :style="info.relates.length > 1 ? {flex: 1} : {}">
            <img :src="rel.icon" alt="" />
            <div class="infoView">
              <div class="otherProTitle">
                <p>{{ rel.title }}</p>
                <span>{{ (rel.tags && rel.tags.length) ? rel.tags[0].name : '' }}</span>
              </div>
              <p class="otherProIntro showline1">{{ rel.excerpt }}</p>
            </div>
          </div>
        </div>

        <div class="detailTag" v-if="info.tags && info.tags.length">
          <p v-for="item in info.tags">{{ item.name }}</p>
        </div>
        <div class="detailTxt" v-html="info.content"></div>
        <!-- 提示文案 -->
        <a class="oldLink" :href="info.url" target="_blank" v-if="info.url">原文链接↗</a>
        <p class="detailTips">本内容只为提供市场信息，不构成投资建议。</p>
      </div>
      <div class="fastDetail_right">
        <div class="stickyView"><hotArticle /></div>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.fastDetailScroll"/>
  </div>
</template>
<script>
import hotArticle from '../components/hotArticle';
import { getDetail } from '../../../api';
import footerView from './../components/footerView'
export default {
  name: "detail",
  components: {
    hotArticle,
    footerView
  },
  data() {
    return {
      info: {
        "id": "",
        "title": "",
        "owner": {},
        "cover": "",
        "icon": "",
        "publishTime": "",
        "excerpt": "",
        "categories": [],
        "tags": [],
        "content": ""
      }
      
    };
  },
  methods: {
    getDetailData(id) {
      getDetail(id).then(res=>{
        document.title = res.data.title;
        this.info = res.data;
        this.$utils.shareWX({title: res.data.title, intro: res.data.excerpt.replace(/<\/?p[^>]*>/g, ''),img: 'https://web3cn.pro/logo.png',url: window.location.href})
      })
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDetailData(id);
    }
  },
 
};
</script>
<style lang="less">
.fastDetail {
  padding-top: 37px;
  display: flex;
  width: 1220px;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  .fastDetail_left {
    flex: 1;
    .backBtn {
      display: flex;
      align-items: center;
      width: 80px;
      cursor: pointer;
      margin-bottom: 45px;
      img {
        width: 10px;
        height: auto;
        margin-right: 8px;
      }
      p {
        font-size: 15px;
        font-weight: 500;
        color: #9C9C9C;
      }
    }
    .detailTitle {
      font-size: 23px;
      font-weight: 600;
      color: #444444;
      line-height: 36px;
      margin-top: 45px;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }
    .detailTime {
      font-size: 4px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #737373;
      line-height: 5px;
      margin-bottom: 20px;
    }
    .detailTag {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 38px;
      p {
        padding: 3px 9px;
        font-size: 12px;
        font-weight: 500;
        color: #737373;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #FFF6D8
      }
    }

    .detailTxt {
      width: 100%;
      font-size: 15px;
      font-weight: 500;
      color: #444444;
      line-height: 38px;
      text-align: justify;
      * {
        word-break: break-all;
      }
      p {
        margin-bottom: 10px;
      }
    }
    .otherProList {
      display: flex;
    }
    .otherPro {
      width: 330px;
      background: rgba(206,202,190,0.2);
      padding: 8px 15px;
      margin-bottom: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 4px;
      margin-right: 30px;
      img {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        margin-right: 15px;
        box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
      }
      .infoView {
        .otherProTitle {
          display: flex;
          align-items: center;
          margin-bottom: 7px;
          p {
            font-size: 14px;
            font-weight: 500;
            color: #4A4A4A;
          }
          span {
            margin-left: 8px;
            font-size: 12px;
            padding: 2px 12px;
            color: #F0B200;
            background: #FFF6D8;
          }
        }
        .otherProIntro {
          font-size: 11px;
          font-weight: 500;
          color: #9C9C9C;
        }
      }
    }
    .oldLink {
      display: block;
      margin-top: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #F0B200;
      cursor: pointer;
    }
    .detailTips {
      margin-top: 15px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9C9C9C;
      margin-bottom: 30px;
    }
    
  }
  .fastDetail_right {
    flex-shrink: 0;
    width: 374px;
    margin-left: 60px;
    .stickyView {
      position: sticky;
      top: 37px;
    }
  }
}
</style>
  