<template>
  <div class="infinite-list-wrapper" style="overflow:auto;height:100vh" ref="orgScroll">
    <div class="orgLib_page" v-infinite-scroll="load" :infinite-scroll-disabled="!listData.hasNext" :infinite-scroll-distance="100">
      <div class="orgLib_title">
        <p>推荐机构</p>
        <img src="@/assets/images/projectLib/hot.png" alt="">
      </div>
      <!-- swiper -->
      <div class="swiper-container hotOrgswiper" v-if="hotList.length">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(it,index) in hotList" :key="index+'hot'">
            <div class="swiper-slide_item"  v-for="(item,index) in it"  @click="goDetail(item,index)">
            <div class="swiperLeft">
              <img :src="item.icon" alt="" />
              <div>
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div class="swiperRight">
              <p>{{item.projectCount}}</p>
              <span>投资项目</span>
            </div>
            </div>
          </div>
        </div>
        <div class="swiperBtn0" @click="slideSwiper(1)" v-if="showPreBtn"></div>
        <div class="swiperBtn" @click="slideSwiper(2)" v-if="(hotList.flat(1)).length > 4 && showNextBtn"></div>
      </div>
      <div v-else class="swiperNodata">
        <van-loading color="#f0b200"  :size="54"/>
      </div>
      <div class="allPrijectTitle">
        <div class="orgLib_title" style="width: 75px">
          <p>全部</p>
        </div>
        <p class="projectNumber">机构数<span>{{ totalNums }}</span></p>
      </div>
      
      <div style="min-height: 500px;">
        <div class="orgList" v-if="listData.list.length">
          <div class="orgItem" v-for="(item,index) in listData.list" :key="index" :style=" (index+1)%4 == 0 && { marginRight: 0 }" @click="goDetail(item)">
            <div class="orgItemInfo">
              <img :src="item.icon" alt="">
              <div>
                <span>{{ item.fundedYear }}</span>
              </div>
              <p>{{ item.projectCount }}</p>
            </div>
            <p class="orgItemName showline1">{{ item.title }}</p>
            <p class="orgItemIntro showline2">{{ item.excerpt }}</p>
          </div>
        </div>
        <div class="loadingView" v-else>
            <van-loading color="#f0b200" v-if="listData.loading" :size="54"/>
            <p v-else>暂无数据</p>
          </div>
        <div class="moreDeepBtn" v-if="listData.hasNext">
          <span>加载更多</span>
          <div class="moreFastMsg_loading" v-if="loadMore">
            <van-loading color="#9c9c9c" size="44px"/>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.orgScroll"/>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { getOrgList } from '@/api/index'
import footerView from './../components/footerView'
export default {
  name: "topic",
  components: {
    footerView
  },
  data() {
    return {
      hotList: [],
      listData: {
        list: [],
        page: 1,
        size: 20,
        loading: true,
        hasNext: false,
      },
      loadMore: false,
      swiperOrgObj: null,
      totalNums: 0,
      showPreBtn: false,
      showNextBtn: true,
    };
  },
  mounted() {
    this.getHot();
    this.getList();
  },
  methods: {
    initSwiper() {
      const self = this;
      this.swiperOrgObj = new Swiper('.hotOrgswiper', {
        loop: false,
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 21,
        on:{
          slideChangeTransitionEnd: function(){
            if(this.activeIndex == 0) {
              self.showPreBtn = false;
            }
            if(this.activeIndex > 0) {
              self.showPreBtn = true;
            }
            if(this.activeIndex < self.hotList.length-1) {
              self.showNextBtn = true;
            }
            if(this.activeIndex == self.hotList.length-1) {
              self.showNextBtn = false;
            }
          },
        },
      })
    },
    slideSwiper(type) {
      if(type==1) {
        this.swiperOrgObj.slidePrev();
      } else {
        this.swiperOrgObj.slideNext();
      }
    },
    getHot() {
      getOrgList({pinned: true})
      .then(res=>{
        if(res.data && res.data.items) {
          this.hotList = this.$utils.chunk(res.data.items,4);
          this.$nextTick(()=>{
            this.initSwiper();
          })
        }
      })
    },
    getList(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      getOrgList({
          page: this.listData.page,
          size: this.listData.size,
          // category: this.tabIndex
      })
      .then(res=> {
        const data = res.data;
        const result = data.items;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(result);
          this.loadMore = false;
        } else {
          this.listData.list = result;
        }
        this.totalNums = res.data.totalPages * this.listData.size;
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    load() {
      if(!this.loadMore) {
        this.loadMore = true;
        setTimeout(() => {
          this.getList(true)
        }, 500);
      }
    },
    goDetail(item) {
      // this.$router.push('/organizationDetail/'+item.id)
      window.open(window.location.origin+'/organizationDetail/'+item.id)
    }
  },
 
 
};
</script>
<style lang="less">
.orgLib_page {
  padding-top: 30px;
  width: 1220px;
  margin: 0 auto;
  .allPrijectTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .projectNumber {
      font-size: 15px;
      font-weight: 600;
      color: #4A4A4A;
      span {
        padding-left: 5px;
        color: #F0B200;
      }
    }
  }
  .orgLib_title {
    width: 117px;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    background: url('../../../assets/images/projectLib/projectlibTitleicon.png') no-repeat left bottom / 100% 15px;
    margin-bottom: 15px;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
    }
    img {
      width: 15px;
      height: 19px;
      margin-left: 4px;
    }
  }
  .swiperNodata {
    width: 100%;
    height: 113px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #999;
    }
  }
  .hotOrgswiper {
    width: 100%;
    height: 113px;
    margin-bottom: 30px;
    .swiper-slide {
      width: 100%;
      display: flex;
    }
    .swiper-slide_item {
      width: calc(25% - 15.75px);
      background: url('./../../../assets/images/projectLib/swiperBg1.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 15px;
      box-sizing: border-box;
      margin-right: 21px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        .swiperLeft img {
          width: 65px;
          height: 65px;
        }
      }
      .swiperLeft {
        flex: 1;
        display: flex;
        align-items: center;
        
        img {
          width: 54px;
          height: 54px;
          margin-right: 8px;
          border-radius: 100%;
          box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
          transition: .3s;
        }
        p {
          font-size: 16px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 21px;
        }
        span {
          font-size: 12px;
          color: #FFFFFF;
        }
      }
      .swiperRight {
        height: 100%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 17px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 23px;
        }
        span {
          font-size: 12px;
          color: #FFFFFF;
        }
      }
    }
    .swiperBtn0 {
      position: absolute;
      left: 15px;
      top: 50%;
      margin-top: -17px;
      width: 34px;
      height: 34px;
      background: url('../../../assets/images/projectLib/slideSwiper.png') no-repeat center center / 100% 100%;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
      transform: rotate(180deg);
    }
    .swiperBtn {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -17px;
      width: 34px;
      height: 34px;
      background: url('../../../assets/images/projectLib/slideSwiper.png') no-repeat center center / 100% 100%;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
    }
  }
  // list
  .orgList {
    display: flex;
    flex-wrap: wrap;
    .orgItem {
      flex: 1;
      min-width: 281px;
      height: 167px;
      background: rgba(255,255,255,0.2);
      box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
      border-radius: 11px;
      padding: 23px 15px 15px 15px;
      margin-right: 22px;
      margin-bottom: 23px;
      cursor: pointer;
      &:hover {
        background: linear-gradient(270deg,#fff,#FFE8A6);
        box-shadow: 0 9px 23px -1px rgba(35,49,65,.16);
      }
      .orgItemInfo {
        display: flex;
        align-items: center;
        img {
          width: 54px;
          height: 54px;
          margin-right: 7px;
          border-radius: 100%;
          box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
        }
        span {
          padding: 4px 15px;
          background: #FFF6D8;
          font-size: 11px;
          font-weight: 600;
          color: #000;
        }
        p {
          flex: 1;
          text-align: right;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
      }
      .orgItemName {
        font-size: 17px;
        font-weight: 600;
        color: #000;
        line-height: 20px;
        margin-top: 11px;
        margin-bottom: 10px;
      }
      .orgItemIntro {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        line-height: 19px;
      }
    }
  }
  .loadingView {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
  .moreDeepBtn {
    margin: 30px 38px;
    height: 49px;
    line-height: 49px;
    background: #f3f2f0;
    border-radius: 11px;
    font-size: 15px;
    font-weight: 600;
    color: #9c9c9c;
    text-align: center;
    cursor: pointer;
    position: relative;
    &:hover {
      background: #FFF6E6;
    }
    .moreFastMsg_loading {
      width: 15px;
      height: 100%;
      position: absolute;
      left: 58%;
      top: 0;
    }
  }
}
</style>
  