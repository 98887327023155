<template>
  <div style="overflow:auto;height:100vh" ref="infoDetailScroll" class="">
    <div class="infobgView">
      <img src="@/assets/images/infobg.png" alt="" class="infoBg">
      <div class="infoDetail">
        <div class="infoContent">
          <p class="title">工作机会</p>
          <p class="title1">公司介绍</p>
          <p class="text">WEB3CN是一家很酷的创业公司，立足于全球视野为用户提供最专业、全面、及时的NFT信息资讯服务。站在工业文明与数字文明的交汇口，我们看到无数激动人心的可能和机遇诞生。加入我们，web3cn.pro将带领你进入一个全新的世界和领域！</p>
          <p class="title1">经验与背景需求</p>
          <p class="text">
            我们正寻找的同事与伙伴，希望你具备…<br/><br/>
            ·对世界充满好奇心<br/>
            ·思维开阔，乐于接受新鲜事物<br/>
            ·拥有国际视野，熟练使用英语更佳<br/>
            ·乐于表达，文笔优秀<br/>
            ·审美在线，喜欢二次元或动漫更佳<br/>
            ·有早期创业公司，或者风险投资、金融科技领域的从业经验更佳<br/>
            ·对区块链以及加密行业充满热情<br/>
            ·勇于担当，责任心超强<br/><br/>
            我们的团队重视并乐于看到更多元化的背景，无论民族、宗教、年龄、性别、性取向、婚姻状况等等。我们乐观，并始终保持开放的态度！
          </p>
          <p class="title1">在招的职位描述</p>
          <p class="text">
            [内容编辑/ 研究分析师]<br/><br/>

            ·对区块链以及NFT有一定基础认知<br/>
            ·对区块链投资有一定经验和认知<br/>
            ·逻辑思考能力优秀,拥有独立思考和分析的能力<br/>
            ·沟通能力优秀,时间观念强,做事高效<br/>
            ·文笔能力优秀,写过与区块链或NFT相关的文稿更佳<br/>
            ·曾在投行撰写过投研报告更佳<br/>
            [编辑 / 翻译（可兼职）]<br/><br/>

            ·对区块链以及NFT有一定基础认知<br/>
            ·外文阅读和翻译能力优秀(主要以英语为主,韩语,日语为辅)<br/>
            ·文笔能力优秀<br/>
            [设计]<br/><br/>

            ·对区块链以及NFT有一定基础认知<br/>
            ·审美在线,对科幻,动漫,游戏感兴趣最佳<br/>
            ·工作效率高效
          </p>
          <p class="title1">工作地点</p>
          <p class="text">杭州 未来科技城 欧美金融城（EFC）</p>
        </div>
      </div>
    </div>
    
    <footerView :ele="$refs.infoDetailScroll" :hideTop="true"/>
  </div>
</template>
<script>
import footerView from './../components/footerView'
export default {
  name: "detail",
  components: {
    footerView
  },
  data() {
    return {
      type: 1
    };
  },
  methods: {
   
  },
  mounted() {
    
  },
 
};
</script>
<style lang="less">
.infobgView {
  width: 100%;
  position: relative;
  .infoBg {
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    height: calc(100% - 45px);
    z-index: 1;
  }
}
.infoDetail {
  position: relative;
  z-index: 2;
  padding-top: 45px;
  width: 1220px;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  .infoContent {
    width: 732px;
    padding-left: 10px;
    padding-bottom: 56px;
  }
  .title {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .title1 {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    line-height: 25px;
    margin-bottom: 7px;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    color: #737373;
    line-height: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }
  
}
</style>
  