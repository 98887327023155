<template>
  <div class="orgDetail_h5_page">
    <!-- <van-sticky>
      <van-nav-bar
        title="机构详情"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </van-sticky> -->
    <van-sticky>
      <topInput />
    </van-sticky>
    <div class="detailIntroduce">
      <div class="projectLogo">
        <img :src="info.icon" alt="">
      </div>
      <div class="projectInfo">
        <div class="info1">
          <p>{{ info.title }}</p>
          <span>{{ info.fundedYear }}</span>
        </div>
        <p class="info2">{{ info.excerpt }}</p>
        <div class="info3">
          <a :href="info.contact.medium" target="_blank"><img src="@/assets/images/orgDetail/web.png" alt=""></a>
          <a :href="info.contact.twitter" target="_blank"><img src="@/assets/images/orgDetail/twitter.png" alt=""></a>
          <a :href="info.contact && info.contact.medium" target="_blank" v-if="info.contact && info.contact.medium" ><img src="@/assets/images/detailmedium.png" alt=""></a>
        </div>
      </div>
    </div>
    <div class="projectDetail_Content">
      <p class="orgNumber">投资回报率</p>
      <div class="returnPrecent">
        <!-- <div class="returnPrecentSwiper"> -->
        <div class="returnItem" v-for="(item,index) in info.rois" :key="index+'return'">
          <img :src="item.logo" alt="" @click="$router.push(`/h5/projectDetail/project_${item.name.replace(/[^\w]|_/g, '').toLowerCase()}`)"/>
          <div>
            <span class="bgpercent" :style="{width: ((80-index*2)/100)*170 + 'px'}"></span>
            <span class="itemBorder"></span>
          </div>
          <p>{{ item.roi }}倍</p>
        </div>
      </div>
      <p class="orgNumber">投资组合<span>{{ info.projects.length || '' }}</span></p>
      <div class="orgTable">
        <div class="tableView">
          <div class="tableTitle">
            <p class="tableTd1">项目名称</p>
            <p class="tableTd4">回报率/价格</p>
          </div>
          <div v-if="info.projects.length">
            <div class="tableItem" v-for="(item,index) in info.projects" :key="index" @click="$router.push('/h5/projectDetail/'+item.id)">
              <div class="tableTd1 itemTd1">
                <img :src="item.logo" alt="">
                <div>
                  <p>{{ item.name }}</p>
                  <span v-for="(it,index) in item.tags" :key="index+'tag'">{{ it }}</span>
                </div>
              </div>
              <!-- <div class="tableTd2 itemTd2">
                <p v-for="(it,index) in item.tags" :key="index+'tag'">{{ it }}</p>
              </div> -->
              <div class="tableTd2 itemTd2">
                <p>{{ item.privatePrice }}</p>
                <p>{{ item.privateRoi }}倍</p>
              </div>
            </div>
          </div>
          <div class="loadingView" v-else>
            <van-loading color="#f0b200" v-if="loading" :size="34"/>
            <p v-else>暂无数据</p>
          </div>

        </div>
      </div>
    </div>
    <div style="height: 30px"></div>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import topInput from './../components/topInput';
import Swiper from "swiper"
import { getOrgDetail } from '@/api/index';
export default {
  name: "detail",
  components: {
    topInput
  },
  data() {
    return {
      loading: true,
      info: {
        contact: {},
        rois: [], // 回报率
        projects: [],
      }
     
    };
  },
 
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getInfo(id);
    }
  },
  methods: {
    initSwiper() {
      
    },
    getInfo(id) {
      getOrgDetail(id).then(res=>{
        this.$utils.shareWX({title: res.data.title+'-'+res.data.excerpt.replace(/<\/?p[^>]*>/g, ''), intro: '',img: res.data.icon,url: window.location.href});
        this.info = res.data;
        this.loading = false;
        document.title = res.data.title+'-'+res.data.excerpt.replace(/<\/?p[^>]*>/g, '');
        this.$nextTick(()=>{
          this.initSwiper();
        })
      })
    }
  },
  
 
};
</script>
<style lang="less">
.orgDetail_h5_page {
  .van-nav-bar .van-icon {
    color: #444444;
    font-size: 22px;
  }
  .van-nav-bar__title{
    font-size: 17px;
    font-weight: 500;
    color: #444444;
  }
  .detailIntroduce {
    width: 100%;
    padding: 18px;
    background: url('./../../../assets/images/orgDetail/detailtopbg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .projectLogo {
      width: 55px;
      height: 55px;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 14px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .projectInfo {
      .info1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        p {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          margin-right: 5px;
        }
        span {
          padding: 2px 5px;
          font-size: 11px;
          background: #FFF6D8;
          color: #000;
        }
      }
      .info2 {
        font-size: 12px;
        // font-weight: 600;
        color: #9C9C9C;
        line-height: 17px;
        margin-bottom: 12px;
        text-align: justify;
      }
      .info3 {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          width: 25px;
          height: 25px;
          margin-right: 14px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .projectDetail_Content {
    padding: 0 15px;
    margin: 0 auto;
    .orgNumber {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 20px;
      span {
        padding-left: 5px;
        color: #CCCCCB
      }
    }
    .returnPrecent {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 20px 0px #EEEEEE;
      border-radius: 10px;
      padding: 19px;
      margin-bottom: 30px;
      .returnItem {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        p {
          width: 60px;
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          text-align: right;
          flex-shrink: 0;
        }
        div {
          flex-shrink: 0;
          width: 170px;
          height: 18px;
          border-radius: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .bgpercent {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 18px;
            background: linear-gradient(to left, #FFD14D 0%, #FFFFFF 100%);
            border-radius: 11px;
          }
          .itemBorder {
            width: 100%;
            height: auto;
            border-bottom: 1px dashed #ADB0C2;
          }
        }
        img {
          display: block;
          margin: 0 auto;
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }
    }
  
    .orgTable {
      width: 100%;
      min-height: calc(100vh - 316px);
      .tableView {
      width: 100%;
      min-height: 700px;
      border-radius: 11px;
      border: 1px solid #EDEDED;
      margin-bottom: 30px;
      .tableTitle {
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #737373;
        }
      }
      .tableItem {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 17px 14px;
        border-top: 1px solid #EDEDED;
        &:hover {
          background: rgba(240,178,0,0.1);
          .itemTd7 .itemDetail {
            display: flex;
            
          }
        }
        .itemTd1 {
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
            border-radius: 100%;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            color: #000;
            line-height: 20px;
            margin-bottom: 5px;
          }
          span {
            padding: 2px 5px;
            background: #FFF6D8;
            font-size: 10px;
            color: #000;
            margin-right: 5px;
          }
        }
        .itemTd2 {
          text-align: right;
          p:frist-child {
            font-size: 14px;
            font-weight: 600;
            color: #444444;
            line-height: 20px;
          }
          p:last-child {
            margin-top: 5px;
            font-size: 10px;
            font-weight: 400;
            color: #737373;
            line-height: 14px;
          }
        }
      }
      .tableTd1 {
        flex: 1;
        margin-right: 8px;
      }
      .tableTd2 {
        width: 100px;
      }
    }
    .moreDeepBtn {
      margin-left: 50px;
      margin-right: 26px;
      height: 49px;
      line-height: 49px;
      text-align: center;
      background: #F3F2F0;
      border-radius: 11px;
      font-size: 15px;
      font-weight: 600;
      color: #9C9C9C;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
    .loadingView {
      width: 100%;
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
    }
  }
}
</style>
  