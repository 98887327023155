<template>
  <div class="articleItem_search">
    <div class="hoverbg"></div>
    <div class="article_com" @click="$emit('goDetail')">
      <div class="left">
        <div class="title">
          <img src="@/assets/images/kxicon.png" alt="" v-if="info.kx" class="titleIcon">
          <div class="titleTxt" v-if="search" v-html="info.title"></div>
          <p class="titleTxt" v-else>{{ info.title }}</p>
        </div>
        <p class="article_com_content" v-if="search" v-html="info.excerpt"></p>
        <p class="article_com_content" v-else>{{ info.excerpt }}</p>
      </div>
      <img :src="info.cover" alt="" class="itemImg" v-if="info.cover" />
    </div>
    <div :class="otherFlex && 'otherInfo'">
      <div class="author" v-if="info.author && info.author.id" @click.stop="goAuthor(info)">
        <!-- <img :src="info.author.logo || require('@/assets/images/phoneicon.png')" alt=""/> -->
        <p class="name">{{ info.author.name }} · {{ $dayjs(new Date(info.publishTime)).fromNow() }}</p>
      </div>
      <div class="tagView" v-if="info.tags">
        <p class="tagItem" v-for="(item, index) in info.tags" :key="index">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "article",
  props: ['kx', 'info', 'otherFlex', 'search', 'noGoAuthor'],
  data() {
    return {

    }
  },
  methods: {
    goAuthor(item) {
      if(this.noGoAuthor) {
        return 
      }
      this.$router.push('/author/'+item.author.id)
    },
  },
  mounted() {

  },

};
</script>
<style lang="less">
.articleItem_search {
  position: relative;
  cursor: pointer;
  .hoverbg {
    position: absolute;
    left: -10px;
    top: 0;
    width: calc(100% + 20px);
    height: 100%;
    display: none;
    border-radius: 8px;
  }
  &:hover {
    .hoverbg {
      display: block;
      box-shadow: 0 -12px 20px -6px rgba(140,150,162,.2);
      border-radius: 6px;
      z-index: 2;
    }
    .titleTxt {
      color: #F0B200;
    }
  }
  .article_com {
    display: flex;
    padding-top: 27px;
    cursor: pointer;

    .left {
      flex: 1;

      .title {
        display: flex;
        cursor: pointer;

        .titleIcon {
          width: 50px;
          height: 23px;
          margin-right: 8px;
        }

        .titleTxt {
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }

      .article_com_content {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 24px;
        margin-bottom: 23px;
        margin-top: 20px;
        text-align: justify;
      }

    }

    .itemImg {
      width: 120px;
      height: 79px;
      border-radius: 11px;
      flex-shrink: 0;
      margin-left: 21px;
    }
  }

  .otherInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .author {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    img {
        width: 25px;
        height: 25px;
        border-radius: 25px;
        margin-right: 5px;
        border-radius: 100%;
        box-shadow: 0px 2px 8px 0px #eeeeee;
      }
    .name {
      font-size: 12px;
      font-weight: 500;
      color: #737373;
    }
  }

  .tagView {
    display: flex;
    flex-wrap: wrap;
    p {
      padding: 3px 14px;
      background: #FFF6D8;
      border-radius: 17px;
      font-size: 12px;
      font-weight: 500;
      color: #737373;
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }
}</style>
