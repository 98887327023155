<template>
  <div style="overflow:auto;height:100vh" ref="searchIndexScroll" class="">
    <div class="searchIndex">
      <div class="closeView">
        <img src="@/assets/images/close_gray.png" alt="" @click="$router.go(-1)"/>
      </div>
      <div class="inputView">
        <input v-model="searchValue" placeholder="请输入关键词" @keydown.enter="goSearch()"/>
      </div>
      <p class="hotTitle">热门搜索</p>
      <div class="hotView">
        <p v-for="item in list" :key="item.id" @click="goSearch(item.title)">{{ item.title }}</p>
      </div>
    </div>
    <footerView :ele="$refs.searchIndexScroll" :hideTop="true"/>
  </div>
</template>
<script>
import footerView from './../components/footerView'
import { getProjectList } from '@/api/index'
export default {
  name: "detail",
  components: {
    footerView
  },
  data() {
    return {
      list: [],
      searchValue: ''
    };
  },
  methods: {
    goSearch(title) {
      if(title) {
        this.$router.push('/result/'+title)
        return 
      }
      if(this.searchValue) {
        this.$router.push('/result/'+this.searchValue)
      }
    },
  },
  mounted() {
    getProjectList({pinned: true,sort:'SORT_VALUE',sortOrder:false,size:5})
    .then(res=>{
      if(res.data) {
        this.list = res.data.items;
      }
    })
  },
 
};
</script>
<style lang="less">
.searchIndex {
  position: relative;
  padding-top: 68px;
  width: 770px;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  .closeView {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 46px;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .inputView {
    width: 770px;
    height: 66px;
    background: #FFFFFF;
    border-radius: 33px;
    border: 2px solid #F0B200;
    display: flex;
    padding: 22px 30px;
    align-items: center;
    margin-bottom: 30px;
    input {
      border: none;
      outline: none;
      font-size: 15px;
      line-height: 24px;
      color: #4a4a4a;
      caret-color: #F0B200;
    }
    input::-webkit-input-placeholder {
      color: #9C9C9C;
      font-size: 15px;
      line-height: 24px;
    }
  }
  .hotTitle {
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 21px;
  }
  .hotView {
    display: flex;
    flex-wrap: wrap;
    p {
      min-width: 90px;
      padding: 8px 20px;
      background: #FFF6D8;
      border-radius: 8px;
      margin-right: 15px;
      margin-top: 19px;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
  