<template>
  <div class="deep_listItem_h5_home" :style="hideBottomBorder&&{border:'none'}">
    <p class="listItem_h5_content_title">{{info.title}}</p>
    <div class="listItem_h5_content">
        <p class="listItem_h5_content_txt">{{ info.excerpt }}</p>
        <img :src="info.cover" v-if="info.cover" alt=""  class="listItem_h5_content_img"/>
    </div>
    <div class="listItem_h5_tag">
      <p v-for="(item, index) in info.tags" :key="index">{{ item.name }}</p>
    </div>
    <div v-if="info.author && info.author.id" @click.stop="goAuthor(info)" class="listItem_h5_author">
      <!-- <img :src="info.author.logo || require('@/assets/images/phoneicon.png')" alt="" /> -->
      <p>{{info.author.name }}·{{ $dayjs(new Date(info.publishTime)).fromNow() }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "article",
  props: {
    info: {
      default: {},
      type: Object
    },
    hideBottomBorder: {
      default: false,
      type: Boolean
    },
    canGoAuthor: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      kx: require('./../../../../assets/h5Image/homekxicon.png'),
      sd: require('./../../../../assets/h5Image/homesdicon.png'),
    }
  },
  methods: {
    goAuthor(info) {
      if(this.canGoAuthor) {
        this.$router.push('/h5/author/'+info.author.id)
      }
    }
  },
  mounted() {
    
  },
  
};
</script>
<style lang="less">
.deep_listItem_h5_home {
  padding-top: 16px;
  padding-bottom: 13px;
  width: 100%;
  border-bottom: 1px solid rgba(206,202,190,0.2);
  .listItem_h5_content_title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 30px;
    margin-bottom: 7px;
  }
  .listItem_h5_content {
    display: flex;
    margin-bottom: 10px;
    .listItem_h5_content_txt {
      flex: 1;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    .listItem_h5_content_img {
      flex-shrink: 0;
      border-radius: 10px;
      width: 114px;
      height: 74px;
      margin-left: 10px;
    }
  }
  .listItem_h5_tag {
    display: flex;
    flex-wrap: wrap;
    p {
      padding: 2px 9px;
      background: #FFF6D8;
      font-size: 11px;
      font-weight: 500;
      color: #737373;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .listItem_h5_author {
    font-size: 12px;
    font-family: SFProText-Medium, SFProText;
    font-weight: 500;
    color: #CCCCCB;
    line-height: 13px;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 5px;
      box-shadow: 0px 2px 8px 0px #eeeeee;
    }
  }

}
</style>
