<template>
 <div class="border_view" :style="{alignItems: direction=='right'?'flex-end':'flex-start'}">
    <div></div>
    <!-- <p></p> -->
  </div>
</template>

<script>
export default {
  name: "border",
  props: [ 'direction' ],
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
  
};
</script>
<style lang="less">
.border_view {
  display: flex;
  flex-direction: column;
  div {
    width: 100%;
    height: 1px;
    background: #CECABE;
  }
  p {
    width: 8px;
    height: 7px;
    background: #CECABE;
  }
}
</style>
