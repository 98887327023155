<template>
  <div class="projectDynamic">
    <div class="timePicker" v-if="dateList.length">
      <el-select v-model="dateValue" placeholder="" @change="changeYear">
        <el-option
          v-for="item in dateList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    
    <div class="timelineView" v-if="listData.list.length">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in listData.list"
          :key="index"
        >
        <div class="timelineItem">
          <div class="itemDate" v-if="showDate(item,index)">
            <p v-if="showMonth(item,index)">{{ $dayjs(item.publishTime).format('MM') }}月</p>
            <p style="font-size: 26px;color:#000">{{ $dayjs(item.publishTime).format('DD') }}日</p>
          </div>
          <p class="itemHour">{{ $dayjs(item.publishTime).format('hh:mm') }}</p>
          <div class="itemContent" @click="goDetail(item)">
            <div class="itemContentTxt">
              <div class="itemTitle">
                
                <img src="../../../../assets/images/projectDetail/gf.png" alt="" v-if="item.official" />
                <img src="../../../../assets/images/kxicon.png" alt="" v-else-if="item._type=='flash'" />
                <img src="../../../../assets/images/projectDetail/yb.png" alt="" v-else-if="item.report" />
                <p>{{ item.title }}</p>
              </div>
              <p class="itemTxt" v-html="item.excerpt"></p>
            </div>
            <img :src="item.cover" alt="" class="itemContentLogo" v-if="item.cover"/>
          </div>
        </div>
      </el-timeline-item>
      </el-timeline>
      <div class="moreDeepBtn" @click="loadMoreList" v-if="listData.isEnd">
        <span>加载更多</span>
        <div class="moreFastMsg_loading" v-if="loadMore">
          <van-loading color="#9c9c9c" size="44px"/>
        </div>
      </div>
    </div>
    <div class="loadingView" v-else>
      <van-loading color="#f0b200" v-if="listData.loading" :size="54"/>
      <p v-else>暂无数据</p>
    </div>
  </div>
</template>
<script>
import { getProjectDetail, getProjectDynamic } from '@/api/index';
export default {
  name: "projectDynamic",
  data() {
    return {
      dateValue: '',
      dateList: [],
      loadMore: false,
      listData: {
        list: [],
        page: 1,
        size: 10,
        loading: true,
        isEnd: false,
      },
    };
  },
  methods: {
    showDate(item,index) {
      let type = false;
      if(index == 0) {
        type = true;
      } else {
        if(this.$dayjs(item.publishTime).format('DD') != this.$dayjs(this.listData.list[index-1].publishTime).format('DD')) {
          type = true
        }
      }
      return type
    },
    showMonth(item,index) {
      let type = false;
      if(index == 0) {
        type = true;
      } else {
        if(this.$dayjs(item.publishTime).format('YYYY/MM') != this.$dayjs(this.listData.list[index-1].publishTime).format('YYYY/MM')) {
          type = true
        }
      }
      return type
    },
    loadMoreList() {
      if(!this.loadMore) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      getProjectDynamic({
        relate: this.$route.params.id,
        year: this.dateValue,
        page: this.listData.page,
        size: this.listData.size,
        sort: 'PUBLISH_TIME',
        sortOrder: false
      })
      .then(res=> {
        const data = res.data;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(data.items);
          this.loadMore = false;
        } else {
          this.listData.list = data.items;
        }
        this.listData.page = this.listData.page+1;
        this.listData.isEnd = data.hasNext;
        this.listData.loading = false;
      })
    },
    getDate(id) {
      getProjectDetail(id+'/post_years')
      .then(res=>{
        if(res.data) {
          this.dateList = res.data.map(item=>({value: item,label:item+'年'}));
          this.dateValue = res.data[0];
          if(!res.data.length) {
            this.listData.loading = false;
            return 
          }
          this.$nextTick(()=>{
            this.getListData()
          })
        }
      })
    },
    goDetail(item) {
      if(item._type == 'article') {
        this.$router.push('/deepDetail/'+item.id)
      }
      if(item._type == 'flash') {
        this.$router.push('/fastDetail/'+item.id)
      }
    },
    changeYear(val) {
      this.listData = {
        list: [],
        page: 1,
        size: 10,
        loading: true,
        isEnd: false,
      };
      this.getListData();
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDate(id);
    }
  },
 
};
</script>
<style lang="less">
  .projectDynamic {
    .timePicker {
      width: 130px;
      font-size: 30px;
      font-weight: bold;
      color: #737373;
      line-height: 36px;;
      margin-bottom: 30px;
      padding-right: 26px;
      background: url('./../../../../assets/images/bottom.png') no-repeat right center / 16px 11px;
      position: relative;
      cursor: pointer;
      .el-input__inner {
        border: none;
      }
      .el-input__suffix {
        display: none;
      }
      .el-input__inner {
        padding: 0;
        text-align: right;
        font-size: 30px;
        color: #737373;
      }
    }
    .timelineView {
      width: 100%;
      padding-left: 91px;
      box-sizing: border-box;
      margin-bottom: 150px;
      li {
        list-style: none;
      }
      .timelineItem {
        width: 100%;
        min-height: 153px;
        padding: 15px 60px 28px 30px;
        border-radius: 10px;
        background: rgba(206, 202, 190, 0.1);
        border-radius: 23px;
        cursor: pointer;
        position: relative;
        .itemDate {
          position: absolute;
          top: 0;
          left: -91px;
          font-size: 21px;
          font-weight: 400;
          color: #737373;
          padding-right: 14px;
          text-align: right;
          line-height: 30px;
        }
        .itemHour {
          font-size: 12px;
          font-weight: 500;
          color: #737373;
          margin-bottom: 10px;
        }
        .itemContent {
          display: flex;
          .itemContentLogo {
            flex-shrink: 0;
            width: 120px;
            height: 79px;
            border-radius: 11px;
            margin-left: 45px;
          }
          .itemContentTxt {
            flex: 1;
            .itemTitle {
              display: flex;
              margin-bottom: 15px;
              img {
                width: auto;
                height: 23px;
                margin-right: 8px;
                margin-top: 2px;
              }
              p {
                font-size: 19px;
                font-weight: 600;
                color: #000;
                line-height: 27px;
              }
            }
            .itemTxt {
              font-size: 14px;
              font-family: SFProText-Medium, SFProText;
              font-weight: 500;
              color: #444444;
              line-height: 24px;
              text-align: justify;
            }
          }
        }
        &:hover {
          .itemContentTxt {
            .itemTitle p {
              color: #F0B200;
            }
          }
        }
      }
      // ele css
      .el-timeline-item {
        padding-bottom: 15px;
      }
      .el-timeline-item__node {
        background: #fff;
        border: 2px solid #F0B200;
      }
      .el-timeline-item__tail {
        border-left: 2px solid rgba(206,202,190,0.2);
      }
    }
    .loadingView {
      width: 100%;
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
    .moreDeepBtn {
      height: 49px;
      line-height: 49px;
      background: #f3f2f0;
      border-radius: 11px;
      font-size: 15px;
      font-weight: 600;
      color: #9c9c9c;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
  }
</style>
  