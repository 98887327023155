import axios from 'axios';
import api from './../assets/js/API'
import qs from 'qs'
// 获取 tab标签
export function getCategories(url) {
  console.log(api.apiUrl + '/categories/'+url)
  return axios.get(api.apiUrl + '/categories/'+url)
}
// 列表 type: flash快讯  article深度
export function getList(url,params) {
  return axios.get(api.apiUrl +'/posts/'+ url,{
    params
  })
}

// 详情
export function getDetail(url) {
  return axios.get(api.apiUrl + '/post/'+url)
}

// 获取导航
export function getNavigation() {
  return axios.get(api.apiUrl + '/navigation_category/')
}

// 项目列表
export function getProjectList(params) {
  return axios.get(api.apiUrl +'/projects/',{
    params
  })
}

// 项目详情
export function getProjectDetail(url,params) {
  return axios.get(api.apiUrl +'/project/'+url,{
    params
  })
}

// 项目动态
export function getProjectDynamic(params) {
  return axios.get(api.apiUrl +'/posts',{
    params
  })
}

//机构列表 
export function getOrgList(params) {
  return axios.get(api.apiUrl +'/institutions/',{
    params
  })
}

//机构详情
export function getOrgDetail(url,params) {
  return axios.get(api.apiUrl +'/institution/'+url,{
    params
  })
}

// 搜索项目机构
export function searchResult1(params) {
  return axios.get(api.apiUrl +'/posts/(project,institution)',{
    params
  })
}
// 搜索 文章 快讯
export function searchResult2(params) {
  return axios.get(api.apiUrl +'/posts/(article,flash)',{
    params
  })
}

// mirror
export function searchMirror(params) {
  return axios.get(`https://api.mirrorbeats.xyz/mirror/Mirror/search?page=${params.page}&keyword=${params.keyword}&sort=Time`)
}

// 作者
export function getAuthor(params) {
  return axios.get(api.apiUrl +'/posts',{
    params
  })
}

// 获取微信的config
export function getWXconfig(params) {
  return axios.post(api.apiUrl +'/wx/sign',params)
}