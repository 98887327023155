<template>
  <div class="infinite-list-wrapper" style="overflow:auto;height:100vh" ref="searchScroll">
    <div class="search_page"  v-infinite-scroll="load" :infinite-scroll-disabled="!hasNext" :infinite-scroll-distance="100">
      <div class="search_page_left">
        <own @changeTab="changeTab(2)" v-show="tab==1" ref="ownRef" :searchValue="searchValue"/>
        <other @changeTab="changeTab(1)" v-show="tab==2" ref="otherRef" :searchValue="searchValue" @canLoad="canLoad"/>
      </div>
      <div class="search_page_right">
        <newFastmessage />
        <hotArticle />
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.searchScroll"/>
  </div>
</template>
<script>
import hotArticle from '../components/hotArticle';
import newFastmessage from '../components/newFastmessage';
import footerView from './../components/footerView'
import own from './own';
import other from './other'
export default {
  name: "detail",
  components: {
    hotArticle,
    newFastmessage,
    own,
    other,
    footerView
  },
  data() {
    return {
      tab: 1,
      searchValue: '',
      hasNext: false
    };
  },
  methods: {
    setSearch(val) {
      this.searchValue = val;
    },
    changeTab(val) {
      this.tab = val;
    },
    load() {
      if(this.tab==2) {
        this.$refs.otherRef.loadMoreList();
      }
    },
    canLoad(val) {
      this.hasNext = val;
    },
  },
  mounted() {
    if(this.$route.params.value) {
      document.title = `"${this.$route.params.value}" 的搜索结果`;
      this.$emit('setInputValue',this.$route.params.value);
      this.setSearch(this.$route.params.value);
    }
  },
  destroyed () {
    localStorage.removeItem('ROUTE_NAME')
  },
 
};
</script>
<style lang="less">
.search_page {
  padding-top: 37px;
  display: flex;
  width: 1220px;
  margin: 0 auto;
  
}
</style>
  