<template>
  <div class="h5_top_tab_pro" >
    <van-sticky :offset-top="top">
      <div class="h5_top_tab_pro_content">
        <div class="protab_view" style="height: 44px">
          <p v-for="(item,index) in tabList.slice(0,4)" :key="index"  @click="changeTab(item)" :class="`tab_item ${activeId==item.id&&'tab_item_active'}`">{{ item.name }}</p>
          <div class="shoow_all" v-show="tabList.length > 4">
            <img src="@/assets/h5Image/showall.png" alt="" @click="showAll=!showAll" :style="showAll&&{transform: 'rotate(90deg)'}"/>
          </div>
        </div>
      </div>
    </van-sticky>
    <van-popup v-model="showAll"  position="bottom" :style="{ height: '80%' }">
      <div class="other_tab" >
        <p v-for="(item,index) in tabList" :key="index"  @click="changeTab(item,true)" :style="activeId==item.id && {background: ' #EEEEEE'}">{{ item.name }}</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "toptab",
  props: {
    tabList: {
      default: [],
      type: Array
    },
    top: {
      default: 44,
      type: Number
    }
  },
  data() {
    return {
      list: [],
      showAll: false,
      list1: [],
      activeId: null,
    };
  },
  mounted() {
  },
  methods: {
    changeTab(val,status) {
      this.showAll = false;
      this.activeId = val.id;
      this.$emit('clickTab',{val,status});
    }
  },
};
</script>

<style lang="less">
.h5_top_tab_pro {
  
  .h5_top_tab_pro_content {
    position: relative;
  }
  .protab_view {
    background: #fff;
    padding: 0 8px;
    width: 100%;
    display: flex;
    align-items: center;
    .tab_item {
      height: 19px;
      line-height: 19px;
      font-size: 13px;
      font-weight: 500;
      color: #000000;
      padding: 0 12px;
      margin: 12px 0;
      margin-right: 12px;

    }
    .tab_item_active {
      background: #F0B200;
      border-radius: 19px;
      color: #fff;
    }
    .shoow_all {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 14px;
        height: auto;
        transition: .5s;
      }
    }
  }
  .other_tab {
    background: #FAFAFA;
    padding: 19px 22px;
    height: 100%;
    overflow-y: auto;
    p {
      height: 43px;
      border-radius: 14px;
      line-height: 43px;
      text-align: center;
    }
  }
  .van-popup--bottom {
    border-radius: 14px 14px 0px 0px;
    overflow: hidden;
  }
}
</style>