<template>
  <div id="pcView" >
    <div class="header_view">
      <div class="header_view_content">
        <img src="@/assets/images/logo.png" alt="logo" class="header_left" @click="$router.replace('/home')">
        <div class="header_mid" v-show="!hideAll">
          <!-- 锚链接区域 -->
          <p :class="tab==1 ? 'choose' : 'ontChoose'" @click="clickTab(1)">首页</p>
          <p :class="tab==3 ? 'choose' : 'ontChoose'" @click="clickTab(3)">深度</p>
          <p :class="tab==4 ? 'choose' : 'ontChoose'" @click="clickTab(4)">项目库</p>
          <p :class="tab==5 ? 'choose' : 'ontChoose'" @click="clickTab(5)">机构</p>
          <p :class="tab==6 ? 'choose' : 'ontChoose'" @click="clickTab(6)">工具</p>
        </div>
        <div class="header_right" v-show="!hideAll">
          <input placeholder="搜索项目/机构/资讯" v-model="searchValue" @keydown.enter="goSearch"/>
          <div class="search_btn" @click="goSearch">
            <img :src="require('@/assets/images/search_black.png') " alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="router_view">
      <transition name="fade">
        <router-view ref="routerRef" />  
      </transition>
    </div>


   
  </div>
</template>

<script>
export default {
  name: "index",
 
  data() {
    return {
      tab: 1,
      showDownload: false,
      focueInput: false,
      hideAll: false,
      searchValue: ''
    }
  },
 
  watch: {
    "$route.name"() {
      let routeName = this.$route.name;
      if(routeName.includes('pcinfo')) { // 
        this.hideAll = true;
      } else {
        this.hideAll = false;
      }
      if(routeName.includes('pcsearch')) { // 
        this.tab = 99;
      }
      if(routeName.includes('pchome')) {
        this.tab = 1;
      }
      if(routeName.includes('pcdeep')) {
        this.tab = 3;
      }
      if(routeName.includes('pcproject')) {
        this.tab = 4;
      }
      if(routeName.includes('pcorganization')) {
        this.tab = 5;
      }
      if(routeName.includes('pctools')) {
        this.tab = 6;
      }
      if(routeName.includes('pccolumns')) {
        this.tab = 7;
      }
    }
  },
  methods: {
    
    clickTab(val) {
      this.tab = val;
      let route = ''
      switch (val) {
        case 1:
          route = '/home'
          break;
        case 2:
          route = '/fastNews'
          break;
        case 3:
          route = '/deep'
          break;
        case 4:
          route = '/project'
          break;
        case 5:
          route = '/organization'
          break;
        case 6:
          route = '/tools'
          break;
        default:
          break;
      }
      this.$router.push(route)
    },
    getRoute() {
      let routeName = this.$route.name;
      if(routeName.includes('pcinfo')) { // 
        this.hideAll = true;
      } else {
        this.hideAll = false;
      }
      if(routeName.includes('pcsearch')) { // 
        this.tab = 99;
      }

      if(routeName.includes('pchome')) {
        this.tab = 1;
      }
     
      if(routeName.includes('pcdeep')) {
        this.tab = 3;
      }
      if(routeName.includes('pcproject')) {
        this.tab = 4;
      }
      if(routeName.includes('pcorganization')) {
        this.tab = 5;
      }
      if(routeName.includes('pctools')) {
        this.tab = 6;
      }

      if(routeName.includes('pccolumns')) {
        this.tab = 7;
      }
      
    },
    goSearch() {
      // this.focueInput = true
      if(this.searchValue) {
        let routeName = this.$route.name;
        localStorage.setItem('ROUTE_NAME',routeName)
        if(this.$route.name=='pcsearch') {
          this.$refs.routerRef.setSearch(this.searchValue)
          return 
        }
        this.$router.push('/result/'+this.searchValue)
      }
    },
  },
  mounted() {
    this.getRoute();
    // this.linkWallet();
  },
  created() {
    if(!this.$route.path.includes('tool')) {
      document.body.style.overflow = 'hidden';
    }
  }
  
};
</script>

<style lang="less">
#pcView {
  min-height: 100vh;
  padding-top: 58px;
  font-family: TaipeiSansTCBeta;
  .header_view {
    width: 100%;
    height: 58px;
    background: linear-gradient(135deg, #191919 0%, #F0B200 100%);
    box-shadow: 0px 2px 4px 0px #EEEEEE;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;
    .header_view_content {
      width: 1220px;
      height: 58px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    .header_left {
      width: 100px;
      margin-right: 66px;
      flex-shrink: 0;
    }
    .header_mid {
      flex: 1;
      display: flex;
      align-items: center;
      p  {
        font-size: 15px;
        margin-right: 52px;
        cursor: pointer;
        flex-shrink: 0;
      }
      .ontChoose {
        color: #ffffff;
      }
      .choose {
        color: #F0B200;
        font-weight: 600;
      }
    }
    .header_right {
      width: 374px;
      height: 41px;
      background: #fff;
      border-radius: 21px;
      display: flex;
      align-items: center;
      padding: 0 14px;
      input {
        flex: 1;
        height: 41px;
        border: none;
        outline: none;
        font-size: 14px;
        text-align: left;
        background: none;
        color: #4a4a4a;
        caret-color: #F0B200;
      }
      input::-webkit-input-placeholder {
        color: #CCCCCB;
        font-size: 12px;
      }
      .search_btn {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 23px;
          height: auto;
        }
      }
    }
  }
  .router_view {
    flex: 1;
    width: 100%;
  }

  .fade-enter-active {
    transition: all 0.3s ease;
  }
  .fade-leave-active {
    transition: all 0.3s ease;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
