<template>
  <div class="org_h5_page">
    <div class="top_bar">
      <topInput />
    </div>
    <div class="orgLib_title" style="margin-left: 15px;margin-top: 15px;">
      <p>推荐机构</p>
      <img src="@/assets/images/projectLib/hot.png" alt="">
    </div>
    <!-- swiper -->
    <div class="swiper-container hotOrgswiper" v-if="hotList.length">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,index) in hotList" :key="index+'hot'" @click="goDetail(item)">
          <div class="swiperLeft">
            <img :src="item.icon" alt="" />
            <div>
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div class="swiperRight">
            <p>{{item.projectCount}}</p>
            <span>投资项目</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="swiperNodata">
      <van-loading color="#f0b200"  :size="34"/>
    </div>

    <div class="allPrijectTitle">
      <div class="orgLib_title" style="width: 75px">
        <p>全部</p>
      </div>
      <p class="projectNumber">机构数<span>{{ totalNums }}</span></p>
    </div>
    
    <div style="padding: 0 15px">
      <div v-if="listData.list.length">
        <van-list
          v-model="listData.loading"
          :finished="!listData.hasNext"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="orgList">
            <div class="orgItem" v-for="(item,index) in listData.list" :key="index" :style=" (index+1)%2 == 0 && { marginRight: 0 }" @click="goDetail(item)">
              <div class="orgItemInfo">
                <img :src="item.icon" alt="">
                <div>
                  <span>{{ item.fundedYear }}</span>
                </div>
                <p>{{ item.projectCount }}</p>
              </div>
              <p class="orgItemName showline1">{{ item.title }}</p>
              <p class="orgItemIntro showline2">{{ item.excerpt }}</p>
            </div>
          </div>
        </van-list>
      </div>
      <div class="loadingView" v-else>
        <van-loading color="#f0b200" v-if="listData.loading" :size="34"/>
        <p v-else>暂无数据</p>
      </div>
      
    </div>
    <div style="height: 30px"></div>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import topInput from './../components/topInput'
import topTab from './../components/topTab'
import { getOrgList } from '@/api/index'
export default {
  name: "topic",
  components: {
    topInput,
    topTab
  },
  data() {
    return {
      hotList: [],
      listData: {
        list: [],
        page: 1,
        size: 30,
        loading: true,
        hasNext: false,
      },
      loadMore: false,
      swiperOrgObj: null,
      totalNums: 0,
      showPreBtn: false,
      showNextBtn: true,
    };
  },
  mounted() {
    this.getHot();
    this.getList();
  },
  methods: {
    initSwiper() {
      const self = this;
      this.swiperOrgObj = new Swiper('.hotOrgswiper', {
        loop: false,
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 5,
        on:{
          slideChangeTransitionEnd: function(){
            if(this.activeIndex == 0) {
              self.showPreBtn = false;
            }
            if(this.activeIndex > 0) {
              self.showPreBtn = true;
            }
            if(this.activeIndex < self.hotList.length-1) {
              self.showNextBtn = true;
            }
            if(this.activeIndex == self.hotList.length-1) {
              self.showNextBtn = false;
            }
          },
        },
      })
    },
    slideSwiper(type) {
      if(type==1) {
        this.swiperOrgObj.slidePrev();
      } else {
        this.swiperOrgObj.slideNext();
      }
    },
    getHot() {
      getOrgList({pinned: true})
      .then(res=>{
        if(res.data && res.data.items) {
          this.hotList = res.data.items;
          this.$nextTick(()=>{
            this.initSwiper();
          })
        }
      })
    },
    getList(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      getOrgList({
          page: this.listData.page,
          size: this.listData.size,
          // category: this.tabIndex
      })
      .then(res=> {
        const data = res.data;
        const result = data.items;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(result);
          this.loadMore = false;
        } else {
          this.listData.list = result;
        }
        this.totalNums = res.data.totalPages * this.listData.size;
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    onLoad() {
      if(!this.loadMore && this.listData.page!=1) {
        this.loadMore = true;
        this.getList(true)
      }
    },
    goDetail(item) {
      const scrollTop = document.documentElement.scrollTop
      sessionStorage.setItem('orgPosition',scrollTop)
      this.$router.push('/h5/organizationDetail/'+item.id)
    }
  },
  activated() {
    const value = sessionStorage.getItem('orgPosition') || 0;
    setTimeout(() => {
      document.documentElement.scrollTop = value;
    }, 0);
  },
};
</script>
<style lang="less">
.org_h5_page {
  padding-top: 64px;
  padding-bottom: 68px;
  .top_bar {
    width: 100%;
    height: 64px;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .orgLib_title {
    width: 90px;
    height: 21px;
    line-height: 21px;
    display: flex;
    align-items: center;
    background: url('../../../assets/images/projectLib/projectlibTitleicon.png') no-repeat left bottom / 100% 13px;
    margin-bottom: 15px;
    p {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
    }
    img {
      width: 13px;
      height: 16px;
      margin-left: 5px;
    }
  }
  .allPrijectTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    .projectNumber {
      font-size: 15px;
      font-weight: 600;
      color: #4A4A4A;
      span {
        padding-left: 5px;
        color: #F0B200;
      }
    }
  }
  .projectLib_title {
    margin-left: 15px;
    width: 90px;
    height: 21px;
    line-height: 21px;
    display: flex;
    align-items: center;
    background: url('../../../assets/images/projectLib/projectlibTitleicon.png') no-repeat left bottom / 100% 13px;
    margin-bottom: 15px;
    p {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
    }
    img {
      width: 13px;
      height: 16px;
      margin-left: 5px;
    }
  }
  .swiperNodata {
    width: 100%;
    padding: 0 10px;
    height: 72px;
    margin-bottom: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #999;
    }
  }
  .hotOrgswiper {
    height: 72px;
    margin-bottom: 19px;
    margin-left: 15px;
    .swiper-slide {
      width: 200px;
      height: 72px;
      background: url('./../../../assets/images/projectLib/swiperBg1.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .swiperLeft {
        flex: 1;
        display: flex;
        align-items: center;
        img {
          width: 38px;
          height: 38px;
          margin-right: 5px;
          border-radius: 100%;
        }
        p {
          font-size: 12px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 3px;
        }
        span {
          font-size: 10px;
          color: #E8E3D4;
          line-height: 13px;
        }
      }
      .swiperRight {
        height: 100%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 12px;
          color: #FFFFFF;
          font-weight: 600;
          line-height: 16px;
        }
        span {
          font-size: 10px;
          color: #E8E3D4;
        }
      }
    }
  }
  // list
  .orgList {
    display: flex;
    flex-wrap: wrap;
    .orgItem {
      flex: 1;
      min-width: 40%;
      min-height: 79px;
      background: #FAFAFA;
      border-radius: 5px;
      padding: 10px;
      margin-right: 6px;
      margin-bottom: 15px;
      cursor: pointer;
      .orgItemInfo {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          margin-right: 5px;
          border-radius: 100%;
        }
        span {
          padding: 4px 9px;
          background: #FFF6D8;
          font-size: 11px;
          font-weight: 600;
          color: #000;
        }
        p {
          flex: 1;
          text-align: right;
          font-size: 12px;
          font-weight: 600;
          color: #000000;
        }
      }
      .orgItemName {
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        color: #000;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .orgItemIntro {
        font-size: 13px;
        font-weight: 500;
        color: #444;
        text-align: justify;
        line-height: 19px;
      }
    }
  }
  .loadingView {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
  .moreDeepBtn {
    margin: 30px 38px;
    height: 49px;
    line-height: 49px;
    background: #f3f2f0;
    border-radius: 11px;
    font-size: 15px;
    font-weight: 600;
    color: #9c9c9c;
    text-align: center;
    cursor: pointer;
    position: relative;
    &:hover {
      background: #FFF6E6;
    }
    .moreFastMsg_loading {
      width: 15px;
      height: 100%;
      position: absolute;
      left: 58%;
      top: 0;
    }
  }
}
</style>
  