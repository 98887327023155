<template>
  <div class="search_page_left">
    <div class="tabView">
      <div class="tab">
        <p @click="$emit('changeTab')">本站</p>
        <p class="tab_active">Mirror</p>
      </div>
      <p class="searchNum">包含<span>{{searchValue}}</span>的结果<span style="padding-left:5px">共{{ listData.list.length }}条</span></p>
    </div>
    <div v-if="listData.list.length">
      <div>
        <a class="otherItem" v-for="item in listData.list" :href="item.link" target="_blank">
          <p class="otherItemTitle showline1" v-html="item.title"></p>
          <div class="otherItemContent">
            <p class="showline3" v-html="item.news_describe"></p>
            <img :src="item.image" alt="" v-if="item.image"/>
          </div>
          <div class="otherItemAuthor">
            <img :src="item.avatar || require('./../../../assets/h5Image/mirror.png')" alt="" />
            <p>{{ item.nickname }} · {{ $dayjs(new Date(item.creat_time * 1000)).format('YYYY/MM/DD') }}</p>
          </div>
        </a>
      </div>
      <div class="moreDeepBtn" v-if="listData.hasNext">
        <span>加载更多</span>
        <div class="moreFastMsg_loading" v-if="loadMore">
          <van-loading color="#9c9c9c" size="44px"/>
        </div>
      </div>
    </div>
    <div v-else class="searchLoading">
      <van-loading color="#f0b200" :size="54" v-if="listData.loading"/>
      <p v-else>暂无数据</p>
    </div>
    <div style="height:150px"></div>
  </div>
</template>
<script>
import articleBorder from './../components/articleBorder';
import { searchMirror } from '@/api/index';
export default {
  name: "detail",
  props: [ 'searchValue' ],
  components: {
    articleBorder,
  },
  data() {
    return {
      listData: {
        list: [],
        page: 1,
        loading: true,
        hasNext: false
      },
      loadMore: false,
      searchTotal: 0,
    };
  },
  watch: {
    searchValue() {
      this.listData.page = 1;
      this.$nextTick(()=>{
        this.searchOther();
      })
    }
  },
  methods: {
    searchOther(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      searchMirror({ keyword: this.searchValue,page: this.listData.page })
      .then(res=>{
        const data = res.data;
        let result = []
        if(data.data && data.data.length) {
          result = data.data.map(item=>{
            return {
              ...item,
              title: item.title.replaceAll(this.searchValue,`<span style='color:#F0B200'>${this.searchValue}</span>`),
              news_describe: item.news_describe.replaceAll(this.searchValue,`<span style='color:#F0B200'>${this.searchValue}</span>`),
            }
          })
        }
        if(loadMore) {
          this.listData.list = this.listData.list.concat(result);
          this.loadMore = false;
        } else {
          this.listData.list = result;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = (result.length != 0);
        this.$emit('canLoad', (result.length != 0));
        this.listData.loading = false;
      })
      .catch(()=>{
        this.listData.loading = false;
      })
    },
    loadMoreList() {
      if(!this.loadMore) {
        this.loadMore = true;
        setTimeout(() => {
          this.searchOther(true)
        }, 500);
      }
    },
  },
  mounted() {
  },
};
</script>
<style lang="less">
.search_page_left {
  .tabView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .tab {
      display: flex;
      width: 142px;
      height: 34px;
      border-radius: 8px;
      background: #EEEEEE;
      p {
        flex: 1;
        height: 34px;
        font-size: 14px;
        font-weight: 600;
        color: #9C9C9C;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
      }
      .tab_active {
        border-radius: 8px;
        background: #444444;
        color: #fff;
      }
    }
    .searchNum {
      font-size: 14px;
      font-weight: 600;
      color: #9C9C9C;
      span {
        color: #F0B200;
      }
    }
  }
  .listView {
    margin-bottom: 15px;
  }
  .otherItem {
    display: block;
    width: 100%;
    background: rgba(255,255,255,0.2);
    box-shadow: 0px 2px 15px 0px rgba(238,238,238,1);
    border-radius: 11px;
    padding: 15px 15px 18px 15px;
    margin-bottom: 15px;
    cursor: pointer;
    .otherItemTitle {
      font-size: 21px;
      font-weight: 600;
      color: #000;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .otherItemContent {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        line-height: 23px;
        word-break: break-all;
      }
      img {
        width: 120px;
        height: 79px;
        flex-shrink: 0;
        margin-left: 16px;
        border-radius: 11px;
      }
    }
    .otherItemAuthor {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 8px;
        box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
      }
      p {
        font-size: 12px;
        font-weight: 500;
        color: #737373;
      }
    }
  }
  .moreDeepBtn {
      margin-left: 64px;
      margin-right: 99px;
      margin-bottom: 15px;
      margin-top: 30px;
      height: 38px;
      line-height: 38px;
      background: #f3f2f0;
      border-radius: 19px;
      font-size: 15px;
      font-weight: 600;
      color: #9c9c9c;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
  }

  .searchLoading {
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
  