<template>
  <div class="h5_footer">
    <div class="footer_title">
      <img src="@/assets/images/logo.png" alt="" />
      <span></span>
      <p>更懂你的WEB3资讯平台</p>
    </div>
    <p class="footer_intro">Web3CN是一个专业的web3信息服务平台，为用户提供及时、专业且有品质的web3资讯和数据，致力于让更多的人进入web3并捕捉到web3的市场机遇。</p>
    <div class="footer_concat">
      <div class="web">
        <a href="https://twitter.com/Web3CN_Pro" target="_blank"><img src="@/assets/images/twitter.png" alt=""></a>
        <a href="https://discord.com/invite/CDAkZ8Kc3K" target="_blank"><img src="@/assets/images/discord.png" alt=""></a>
        <a href="mailto:marketing@web3cn.pro" target="_blank"><img src="@/assets/images/msg.png" alt=""></a>
      </div>
      <div class="wx">
        <img src="@/assets/images/code1.png" alt="">
        <img src="@/assets/images/wx.png" alt="">
      </div>
    </div>
    <div style="width: 100%;height: 2px;background: #CECABE;"></div>
    <div class="footer_other">
      <p>工作机会</p>
      <p>隐私政策</p>
      <p>版权申明</p>
    </div>
    <p class="footer_copyRight">Copyright © 2021-2023 浙ICP备2021024901号</p>
  </div>
</template>

<script>
export default {
  name: "toptab",
  props: {
   
  },
  data() {
    return {};
  },
  created() {
    // 

  },
  
  mounted() {
  },
  methods: {
   
  },
  
};
</script>

<style lang="less">
.h5_footer {
  width: 100%;
  background: linear-gradient(135deg, #191919 0%, #F0B200 100%);
  padding: 38px 15px 53px 15px;
  .footer_title {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    img {
      width: auto;
      height: 19px;
      flex-shrink: 0;
    }
    span {
      width: 2px;
      height: 19px;
      background: #CECABE;
      margin: 0 8px;
    }
    p {
      font-size: 13px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 19px;
    }
  }
  .footer_intro {
    font-size: 13px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #9C9C9C;
    line-height: 24px;
    text-align: justify;
    margin-bottom: 28px;
  }
  .footer_concat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    .web {
      display: flex;
      align-items: center;
      a {
        width: 38px;
        height: 38px;
        margin-right: 19px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .wx {
      display: flex;
      align-items: center;
      img {
        width: 70px;
        height: 71px;
        margin-right: 13px;
        border-radius: 8px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .footer_other {
    margin-top: 19px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    p {
      font-size: 13px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 19px;
      margin-right: 29px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .footer_copyRight {
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 16px;
  }
}
</style>