<template>
  <div class="infinite-list-wrapper" style="overflow:auto;height:100vh" ref="homeScroll">
    <div class="index_page" v-infinite-scroll="load" :infinite-scroll-disabled="!listData.hasNext" :infinite-scroll-distance="100">
      <div class="index_page_left">
        <div class="swiper-container banner_swiper" v-show="bannerList.length">
          <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
                <img :src="item.cover" alt="" />
                <div class="swiper_text showline1">{{ item.title }}</div>
              </div>
          </div>
          <div class="swiper-pagination"></div>
          
        </div>
        <div class="banner_swiper banner_swiper_loading" v-show="!bannerList.length">
          <van-loading color="#f0b200"  :size="54"/>
        </div>
        <!-- 导航 -->
        <!-- 导航 -->
        <div class="nav_swiper">
          <van-tabs  @click="clickTab">
            <van-tab v-for="(item,index) in tabList" :key="index" :title="item.name" style=""  >
            </van-tab>
          </van-tabs>
        </div>
        <!-- 热点新闻 -->
        <div class="listContent">
          <div class="noticeView">
            <p>更新提醒</p>
            <div class="noticeImg1" v-if="openNotice" @click="openNotice = false"></div>
            <div class="noticeImg2" v-else @click="openNotice = true"></div>
          </div>
          <div class="fastmessage_view" v-if="listData.list.length">
            <div class="dateList" v-for="(item, index) in listData.list" :key="index">
              <p class="itemDateTxt" v-if="showDate(item,index)">{{ item.dateName }}</p>
              <div class="fastMsgList">
                <div
                  class="fastMsgItem"
                >
                  <div class="hoverBg"></div>
                  <div class="itemTitle">
                    <img
                      src="@/assets/images/fastmsgItemIcon.png"
                      alt=""
                      class="titleLeftIcon"
                    />
                    <span class="titleTime">{{ $dayjs(item.publishTime).format('HH:mm') }}</span>
                    <p class="titleContent showline2" @click="goDetail(item)">
                    <span v-if="item.pinned"></span>
                      {{ item.title }}
                    </p>
                    <div class="titleRightIcon1" @click.stop="copy(item)"></div>
                    <div class="titleRightIcon2" @click.stop="share(item)"></div>
                  </div>
                  <div class="itemContent" @click="goDetail(item)">
                    <div class="contentTxt" v-html="item.excerpt "></div>
                    <div class="contentTagList" v-if="item.relates && item.relates.length">
                      <div class="contentTag" v-for="rel in item.relates"  @click.stop="$router.push('/projectDetail/'+rel.id)" :style="item.relates.length > 1 ? {flex: 1} : {}">
                        <img :src="rel.icon" alt="" class="contentTagIcon" /> 
                        <div>
                          <p class="contentTagName">{{ rel.title }}&nbsp;&nbsp;<span>{{ (rel.tags && rel.tags.length) ? rel.tags[0].name : '' }}</span></p>
                          <p class="contentTagIntro showline1">{{ rel.excerpt }}</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <span
                      class="leftBorder"
                      v-if="showBorder(item,index)"
                    ></span>
                </div>
              </div>
            </div>
            <div class="moreFastMsg" v-if="listData.hasNext">
              <span>查看更多</span>
              <div class="moreFastMsg_loading" v-if="loadMore">
                <van-loading color="#9c9c9c" size="44px"/>
              </div>
            </div>
          </div>
          <div class="fastmessage_view loadingView" v-else>
            <van-loading color="#f0b200" v-if="listData.loading" :size="54"/>
            <p v-else>暂无数据</p>
          </div>
        </div>

      </div>
      <div class="index_page_right">
        <div class="appDownload">
          <div class="downloadTxt">
            <p>WEB3CN</p>
            <p>APP下载</p>
          </div>
          <div class="downloadLink">
            <div class="downloadItem">
              <img src="@/assets/images/downloadicon_pg.png" alt=""/>
              <p>IOS下载</p>
            </div>
            <div class="downloadItem">
              <img src="@/assets/images/downloadicon_az.png" alt=""/>
              <p>安卓下载</p>
            </div>
          </div>
        </div>
        
        <financing />
        <hotproject />
        <recommendOrg />
        <div class="stickyView">
          <div class="rightBtnview">
            <a class="rightBtnviewItem" href="https://docs.google.com/forms/d/e/1FAIpQLSdy-1p2HEArJrg9LS4lJ8eOK7kxTSSYjvORyk8mXKHiif82RA/viewform?usp=sf_link" target="_blank">
              <img src="@/assets/images/homeRightBtn1.png" alt="">
              <div>
                <p>寻求报道</p>
                <span>Seek-report</span>
              </div>
            </a>
            <a class="rightBtnviewItem" href="https://docs.google.com/forms/d/e/1FAIpQLSfjUAxVFK2mwoqCfYGYP_4ISS864YkeQCaghEvseJf1G89llw/viewform?usp=sf_link" target="_blank">
              <img src="@/assets/images/homeRightBtn2.png" alt="">
              <div>
                <p>项目收录</p>
                <span>Project inclusion</span>
              </div>
            </a>
            <div class="rightBtnviewItem">
              <img src="@/assets/images/homeRightBtn3.png" alt="">
              <div>
                <p>加速器</p>
                <span>accelerator</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分享弹窗 -->
      <transition name="fade">
        <sharedialog v-if="showShare" @close="closeShare" :item="clickItem"/>
      </transition>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.homeScroll"/>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import notifymsg from "./notifymsg";
import sharedialog from "./sharedialog";
import hotproject from './hotproject';
import financing from './financing';
import recommendOrg from './recommendOrg';
import footerView from './../components/footerView'
import { getCategories, getList } from '@/api/index';
export default {
  name: "detail",
  components: {
    notifymsg,
    sharedialog,
    hotproject,
    financing,
    recommendOrg,
    footerView
  },
  data() {
    const weekMap = new Array("日", "一", "二", "三", "四", "五", "六");
    const now = new Date();
    return {
      bannerList: [],

      tabIndex: 0,
      tabList: [],
      openNotice: localStorage.getItem('OPEN_NOTICE')==1 ? true : false, //是否打开提示音
      nowDate: {
        // 日期
        month: now.getMonth() + 1,
        day: now.getDate(),
        week: weekMap[now.getDay()],
      },
     
      listData: {
        list: [],
        page: 1,
        size: 20,
        loading: false,
        hasNext: false
      },

      notifyObj: {},
      showShare: false,
      clickItem: {},
      loadMore: false,
      // interval
      intervalObj: null
    };
  },
  watch: {
    tabIndex(newVal,oldVal) {
      this.listData = {
        list: [],
        page: 1,
        size: 20,
      }
      if(oldVal!==0) {
        this.getListData();
      }
    },
    openNotice() {
      localStorage.setItem('OPEN_NOTICE',this.openNotice ? 1 : 0);
      if(this.openNotice) {
        this.intervalObj = setInterval(() => {
          this.updateMsg();
        }, 10000);
      } else {
        clearInterval(this.intervalObj)
      }
    }
  },
  destroyed() {
    clearInterval(this.intervalObj)
  },
  mounted() {
    this.getTabs();
    this.getBannerList();
  },
  methods: {
    showDate(item,index) {
      let type = false;
      if(index == 0) {
        type = true;
      } else {
        if(item.dateName != this.listData.list[index-1].dateName) {
          type = true
        }
      }
      return type
    },
    showBorder(item,index) {
      let type = true;
      if(index+1 == this.listData.list.length) {
        type = false
      } else {
        if(item.dateName != this.listData.list[index+1].dateName) {
          type = false
        }
      }
      return type
    },
    initBannerSwiper() {
      const self = this;
      new Swiper('.swiper-container', {
        autoplay: { //自动开始
          delay: 5000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        spaceBetween: 15,
        loop: true, // 循环模式选项
          // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 分页器可以点击
        },
        on: {
          click: function(){
            self.$router.push('/deepDetail/'+self.bannerList[this.realIndex].id);
          }
        }
      })
    },
    getTabs() {
      getCategories('flash')
      .then(res=> {
        const { data } = res;
        if(data) {
          this.tabList = [{name:'全部',id: null}].concat(data.items);
          this.tabIndex = null;
          this.getListData();
          if(this.openNotice) {
            this.intervalObj = setInterval(() => {
              this.updateMsg();
            }, 10000);
          }
        }
      })
    },
    clickTab(val) {
      this.tabIndex = this.tabList[val].id;
    },
    goDetail(item) {
      this.$router.push('/fastDetail/'+item.id)
    },
    copy(item) {
      this.$utils.copy(window.location.origin +'/fastDetail/'+ item.id,()=>{
        this.$toast.success("复制成功");
      })
    },
    share(item) {
      this.clickItem = item;
      this.showShare = true;
      return;
    },
    closeShare() {
      this.showShare = false;
    },
    getBannerList() {
      getList('article',{
        pinned: true
      })
      .then(res=>{
        const data = res.data;
        if(res.data) {
          this.bannerList = data.items;
          this.$nextTick(()=>{
            this.initBannerSwiper();
          })
        }
      })
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      let params = {
        page: this.listData.page,
        size: this.listData.size, 
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      getList('flash',params)
      .then(res=> {
        const data = res.data;
        const result = data.items.map(item=>{
          return {
            ...item,
            dateName: this.$dayjs(item.publishTime).format('YY年MM月DD日') 
          }
        })
        if(loadMore) {
          this.listData.list = this.listData.list.concat(result);
          this.loadMore = false;
        } else {
          this.listData.list = result;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    loadMoreList() {
      if(!this.loadMore) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    async updateMsg() {
      let params = {
        page: 1,
        size: 1, 
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      const res = await getList('flash',params)
      const data = res.data;
      if(data) {
        const result = data.items[0];
        if(this.listData.list.legth && new Date(this.listData.list[0].publishTime) < new Date(result.publishTime)) {
          // 新消息 弹窗提示逻辑
          const notifyObj = this.$notify({
            title: "",
            dangerouslyUseHTMLString: true,
            message: (
              <notifymsg
                item={result}
                closeNotify={this.closeNotify}
                index={1}
              />
            ),
            duration: 0,
            position: "top-left",
            customClass: "fastMsg_page_el_notification",
            showClose: false,
          });
          this.notifyObj["notify" +1] = notifyObj;
          this.listData.list = [result].concat(this.listData.list);
        }
      }
    },
    closeNotify(index) {
      this.notifyObj["notify" + index] &&
        this.notifyObj["notify" + index].close();
    },
    load() {
      if(!this.loadMore) {
        this.loadMore = true;
        setTimeout(() => {
          this.getListData(true)
        }, 500);
      }
    },
  },
};
</script>
<style lang="less">
.index_page {
  padding-top: 34px;
  display: flex;
  width: 1220px;
  margin: 0 auto;
  .index_page_left {
    padding-bottom: 60px;
    .banner_swiper_loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .banner_swiper {
      width: 786px;
      height: 375px;
      border-radius: 11px;
      margin-bottom: 30px;
      position: relative;
      .swiper-slide {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 11px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .swiper_text {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 75px;
        background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, #000000 100%);
        border-radius: 0 0 11px 11px;
        z-index: 1;
        padding: 15px;
        color: #fff;
        font-size: 23px;
        font-weight: 600;
        line-height: 25px;
      }
      .swiper-pagination {
        bottom: 15px;
        right: 15px;
        left: auto;
        width: auto;
      }
      .swiper-pagination-bullet {
        background: #D8D8D8;
      }
      .swiper-pagination-bullet-active {
        background: #fff;
      }
    }
    .nav_swiper {
      width: 786px;
      height: 53px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
      border-radius: 11px;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      .van-tabs__nav--line.van-tabs__nav--complete {
        padding: 0;
      }
      .van-tabs {
        width: 100%;
      }
      .van-tab {
        font-size: 15px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          font-weight: 600;
          box-shadow: 0px 2px 8px 0px rgba(238,238,238,1);
          border-radius: 11px;
          color: rgb(240, 178, 0);
          background: #fff;
        }
      }
      .van-tab--active {
        font-weight: 600;
        border-radius: 11px;
        background: rgb(240, 178, 0);
        color: #fff;
        &:hover {
          color: rgb(240, 178, 0);
        }
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs--line .van-tabs__wrap {
        height: 53px;
        border-radius: 11px;
      }
    }
    .listContent {
      position: relative;
      .noticeView {
        position: absolute;
        right: 0;
        top: 15px;
        display: flex;
        align-items: center;
        height: 21px;
        p {
          font-size: 15px;
          font-weight: 500;
          color: #444444;
          margin-right: 8px;
        }
        .noticeImg1 {
          width: 18px;
          height: 18px;
          background: url('../../../assets/images/noticeOn.png') no-repeat center center / 17px 15px;
          cursor: pointer;
          &:hover {
            background: url('../../../assets/images/noticeOn_hover.png') no-repeat center center / 17px 15px;
          }
        }
        .noticeImg2 {
          width: 18px;
          height: 18px;
          background: url('../../../assets/images/noticeOff.png') no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          &:hover {
            background: url('../../../assets/images/noticeOff_hover.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .loadingView {
        width: 100%;
        min-height: calc(100vh - 240px);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 16px;
          color: #ccc;
          text-align: center;
        }
      }
      .fastmessage_view {
        padding: 15px 14px;
        width: 100%;
        min-height: calc(100vh - 240px);
        margin-bottom: 23px;
        .itemDateTxt {
          height: 21px;
          line-height: 21px;
          font-size: 15px;
          font-weight: 500;
          color: #737373;
          padding-left: 25px;
          background: url('./../../../assets/images/homedateicon.png') no-repeat left center / 14px 14px;
          margin-bottom: 29px;
        }
        .fastMsgList {
          .fastMsgItem {
            width: 100%;
            position: relative;
            .hoverBg {
              position: absolute;
              z-index: -1;
              width: calc(100% - 40px);
              height: 100%;
              top: -15px;
              left: 55px;
              border-radius: 8px;
            }
            &:hover {
              .hoverBg {
                box-shadow: 0 -12px 20px -6px rgba(140,150,162,.2);
                border-radius: 6px;
              }
            }
            .itemTitle {
              display: flex;
              line-height: 25px;
              .titleLeftIcon {
                width: 12px;
                height: 11px;
                margin-top: 7px;
                margin-right: 7px;
                flex-shrink: 0;
              }
              .titleTime {
                font-size: 12px;
                font-weight: 500;
                color: #737373;
                margin-right: 20px;
                flex-shrink: 0;
              }
              .titleContent {
                flex: 1;
                font-size: 20px;
                font-weight: 600;
                color: #000;
                letter-spacing: 1px;
                cursor: pointer;
                text-align: justify;
                line-height: 25px;
                span {
                  width: 20px;
                  height: 25px;
                  display: inline-block;
                  vertical-align: top;
                  margin-right: 5px;
                  background: url('./../../../assets/images/collect.png') no-repeat left center / 20px 20px;
                }
              }
              .titleRightIcon1 {
                margin-top: 4px;
                width: 17px;
                height: 17px;
                margin-left: 8px;
                cursor: pointer;
                background: url('./../../../assets/images/fastNewsCopy.png') no-repeat center center / 100% 100%;
                &:hover {
                  background: url('./../../../assets/images/fastNewsCopy_hover.png') no-repeat center center / 100% 100%;
                }
              }
              .titleRightIcon2 {
                margin-top: 4px;
                width: 17px;
                height: 17px;
                margin-left: 8px;
                cursor: pointer;
                background: url('./../../../assets/images/fastNewsShare.png') no-repeat center center / 100% 100%;
                &:hover {
                  background: url('./../../../assets/images/fastNewsShare_hover.png') no-repeat center center / 100% 100%;
                }
              }
            }
            .leftBorder {
              position: absolute;
              width: 2px;
              height: 100%;
              background: #cecabe;
              opacity: 0.2;
              top: 5px;
              left: 5px;
            }
            .itemContent {
              padding-left: 70px;
              padding-top: 24px;
              padding-bottom: 28px;
              position: relative;
              cursor: pointer;

              .contentTxt {
                font-size: 14px;
                font-weight: 500;
                color: #4a4a4a;
                line-height: 24px;
                margin-bottom: 11px;
                text-align: justify;
              }

              .contentTagList {
                display: flex;
                align-items: center;
              }
              .contentTag {
                display: flex;
                align-items: center;
                max-width: 50%;
                height: 56px;
                background: #FAF9F8;
                padding: 0 15px;
                cursor: pointer;
                margin-right: 30px;
                border-radius: 4px;
                flex-shrink: 0;
                .contentTagIcon {
                  margin-right: 14px;
                  width: 34px;
                  height: 34px;
                  background: #FFFFFF;
                  box-shadow: 0px 2px 3px 0px rgba(204,204,203,1);
                  border-radius: 50%;
                }
                .contentTagName {
                  font-size: 14px;
                  font-weight: 500;
                  color: #4A4A4A;
                  margin-bottom: 7px;
                  span {
                    padding: 2px 12px;
                    background: #FFF6D8;
                    font-size: 12px;
                    color: #F0B200;
                  }
                }
                .contentTagIntro {
                  font-size: 12px;
                  font-weight: 500;
                  color: #9C9C9C;
                  line-height: 15px
                }
              }
            }
            &:hover {
              .titleContent {
                color: #F0B200;
              }
            }
          }
        }
        .moreFastMsg {
          margin-left: 64px;
          margin-bottom: 15px;
          margin-top: 30px;
          height: 49px;
          line-height: 49px;
          background: #f3f2f0;
          border-radius: 11px;
          font-size: 15px;
          font-weight: 600;
          color: #9c9c9c;
          text-align: center;
          cursor: pointer;
          position: relative;
          &:hover {
            background: #FFF6E6;
          }
          .moreFastMsg_loading {
            width: 15px;
            height: 100%;
            position: absolute;
            left: 58%;
            top: 0;
          }
        }
      }
    }
  }
  .index_page_right {
    flex-shrink: 0;
    width: 374px;
    margin-left: 60px;
    .stickyView {
      position: sticky;
      top: 100px;
    }
    .appDownload {
      width: 100%;
      height: 102px;
      background: url('./../../../assets/images/index_right_top_bg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      margin-bottom: 21px;
      .downloadTxt {
        height: 102px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin-left: 38px;
        margin-right: 76px;
        line-height: 30px;
      }
      .downloadLink {
        display: flex;
        align-items: center;
        .downloadItem {
          height: 102px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 1px;
          &:last-child {
            margin-left: 30px;
          }
          img {
            width: 45px;
            height: 45px;
          }
          p {
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            margin: 0 auto;
            margin-top: 8px;
          }
        }
      }
    }
    .rightBtnview {
      width: 100%;
      height: 289px;
      background: #FFFFFF;
      border-radius: 8px 8px 0px 0px;
      border: 1px solid #EDEDED;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 15px;
      .rightBtnviewItem {
        width: 100%;
        height: 71px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:nth-child(1) {
          background: url('./../../../assets/images/homeRightBtn1bg.png') no-repeat center center / 100% 100%;
        }
        &:nth-child(2) {
          background: url('./../../../assets/images/homeRightBtn2bg.png') no-repeat center center / 100% 100%;
          margin: 23px 0;
        }
        &:nth-child(3) {
          background: url('./../../../assets/images/homeRightBtn3bg.png') no-repeat center center / 100% 100%;
        }
        img {
          width: 42px;
          margin-right: 20px;
        }
        div {
          width: 100px;
        }
        p {
          font-size: 15px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 21px;
        }
        span {
          font-size: 12px;
          font-weight: 600;
          color: #E8E3D4;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
  