<template>
  <div style="overflow:auto;height:100vh" ref="infoDetailScroll"  class="infinite-list-wrapper">
    <div class="infobgView">
      <img src="@/assets/images/infobg.png" alt="" class="infoBg">
      <div class="infoDetail">
        <div class="infoContent">
          <p class="title">隐私政策</p>
          <p class="text">
            将按照以下约定向您提供本条款涉及的相关服务。请您使用本平台服务前仔细阅读本条款。您在使用本平台服务时即表示您已仔细阅读并明确同意遵守本条款，并受本条款的约束。您使用或继续使用我们的服务，即意味着同意我们按照本《用户服务与隐私协议》（以下简称“本协议”）收集、使用、储存和分享您的相关信息。
          </p>
          <p class="title1">一、本协议范围和服务内容</p>
          <p class="text">端移动端设备等方式使用WEB3CN服务）所订立的协议。您知悉并同意，WEB3CN为用户提供第三方信货产品的适配服务，将定期或不定期的收集个人信息并为您推送服务信息，推送方式包括但不限于电话、短信、电子邮件、微信等，WEB3CN对提供的服务拥有最终解释权。</p>
          <p class="title1">二、用户个人数据与信息安全</p>
          <p class="text">
            WEB3CN在任何时候竭力保证客户的个人信息不被人擅自或意外取得、处理或删除。采取各种实际措施保证个人信息不会被保管超过合理的期限，本公司将遵守所有关于可辨识个人信息保存的法规要求。<br/>
            1、支持安全套接层协议和128位加密技术--这种加密技术是互联网上保护数据安全的行业标淮，让客户在进行用户管理、个人账户管理、充值等涉及敏感信息的操作时，信息被自动加密，然后才被安全地通过互联网发送出去。<br/>
            2、采取各种合适的物理、电子和管理方面的措施来保护数据，以实现对数据安全的承诺。<br/>
            3、采用集中的影像存储服务来保证合同等文件信息的存储，有效避免被篡改以及删除，并可以实现永久保存。<br/>
            4、网站之间的页面跳转以及数据的发送采用数字签名技术来保证信息以及来源的不可否认性。
          </p>
          <p class="title1">三、用户个人数据与信息安全</p>
          <p class="text" style="margin: 0;">
            WEB3CN在任何时候竭力保证客户的个人信息不被人擅自或意外取得、处理或删除。采取各种实际措施保证个人信息不会被保管超过合理的期限，本公司将遵守所有关于可辨识个人信息保存的法规要求。<br/>
            1、支持安全套接层协议和128位加密技术--这种加密技术是互联网上保护数据安全的行业标淮，让客户在进行用户管理、个人账户管理、充值等涉及敏感信息的操作时，信息被自动加密，然后才被安全地通过互联网发送出去。<br/>
            2、采取各种合适的物理、电子和管理方面的措施来保护数据，以实现对数据安全的承诺。<br/>
            3、采用集中的影像存储服务来保证合同等文件信息的存储，有效避免被篡改以及删除，并可以实现永久保存。<br/>
            4、网站之间的页面跳转以及数据的发送采用数字签名技术来保证信息以及来源的不可否认性。
          </p>
         
        </div>
      </div>
    </div>
    
    <footerView :ele="$refs.infoDetailScroll" :hideTop="true"/>
  </div>
</template>
<script>
import footerView from './../components/footerView'
export default {
  name: "detail",
  components: {
    footerView
  },
  data() {
    return {
      type: 1
    };
  },
  methods: {
   
  },
  mounted() {
    const res = this.$route.params.type;
    this.type = res || 1;
  },
 
};
</script>
<style lang="less">
.infobgView {
  width: 100%;
  position: relative;
  .infoBg {
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    height: calc(100% - 45px);
    z-index: 1;
  }
}
.infoDetail {
  position: relative;
  z-index: 2;
  padding-top: 45px;
  width: 1220px;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  .infoContent {
    width: 732px;
    padding-left: 10px;
    padding-bottom: 56px;
  }
  .title {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .title1 {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    line-height: 25px;
    margin-bottom: 7px;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    color: #737373;
    line-height: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }
  
}
</style>
  