<template>
  <div class="fast_h5_page">
    <div class="top_bar">
      <topInput />
    </div>
    <topTab :tabList="tabList"/>
    <p class="fast_time">2023-01-01 星期三</p>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <!-- hot -->
        
        <div class="fast_list">
          <div class="fast_item" v-for="item in 6" :key="item" @click="$router.push('/h5/fastDetail/1')">
            <div class="fast_item_time">
              <span></span>
              <p>15:33</p>
            </div>
            <p class="fast_item_title">数据：MakerDAO持有近5亿枚GUSD，占总流通量86%</p>
            <p class="fast_item_txt">1月3日消息，根据Nansen数据，MakerDAO GUSD PSM（锚定稳定模块）共持有493,233,160枚 GUSD，占GUSD总流通量86%。据悉，GUSD是Gemini公司发行的稳定币。 此前昨日消息，Gemini联创公开致信DCG创始人，要求其在1月8日前给出解决方案偿还所欠的9亿多美元，并称DCG欠Genesis约16.75亿美元。</p>
            <div class="fast_item_other">
              <div class="fast_item_other_left">
                <p>WEB</p><p>WAA</p><p>GDF</p>
              </div>
              <div class="fast_item_other_right">
                <img src="./../../../assets/h5Image/fastNewsCopy.png" alt="">
                <img src="./../../../assets/h5Image/fastNewsShare.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    
  </div>
</template>
<script>
import topInput from './../components/topInput';
import topTab from './../components/topTab'
export default {
  name: "detail",
  components: {
    topInput,
    topTab
  },
  data() {
    return {
      tabList: [
        {name:'WEB',id: 1},{name:'NFT',id: 2},{name:'公链',id: 3},{name:'WEB',id: 4},
        {name:'DAO',id: 5},{name:'Metaverse',id: 6},{name:'X2E',id: 7},{name:'WEB',id: 8},
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 20; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 60) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    clickTab(name, title) {
      console.log(name, title)
    },
  },
  mounted() {
    
  },
 
};
</script>
<style lang="less">
.fast_h5_page {
  padding-top: 64px;
  padding-bottom: 68px;
  .top_bar {
    width: 100%;
    height: 64px;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .fast_time {
    font-size: 13px;
    font-family: SFProText-Medium, SFProText;
    font-weight: 500;
    color: #9C9C9C;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
  }
  .fast_list {
    padding: 0 15px;
    .fast_item {
      width: 100%;
      padding-top: 16px;
      padding-left: 10px;
      border-left: 1px solid rgba(206,202,190,0.2);
      .fast_item_time {
        position: relative;
        margin-bottom: 10px;
        span {
          width: 7px;
          height: 7px;
          background: #F0B200;
          border-radius: 100%;
          position: absolute;
          left: -14px;
          top: 3px;
        }
        p {
          font-size: 13px;
          font-family: SFProText-Medium, SFProText;
          font-weight: 500;
          color: #CECABE;
        }
      }
      .fast_item_title {
        font-size: 15px;
        
        font-weight: 600;
        color: #444444;
        line-height: 21px;
        margin-bottom: 5px;
      }
      .fast_item_txt {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #9C9C9C;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .fast_item_other {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .fast_item_other_left {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          p {
            padding: 3px 13px;
            border-radius: 21px;
            background: rgba(206,202,190,0.2);
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #737373;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .fast_item_other_right {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 14px;
            margin-left: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
  