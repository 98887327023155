// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/index'
import '@/assets/css/style.css'
import 'element-ui/lib/theme-chalk/index.css';
import API from './assets/js/API'
import ElementUI from 'element-ui';
import utils from './utils/utils';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts
dayjs.extend(relativeTime);
dayjs.locale('zh-cn');
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(ElementUI);
Vue.use(Vant);

// 国际化
import VueI18n from 'vue-i18n'

Vue.use(VueI18n) ;
Vue.prototype.$utils = utils;
Vue.prototype.$api = API;
Vue.prototype.$dayjs = dayjs;
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  // i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
