<template>
  <div class="infinite-list-wrapper" style="overflow:auto;height:100vh" ref="deepScroll">
    <div class="columns_pc_page" v-infinite-scroll="load" :infinite-scroll-disabled="!listData.hasNext" :infinite-scroll-distance="100">
      <!-- <img src="@/assets/images/deepBanner.png" alt="" class="deepBanner"> -->
      <div class="authorInfo">
        <img src="@/assets/h5Image/downloadLogo.png" alt="">
        <div>
          <p class="infoName">{{ authorInfo.name }}</p>
          <p class="articelNum">{{ authorInfo.total }} <span>文章</span></p>
        </div>
      </div>
      <div class="columns_pc_content">
        <div class="columns_pc_page_left">
          <div v-if="listData.list.length">
            <div class="deepNews">
              <div class="article_view" v-for="(item,index) in listData.list" :key="index" @click="goDetail(item)">
                
                <articleItem :info="item"  :otherFlex="true" :noGoAuthor="true"/>
                <articleBorder :direction="index%2==0?'left':'right'" />
              </div>
            </div>
            <div class="moreDeepBtn" v-if="listData.hasNext">
              <span>更多深度文章</span>
              <div class="moreFastMsg_loading" v-if="loadMore">
                <van-loading color="#9c9c9c" size="44px"/>
              </div>
            </div>
          </div>
          <div class="deep_view loadingView" v-else>
            <van-loading color="#f0b200" v-if="listData.loading" :size="54"/>
            <p v-else>暂无数据</p>
          </div>
        </div> 
        <div class="columns_pc_page_right">
          <hotArticle />
        </div>
      </div>
    </div>
    <div style="height: 150px;"></div>
    <footerView :ele="$refs.deepScroll"/>
  </div>
</template>
<script>
import articleBorder from './../deep/articleBorder';
import articleItem from './../deep/articleItem';
import hotArticle from '../components/hotArticle';
import footerView from './../components/footerView'
import { getAuthor } from '@/api/index';
export default {
  name: "detail",
  components: {
    articleBorder,
    articleItem,
    hotArticle,
    footerView
  },
  data() {
    return {
      listData: {
        list: [],
        page: 1,
        size: 10,
        loading: true,
        hasNext: false,
      },
      authorInfo: {
        total: 0,
        name: ''
      },
      loadMore: false,
    };
  },
  methods: {
    goDetail(item) {
      // this.$router.push('/deepDetail/'+item.id)
      window.open(window.location.origin+'/deepDetail/'+item.id)
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      let params = {
          page: this.listData.page,
          size: this.listData.size,
          author: this.$route.params.id
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      getAuthor(params)
      .then(res=> {
        const data = res.data;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(data.items);
          this.loadMore = false;
        } else {
          this.listData.list = data.items;
          this.authorInfo = {
            total: data.total,
            name: data.items[0].author.name,
          }
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
      })
    },
    load() {
      if(!this.loadMore) {
        this.loadMore = true;
        setTimeout(() => {
          this.getListData(true)
        }, 500);
      }
    },
  },
  mounted() {
    this.getListData();
  },
 
};
</script>
<style lang="less">
.columns_pc_page {
  padding-top: 255px;
  position: relative;
  .authorInfo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 225px;
    background: url('./../../../assets/images/columnsBg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 113px;
      height: 113px;
      background: #FFFFFF;
      border-radius: 100%;
      margin-right: 30px;
    }
    .infoName {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #444444;
      margin-bottom: 23px;
    }
    .articelNum {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #737373;
      span {
        color: #737373;
      }
    }
  }
  .columns_pc_content {
    display: flex;
    width: 1220px;
    margin: 0 auto;
  }
  .columns_pc_page_left {
    flex: 1;
    .deepNews {}
    .moreDeepBtn {
      margin-left: 64px;
      margin-bottom: 15px;
      margin-top: 30px;
      height: 38px;
      line-height: 38px;
      background: #f3f2f0;
      border-radius: 19px;
      font-size: 15px;
      font-weight: 600;
      color: #9c9c9c;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #FFF6E6;
      }
      .moreFastMsg_loading {
        width: 15px;
        height: 100%;
        position: absolute;
        left: 58%;
        top: 0;
      }
    }
    .loadingView {
      width: 100%;
      min-height: calc(100vh - 240px);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        color: #ccc;
        text-align: center;
      }
    }
    .deep_view {
      padding: 15px 14px;
      width: 100%;
      min-height: calc(100vh - 240px);
      margin-bottom: 23px;
    }
  }
  .columns_pc_page_right {
    flex-shrink: 0;
    width: 374px;
    margin-left: 60px;
    
  }
}
</style>
  