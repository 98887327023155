<template>
  <div class="h5HomePage">
    <div class="top_bar">
      <div class="leftImg">
        <img src="./../../../assets/h5Image/logo.png" alt="" />
      </div>
      <div class="inputView" @click="$router.push('/h5/search')">
        <input type="text" readonly placeholder="搜索项目/机构/资讯"/>
        <div class="searchbtn"><img src="./../../../assets/h5Image/search_black.png" alt=""></div>
      </div>
    </div>
    <!-- tab -->
    <topTab :tabList="tabList" :top="64" @clickTab="clickTab"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <!-- swiper -->
      <div class="swiperView" v-if="bannerList.length">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item,index) in bannerList" :key="index">
            <div class="swiperItem" @click="goDeep(item)">
              <img :src="item.cover" alt="" />
              <p class="showline1">{{ item.title }}</p>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="listNodata" style="height: 192px;" v-else>
        <van-loading color="#f0b200" :size="34" v-if="swiperLoading"/>
        <p v-else>暂无数据</p>
      </div>
      <!-- hot -->
      <div v-if="listData.list.length">
        <van-list
          v-model="listData.loading"
          :finished="!listData.hasNext"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- hot -->
          <div class="fast_list">
            <div v-for="(item,index) in listData.list" :key="item.id" @click="goDetail(item)">
              <p class="fast_item_date" v-if="showDate(item,index)">{{ item.dateName }}</p>
              <div class="fast_item" :style="[!showBorder(item,index) && {border: 'none'}, showDate(item,index)&&{paddingTop:0}]">
                <div class="fast_item_time">
                  <span></span>
                  <p>{{ $dayjs(item.publishTime).format('HH:mm') }}</p>
                </div>
                <p class="fast_item_title "><span v-if="item.pinned" class="fast_item_title_pinned"></span>{{ item.title }}</p>
                <p class="fast_item_txt" v-html="item.excerpt"></p>
                <div class="fast_item_other">
                  <div style="display: flex;flex: 1;" v-if="item.relates && item.relates.length">
                    <div class="other_pro" v-for="rel in item.relates" @click.stop="goProject(rel)" :style="item.relates.length>1 ? {flex:1} : {minWidth: '65%'}">
                      <img :src="rel.icon" alt="">
                      <div>
                        <p class="other_pro_title showline1">{{ rel.title }}</p>
                        <span class="other_pro_tag" v-if="rel.tags && rel.tags.length">{{ rel.tags[0].name }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="other_pro" style="background: none;height: 10px;"></div>
                  <div class="fast_item_other_right">
                    <div @click.stop="copy(item)"><img src="./../../../assets/h5Image/home/copy.png" alt=""></div>
                    <div @click.stop="share(item)"><img src="./../../../assets/h5Image/home/share.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div class="listNodata" style="height: 200px;" v-else>
        <van-loading color="#f0b200" v-if="listData.loading" :size="34"/>
        <p v-else>暂无数据</p>
      </div>
    </van-pull-refresh>
    <div style="height: 20px"></div>
    <!-- 分享 -->
    <van-popup v-model="shareModal"  position="top"> 
      <!-- <div class="shareModal">
        <p class="shareTitle">分享至</p>
        <div class="shareIconView">
          <a title='twitter' :href="`https://twitter.com/intent/tweet?url=${weborigin +'/h5/fastDetail/'+ clickItem.id}&text=${clickItem.title}`"><img src="@/assets/h5Image/home/share1.png" alt=""><p>twitter</p></a>
          <div><img src="@/assets/h5Image/home/share3.png" alt="" @click="shareCopy"><p>复制链接</p></div>
          <div><img src="@/assets/h5Image/home/share4.png" alt="" @click="shareSave"><p>保存图片</p></div>
        </div>
      </div> -->
      <sharedialog :item="clickItem" ref="imgele" @close="shareModal=false"  v-if="shareModal" />
    </van-popup>
    <!-- 分享 -->
    
  </div>
</template>
<script>
import articleItem from './component/articleItem';
import topTab from './../components/topTab';
import sharedialog from './component/sharedialog';
import QRCode from 'qrcode';
import { getCategories, getList } from '@/api/index';
export default {
  name: "h5HomePage",
  components: {
    articleItem,
    topTab,
    sharedialog
  },
  data() {
    return {
      searchVal: '',
      tabList: [],

      finished: false,
      refreshing: false,
      swiperLoading: true,
      bannerList: [],
      tabIndex: 0,
      tabList: [],
      listData: {
        list: [],
        page: 1,
        size: 30,
        loading: true,
        hasNext: true
      },
      loadMore: false,
      weborigin: window.location.origin,
      clickItem: {},
      shareModal: false,
    };
  },
  watch: {
    tabIndex(newVal,oldVal) {
      this.listData = {
        list: [],
        page: 1,
        size: 30,
      }
      if(oldVal!==0) {
        this.getListData();
      }
    },
  },
  mounted() {
    this.getTabs();
    this.getBannerList();
  },
  activated() {
    const value = sessionStorage.getItem('fastPosition') || 0;
    console.log(value)
    setTimeout(() => {
      document.documentElement.scrollTop = value;
    }, 0);
  },
  methods: {
    goDetail(item) {
      const scrollTop = document.documentElement.scrollTop
      sessionStorage.setItem('fastPosition',scrollTop)
      this.$router.push('/h5/fastDetail/'+item.id)
    },
    showDate(item,index) {
      let type = false;
      if(index == 0) {
        type = true;
      } else {
        if(item.dateName != this.listData.list[index-1].dateName) {
          type = true
        }
      }
      return type
    },
    showBorder(item,index) {
      let type = true;
      if(index+1 == this.listData.list.length) {
        type = false
      } else {
        if(item.dateName != this.listData.list[index+1].dateName) {
          type = false
        }
      }
      
      return type
    },    
    getTabs() {
      getCategories('flash')
      .then(res=> {
        const { data } = res;
        if(data) {
          this.tabList = [{name:'全部',id: null}].concat(data.items);
          this.tabIndex = null;
          this.$nextTick(()=>{
            this.getListData();
          })
        }
      })
    },
    clickTab(val) {
      this.tabIndex = this.tabList[val].id;
    },
    getBannerList() {
      getList('article',{
        pinned: true
      })
      .then(res=>{
        const data = res.data;
        if(res.data) {
          this.bannerList = data.items;
        }
        this.swiperLoading = false;
      })
      .catch(()=>{
        this.swiperLoading = false;
      })
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      let params = {
        page: this.listData.page,
        size: this.listData.size, 
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      getList('flash',params)
      .then(res=> {
        const data = res.data;
        const result = data.items.map(item=>{
          return {
            ...item,
            dateName: this.$dayjs(item.publishTime).format('YY年MM月DD日') 
          }
        })
        if(loadMore) {
          this.listData.list = this.listData.list.concat(result);
          this.loadMore = false;
        } else {
          this.listData.list = result;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
        this.refreshing = false;
      })
    },
    onLoad() {
      if(!this.loadMore && this.listData.page!=1) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    onRefresh() {
      this.listData.page = 1;
      this.loadMore = false;
      this.$nextTick(()=>{
        this.getListData();
      })
    },
    copy(item) {
      this.$utils.copy(window.location.origin +'/h5/fastDetail/'+ item.id,()=>{
        this.$toast.success("复制成功");
      })
    },
    share(item) {
      this.clickItem = item;
      this.shareModal = true;
    },
    shareWx() {
      const str = window.location.origin +'/h5/fastDetail/'+ this.clickItem.id;
      QRCode.toDataURL(str)
      .then(url => {
        this.$dialog.alert({
          title: '微信扫一扫分享',
          message: `<img src="${url}"/>`,
        })
        this.shareModal = false;
      })
      .catch(err => {
        console.error(err)
      })
      
    },
    shareCopy() {
      this.copy(this.clickItem);
      this.shareModal = false;
    },
    shareSave() {
      this.$refs.imgele.downloadImg();
      this.shareModal = false;
    },
    goProject(rel) {
      const scrollTop = document.documentElement.scrollTop;
      sessionStorage.setItem('fastPosition',scrollTop);
      this.$router.push('/h5/projectDetail/'+rel.id);
    },
    goDeep(item) {
      this.$router.push('/h5/deepDetail/'+item.id);
    },
    
  },
};
</script>
<style lang="less">
.h5HomePage {
  padding-top: 64px;
  padding-bottom: 68px;
  // overflow: auto;
  .top_bar {
    width: 100%;
    height: 64px;
    background: url("./../../../assets/h5Image/tabbarTopBg1.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 25px;
    padding-right: 15px;
    box-sizing: border-box;
    .leftImg {
      width: 103px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .inputView {
      flex: 1;
      display: flex;
      align-items: center;
      height: 34px;
      background: rgba(255,255,255,1);
      border-radius: 17px;
      padding: 0 18px;
      input {
        width: 100px;
        flex: 1;
        border: none;
        outline: none;
        font-size: 12px;
        background: transparent;
        margin-right: 5px;
        font-weight: 600;
        color: #000;
      }
      input::-webkit-input-placeholder {
        color: #CCCCCB;
        font-size: 12px;
      }
      .searchbtn {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        img {
          width: 22px;
          height: 22px;
          display: block;
        }
      }
    }
  }
  .swiperView {
    width: 100%;
    padding: 4px 0;
    box-sizing: border-box;
    .swiperItem {
      width: 100%;
      height: 192px;
      padding: 0 15px;
      box-sizing: border-box;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      p {
        position: absolute;
        left: 15px;
        bottom: 0;
        width: calc(100% - 30px);
        background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, #000000 100%);
        border-radius: 0px 0px 10px 10px;
        height: 48px;
        padding-left: 18px;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: #FFF;
      }
    }
    .van-swipe__indicators {
      right: 19px;
      left: auto;
      bottom: 10px;
    }
  }
  .fast_item_date {
    font-size: 13px;
    font-weight: 500;
    color: #9C9C9C;
    padding-left: 19px;
    margin-bottom: 17px;
    margin-top: 16px;
    background: url('./../../../assets/h5Image/home/date.png') no-repeat left center / 13px 13px;
  }
  .fast_list {
    padding: 0 17px;
    .fast_item {
      margin: 0 2px;
      padding-top: 16px;
      padding-left: 15px;
      border-left: 1px solid rgba(206,202,190,0.2);
      .fast_item_time {
        position: relative;
        margin-bottom: 10px;
        span {
          width: 12px;
          height: 12px;
          background: #fff;
          border: 2px solid #F0B200;
          border-radius: 100%;
          position: absolute;
          left: -21px;
          top: 3px;
        }
        p {
          font-size: 13px;
          font-family: SFProText-Medium, SFProText;
          font-weight: 500;
          color: #CECABE;
        }
      }
      .fast_item_title {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        line-height: 30px;
        margin-bottom: 10px;
        text-align: justify;
        word-break: break-all;
        span {
          padding-right: 2px;
        }
      }
      .fast_item_title_pinned {
        width: 20px;
        height: 30px;
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        background: url('./../../../assets/images/collect.png') no-repeat left center / 20px 20px;
      }
      .fast_item_txt {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #444;
        line-height: 24px;
        margin-bottom: 10px;
        text-align: justify;
      }
      .fast_item_other {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .other_pro {
          max-width: 80%;
          display: flex;
          align-items: center;
          padding: 8px 5px;
          background: #FAF9F8;
          border-radius: 6px;
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            margin-right: 5px;
            border-radius: 4px;
          }
          .other_pro_title {
            font-size: 10px;
            font-weight: 500;
            color: #4A4A4A;
            line-height: 14px;
          }
          .other_pro_tag {
            padding: 2px 6px;
            background: #FFF6D8;
            color: #000;
            font-size: 10px;
            word-break: break-all;
          }
          .other_pro_intro {
            font-size: 10px;
            font-weight: 500;
            color: #9C9C9C;
            margin-top: 5px;
          }
        }
        .fast_item_other_right {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          margin-left: 10px;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 14px;
            &:first-child {
              margin-left: 0;
            }
          }
          img {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
  .van-popup {
    background: none;
  }
  .shareModal {
    padding-top: 10px;
    padding-bottom: 21px;
    background: #F8F8F8;
    border-radius: 10px 0 10px 0;
    .shareTitle {
      font-size: 13px;
      font-weight: 500;
      color: #9C9C9C;
      margin-bottom: 20px;
      text-align: center;
    }
    .shareIconView {
      display: flex;
      align-items: center;
      padding: 0 27px;
      margin-bottom: 14px;
      div,a {
        width: 48px;
        margin-right: 29px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        color: #9C9C9C;
        line-height: 16px;
        margin-top: 5px;
        text-align: center;
      }
      img {
        width: 48px;
        height: 48px;
        
      }
    }
    .shareBtn {
      margin: 0 15px;
      height: 46px;
      background: #FFFFFF;
      border-radius: 23px;
      font-size: 17px;
      font-weight: 500;
      color: #444444;
      text-align: center;
      line-height: 46px;
    }
  }
  .van-overlay {
    background: rgba(0,0,0,0.1);
  }
}

</style>
  