<template>
  <div class="project_detail_info_com">
    <div class="project_detail_h5_title">
      团队成员 <span>{{ info.members.length }}</span>
    </div>
    <div class="project_detail_h5_team" v-for="(item, index) in info.members" :key="index">
      <div class="detail_h5_team_name">
        <img :src="item.avatar" alt="">
        <p>{{ item.name }}</p>
      </div>
      <div class="detail_h5_team_link">
        <a :href="item.twitter" v-if="item.twitter" target="_blank"><img src="@/assets/images/detailTwitterIcon.png" alt=""></a>
        <a :href="item.linkin" v-if="item.linkin" target="_blank" class="linkImg"><img src="@/assets/images/detailInsIcon.png" alt=""></a>
        <a :href="item.medium" v-if="item.medium" target="_blank" class="linkImg"><img src="@/assets/images/detailmedium.png" alt=""></a>
      </div>
    </div>
    <div class="project_detail_h5_title" style="margin-top: 30px">
      投资机构 <span>{{ info.institutions.length }}</span>
    </div>
    <div class="project_detail_h5_orgtable">
      <div class="project_detail_h5_orgtable_item" v-for="(item, index) in info.institutions" :key="index" @click="$router.push(`/h5/organizationDetail/institution_${item.name.replace(/[^\w]|_/g, '').toLowerCase()}`)">
        <div class="item_top">
          <div class="item_top_left">
            <img :src="item.logo" alt="">
            <p>{{ item.name }}</p>
          </div>
          <div class="item_top_link" @click.stop="">
            <a :href="item.homepage" target="_blank" v-if="item.homepage"><img src="@/assets/images/orgDetail/web.png" alt=""></a>
            <a :href="item.discord" target="_blank" v-if="item.discord"><img src="@/assets/images/detailDiscordIcon.png" alt=""></a>
            <a :href="item.twitter" target="_blank" v-if="item.twitter"><img src="@/assets/images/detailTwitterIcon.png" alt=""></a>
          </div>
        </div>
        <div class="item_bottom">
          <div class="item_bottom_row">
            <p>类型:&nbsp;<span>{{ item.type }}</span></p>
            <p>所在地区:&nbsp;<span>{{ item.country }}</span></p>
          </div>
          <div class="item_bottom_row">
            <p>成立年份:&nbsp;<span>{{ item.establishTime }}</span></p>
            <p>投资组合:&nbsp;<span>{{ item.companyCount }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div style="height:30px"></div>
  </div>
</template>
<script>
export default {
  name: "detailinfoCom",
  props: ['info'],
  data() {
    return {
      
    };
  },
  methods: {
    clickShowInfo(item) {
      item.showInfo = !item.showInfo;
      this.$forceUpdate();
    }
  },
  mounted() {

  },

};
</script>
<style lang="less">
.project_detail_info_com {
  .project_detail_h5_title {
    font-size: 15px;

    font-weight: 600;
    color: #444444;
    margin-bottom: 19px;

    span {
      color: #CCCCCB;
      padding-left: 5px;
    }
  }

  .project_detail_h5_team {
    width: 100%;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(238, 238, 238, 1);
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 13px;
    padding-right: 18px;

    div {
      display: flex;
      align-items: center;
    }

    .detail_h5_team_name {
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 10px;
      }

      p {
        font-size: 13px;

        font-weight: 600;
        color: #444444;
      }
    }

    .detail_h5_team_link {
      img {
        width: 24px;
        height: 24px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }

  .project_detail_h5_orgtable {
    width: 100%;
    .project_detail_h5_orgtable_item {
      width: 100%;
      border-radius: 10px;
      border: 1px solid #EDEDED;
      overflow: hidden;
      margin-bottom: 10px;
      .item_top {
        width: 100%;
        height: 53px;
        background: #FAFAFA;
        border-bottom: 1px solid #EDEDED;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        .item_top_left {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            margin-right: 5px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
          }
        }
        .item_top_link {
          display: flex;
          align-items: center;
          a {
            width: 25px;
            height: 25px;
            margin-left: 9px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .item_bottom {
        padding: 13px 14px;
        background: #FFFFFF;
        .item_bottom_row {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            flex: 1;
            font-size: 12px;
            line-height: 17px;
            color: #444;
            span {
              color: #737373;
            }
          }
        }
      }
    }
  }
}</style>
  