<template>
  <div class="h5shareDialog" @click="close">
    <div class="tipsView">请长按图片，将本条快讯推荐给好友</div>
    <div class="shareContent" ref="imageTofile">
      <div class="topImg">
        <img src="@/assets/images/sharedialogImg1.png" alt="" class="left">
        <img src="@/assets/images/sharedialogImg2.png" alt="" class="right">
      </div>
      <div class="shareTime">
        <img src="@/assets/images/kxicon.png" alt="" v-if="!isDeep"/>
        <p>{{ $dayjs(item.publishTime).format('YYYY年MM月DD日 HH:mm') }}</p>
      </div>
      <p class="shareTitle">{{item.title}}</p>
      <div style="position: relative;">
        <p class="shareTxt" v-html="item.excerpt"></p>
        <img src="@/assets/h5Image/sharebg.png" alt="" class="sharetxtbg"/>
      </div>
      
      <p class="shareTips">风险提示：本文仅作为传递信息用途，不代表WEB3CN立场，且不构成投资理财建议。</p>
      
      <div class="shareBottom">
        <div class="left">
          <img src="@/assets/images/sharedialogBottomImg1.png" alt="" class="web3cnLogo" />
          <p>基于DID的精准内容分发<br/>更懂你的WEB3资讯平台</p>
        </div>
        <div class="right">
          <img src="@/assets/images/wx1.png" alt="" />
        </div>
      </div>
    </div>
    <div>
      <img :src="shareImg" alt="" class="shareImage" v-if="shareImg"/>
      <van-loading color="#f0b200" v-else :size="34"/>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import QRCode from 'qrcode';
import wx from 'jweixin-module';
import { getWXconfig } from '@/api/index';
export default {
  name: "detail",
  props: ['index', 'closeNotify', 'item', 'isDeep'],
  data() {
    return {
      wxCode: '',
      shareImg: '',
    };
  },
  computed: {
    shareUrl() {
      if(this.item) {
        return window.location.origin +'/fastDetail/'+ this.item.id
      }
      return ''
    }
  },
  methods: {
    close(e) {
      console.log(e.target.className)
      if (e.target.className != 'shareImage') {
        this.$emit('close');
      }
    },
    productImg() {
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        this.shareImg = url;
      })
    },
    downloadImg() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '下载中',
      });
      this.download(this.shareImg,(status)=>{
        this.$toast.clear();
        if(!status) {
          this.$toast('下载失败,请重试!')
        }
      });
    },
    download(Url,callback){
      try {
        var blob=new Blob([''], {type:'application/octet-stream'});
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = Url;
        a.download = 'web3cn_'+this.item.id;
        var e = document.createEvent('MouseEvents');
        e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
        URL.revokeObjectURL(url);
        callback(true);
      } catch (error) {
        callback(false);
      }
    },
    copyLink() {
      this.$utils.copy(this.shareUrl,()=>{
        this.$toast.success("复制成功");
      })
    },

    async initWechatSDK() {
      const res = await getWXconfig({url:  this.shareUrl});
      console.log(res)
      if(res.data) {
        const appid = res.data.appId; // 替换为您的应用ID
        const timestamp = res.data.timestamp; // 替换为生成签名的时间戳
        const nonceStr = res.data.nonceStr; // 替换为生成签名的随机串
        const signature = res.data.signature; // 替换为生成的签名

        wx.config({
          debug: false,
          appId: appid,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: ['updateTimelineShareData']
        });
        
        wx.ready(() => {
          this.setupShareButton();
        });
      }
    },

    setupShareButton() {
      // const shareButton = document.getElementById('shareButton');
      
      // shareButton.addEventListener('click', () => {
        wx.updateTimelineShareData({
          title: '测试',
          link:  this.shareUrl,
          imgUrl: 'https://web3cn.pro/logo.png',
          success: () => {
            // 分享成功的回调函数
          },
          cancel: () => {
            // 用户取消分享的回调函数
          }
        });
      // });
    }
  },
  mounted() {
    console.log(2222)
    const str = this.shareUrl;
    QRCode.toDataURL(str)
    .then(url => {
      this.wxCode = url;
      this.productImg();
      // this.initWechatSDK();
    })
    .catch(err => {
      console.error(err)
    })
  },
 
};
</script>
<style lang="less">
.h5shareDialog {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  position: relative;
  padding-top: 60px;
  .tipsView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 58px;
    background: #FFF6D8;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #F0B200;
    line-height: 58px;
    text-align: center;
  }
  .shareImage {
    width: 350px;
    height: auto;
  }
  .shareContent {
    position: absolute;
    left: -100000px;
    z-index: -10;
    width: 350px;
    padding-bottom: 30px;
    background-color: #FAFAFA;
    .topImg {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 2rem;
      position: relative;
      .left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      }
      .right {
        width: 68px;
        height: 86px;
        position: relative;
        z-index: 2;
      }
    }
    .shareTime {
      position: relative;
      z-index: 2;
      padding-left: 29px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 50px;
        height: 23px;
        margin-right:8px;
      }
      p {
        font-size: 12px;
        font-family: SFProText-Semibold, SFProText;
        font-weight: 600;
        color: #9C9C9C;
        transform: scale(0.9);
      }
    }
    .shareTitle {
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      box-sizing: border-box;
      font-size: 17px;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #000;
      line-height: 24px;
      -webkit-text-stroke: 0px #000;
      text-stroke: 0px #000;
      margin-bottom: 13px;
      text-align: justify;
      position: relative;
      z-index: 2;
    }
    .shareTxt {
      position: relative;
      z-index: 2;
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      font-size: 13px;
      font-family: SFProText-Medium, SFProText;
      font-weight: 500;
      color: #4a4a4a;
      line-height: 23px;
      margin-bottom: 40px;
      word-break: break-all;
      text-align: justify;
      p {
        text-align: justify;
      }
    }
    .sharetxtbg {
      position: absolute;
      bottom: -50px;
      right: 0;
      width: 250px;
      height: auto;
      z-index: 1;
    }
    .shareTips {
      position: relative;
      z-index: 2;
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      box-sizing: border-box;
      font-size: 12px;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #9C9C9C;
      line-height: 18px;
      margin-bottom: 15px;
    }
    .web3cnLogo {
      width: 109px;
      height: 29px;
      display: block;
    }
    .shareBottom {
      padding-left: 29px;
      padding-right: 25px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          font-size: 12px;
          font-family: SFProText-Semibold, SFProText;
          font-weight: 600;
          color: #444444;
          line-height: 16px;
          letter-spacing: 3px;
        }
      }
      .right {
        img {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
  .shareTool {
    margin-top: 30px;
    width: 350px;
    display: flex;
    justify-content: flex-end;
    .toolView {
      width: 186px;
      height: 34px;
      background: rgba(0,0,0,0.2);
      border-radius: 18px;
      display: flex;
      align-items: center;
      .toolItem {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        img {
          width: 17px;
        }
        
      }
      .wxShare {
        &:hover {
          .wxShareCode {
            display: block;
          }
        }
        .wxShareCode {
          position: absolute;
          left: 50%;
          margin-left: -30px;
          bottom: -65px;
          width: 60px;
          height: 60px;
          border-radius: 8px;
          background: #fff;
          display: none;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      } 
    }
  }
}
</style>
  