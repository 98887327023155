<template>
  <div class="h5_top_tab">
    <van-sticky :offset-top="top">
      <van-tabs title-active-color="#ffffff"  @click="changeTab">
        <van-tab v-for="(item,index) in tabList" :key="index" :title="item.name">
        </van-tab>
      </van-tabs>
    </van-sticky>
  </div>
</template>

<script>
export default {
  name: "toptab",
  props: {
    tabList: {
      default: [],
      type: Array
    },
    top: {
      default: 44,
      type: Number
    }
  },
  data() {
    return {};
  },
  created() {
    // 

  },
  
  mounted() {
  },
  methods: {
    changeTab(val) {
      this.$emit('clickTab',val)
    }
  },
  
};
</script>

<style lang="less">
.h5_top_tab {
  .van-tab {
    font-size: 13px;
    font-weight: 500;
  }
  .van-tabs__line {
    display: none;
  }
  .van-tab--active {
    height: 19px;
    border-radius: 14px;
    background: #F0B200;
    margin: 12px 0;
  }
  .van-tabs__nav--line {
    padding-bottom: 0;
  }
}
</style>