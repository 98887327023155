<template>
  <div class="project_detail_h5_page">
    <!-- <van-sticky>
      <van-nav-bar
        title="项目详情"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </van-sticky> -->
    <van-sticky>
      <topInput />
    </van-sticky>
    <div class="project_detail_h5_content">
      <div class="detail_top">
        <div class="project_detail_h5_logo">
          <img :src="info.icon"  alt="">
        </div>
        <div class="projectInfo">
          <div class="info1">
            <p>{{ info.title }}</p>
          </div>
          <p class="info2">{{ info.excerpt }}</p>
          <div class="info3" v-if="info.tags && info.tags.length">
            <span>{{ info.tags[0].name }}</span>
          </div>
          <div class="project_detail_h5_link">
            <a :href="info.contact && info.contact.homepage" target="_blank" v-if="info.contact && info.contact.homepage"><img src="@/assets/images/orgDetail/web.png" alt=""></a>
            <a :href="info.contact && info.contact.telegram" target="_blank" v-if="info.contact && info.contact.telegram"><img src="@/assets/images/detailAirIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.twitter" target="_blank" v-if="info.contact && info.contact.twitter"><img src="@/assets/images/detailTwitterIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.facebook" target="_blank" v-if="info.contact && info.contact.facebook"><img src="@/assets/images/detailFaceIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.discord" target="_blank" v-if="info.contact && info.contact.discord"><img src="@/assets/images/detailDiscordIcon.png" alt=""></a>
            <a :href="info.contact && info.contact.medium" target="_blank" v-if="info.contact && info.contact.medium" ><img src="@/assets/images/detailmedium.png" alt=""></a>
          </div>
        </div>
      </div>
      <van-sticky :offset-top="64">
        <div class="tabView">
          <p :class="activeName==1 && 'chooseTab'" @click="activeName=1">项目动态</p>
          <p :class="activeName==2 && 'chooseTab'" @click="activeName=2">项目信息</p>
          <p :class="activeName==3 && 'chooseTab'" @click="activeName=3" v-if="info.unlocks || info.token">经济模型</p>
        </div>
      </van-sticky>
      <div class="base_info">
        <!-- <div class="base_info_title">
          
        </div> -->
        <div class="base_info_intro">
          <p :class="showMore && 'showline3'" >
            <span ref="moreText" v-html="info.content"></span>
          </p>
          <div class="showMoreBtn" @click="showMore = !showMore" v-if="isMoreBtn">
            <p v-if="showMore">...</p>
            <img src="@/assets/images/projectDetail/bottom.png" :style="!showMore&& {transform: 'rotate(180deg)'}" alt="">
          </div>
        </div>
      </div>
      <!-- <div class="downloadFile" v-if="activeName==1">
        <div class="fileItem">
          <img src="@/assets/images/projectDetail/baogao.png" alt="" />
          <div class="fileTxt">
            <p class="fileName">项目投研报告</p>
            <p class="fileLink">
              <span>点击查看</span>
            </p>
          </div>
        </div>
        <div class="fileItem" style="margin-left: 10px">
          <img src="@/assets/images/projectDetail/gonglue.png" alt="" />
          <div class="fileTxt">
            <p class="fileName">空投攻略</p>
            <p class="fileLink">
              <span>点击查看</span>
            </p>
          </div>
        </div>
      </div> -->
      <div class="tabContent">
        <projectDynamic v-if="activeName==1"/>
        <projectInfo v-if="activeName==2" :info="info"/>
        <economic v-if="activeName==3" :info="info"/>
      </div>
    </div>
  </div>
</template>
<script>
import projectDynamic from './projectDynamic';
import projectInfo from './projectInfo';
import economic from './economic';
import topInput from './../components/topInput';
import { getProjectDetail } from '@/api/index';
export default {
  name: "detail",
  components: {
    projectDynamic,
    projectInfo,
    economic,
    topInput
  },
  data() {
    return {
      activeName: '1',
      showMore: true,
      info: {
        tags: [],
        content: '',
        contact: {
          homepage: '',
          telegram: '',
          twitter: '',
          discord: '',
          facebook: ''
        },
        members: [],
        institutions: [],
      },
      isMoreBtn: false
    };
  },
  watch: {
    "info.content"() {
      if(this.info.content) {
        setTimeout(() => {
          const height = this.$refs.moreText.offsetHeight;
          if(height*1 > 69) {
            this.isMoreBtn = true
          }
        },0);
      }
    }
  },
  methods: {
    getDetailData(id) {
      getProjectDetail(id)
      .then(res=>{
        this.$utils.shareWX({title: res.data.title+'-'+res.data.excerpt.replace(/<\/?p[^>]*>/g, ''),intro:'',img: res.data.icon,url: window.location.href});
        document.title = res.data.title+'-'+res.data.excerpt.replace(/<\/?p[^>]*>/g, '');
        this.info = res.data;
      })
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if(id) {
      this.getDetailData(id);
    }
  },
 
};
</script>
<style lang="less">
.project_detail_h5_page {
  .van-nav-bar .van-icon {
    color: #444444;
    font-size: 22px;
  }
  .van-nav-bar__title{
    font-size: 17px;
    font-weight: 500;
    color: #444444;
  }
  .project_detail_h5_content {
    
    .detail_top {
      padding: 15px 0;
      background: url('./../../../assets/images/projectDetail/introducebg.png') no-repeat;
      background-size: 100% 100%;
    }
    .project_detail_h5_logo {
      margin: 0 auto;
      width: 55px;
      height: 55px;
      margin-bottom: 10px;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .projectInfo {
      .info1 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        p {
          font-size: 18px;
          font-weight: 600;
          color: #000;
          line-height: 23px;
        }
      }
      .info3 {
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        span {
          padding: 2px 8px;
          background: #FFF6D8;
          font-size: 11px;
          color: #000;
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .info2 {
        width: 80%;
        margin: 0 auto;
        font-size: 12px;
        font-weight: 600;
        color: #444;
        line-height: 17px;
        text-align: center;
        margin-bottom: 12px;
      }
      .project_detail_h5_link {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          width: 19px;
          height: 19px;
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .tabView {
      width: 100%;
      height: 39px;
      background: #FFFFFF;
      display: flex;
      box-shadow: 0px 2px 4px 0px rgba(238,238,238,1);
      p {
        flex: 1;
        line-height: 39px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        box-sizing: border-box;
      }
      .chooseTab {
        color: #F0B200;
        box-shadow: 0px 2px 4px 0px rgba(238,238,238,1);
      }
    }
    .base_info {
      padding: 22px 15px;
      .base_info_intro {
        width: 100%;
        background: #FFFFFF;
        position: relative;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #444;
          line-height: 20px;
          word-break: break-all;
          text-align: justify;
        }
        .showMoreBtn {
          height: 20px;
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          background: #FFFFFF;
          cursor: pointer;
          p {
            color: #737373;
            font-size: 14px;
            
          }
          img {
            width: 12px;
            height: 8px;
            margin-left: 5px;
            transition: .5s;
          }
        }
      }
    }
    .downloadFile {
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .fileItem {
        flex: 1;
        height: 62px;
        background: #FAFAFA;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        .fileTxt {
          .fileName {
            font-size: 14px;
            font-weight: 600;
            color: #4A4A4A;
            line-height: 19px;
          }
          .fileLink {
            font-size: 12px;
            font-weight: 600;
            color: #9C9C9C;
            line-height: 17px;
            cursor: pointer;
            span {
              color: #F0B200;
            }
          }
        }
        img {
          width: 40px;
          margin-right: 10px;
        }
      }
    }
    .tabContent {
      margin-top: 19px;
      width: 100%;
      padding: 0 15px;
    }
  }
}
</style>
  