<template>
  <div class="newFastmessage">
    <div class="fastMsgTitle">
      <p>最新快讯</p>
      <span>{{$dayjs(new Date()).format('YYYY年MM月DD日')}}星期{{ weekMap[$dayjs(new Date()).day()]}}</span>
    </div>
    <div v-if="fastMsgList.length">
      <div class="fastMsgList">
        <div class="fastMsgItem" v-for="(item, index) in fastMsgList" :key="index"  @click="$router.push('/fastDetail/'+item.id)">
          <img src="@/assets/images/fastmsgItemIcon.png" alt="" class="fastMsgItemIcon">
          <p class="fastMsgItemTxt">{{ item.title }}</p>
          <p class="fastMsgItemTime">{{ $dayjs(new Date(item.publishTime)).fromNow() }}</p>
        </div>
      </div>
      <div class="moreFastMsg"  @click="$router.push('/home')">更多快讯</div>
    </div>
    <div class="loadingView" v-else>
      <van-loading color="#f0b200" v-if="loading" :size="54"/>
      <p v-else>暂无数据</p>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/index';
export default {
  name: "newfast",
  props: [],
  data() {
    return {
      weekMap: {
        '0': '日',
        '1': '一',
        '2': '二',
        '3': '三',
        '4': '四',
        '5': '五',
        '6': '六',
      },
      fastMsgList: [],
      loading: true
    }
  },
  methods: {

  },
  mounted() {
    getList('flash',{
      page: 1,
      size: 5,
    })
    .then(res=> {
      const data = res.data;
      this.fastMsgList = data.items;
      this.loading = false;
    })
  },

};
</script>
<style lang="less" scoped>
.newFastmessage {
  padding: 15px 14px;
  width: 100%;
  background: linear-gradient(180deg, rgba(249,217,0,0.2) 0%, rgba(240,178,0,0.1) 100%);
  border-radius: 11px;
  margin-bottom: 23px;

  .fastMsgTitle {
    width: 100%;
    height: 37px;
    background: url('./../../../assets/images/fastmsgTitleIcon.png') no-repeat left center / auto 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 23px;

    p {
      font-size: 18px;
      font-weight: 600;
      color: #000;
      margin-right: 30px;
    }

    span {
      font-size: 15px;
      font-weight: 500;
      color: #737373;
    }
  }

  .fastMsgList {
    .fastMsgItem {
      width: 100%;
      padding-bottom: 30px;
      padding-left: 14px;
      position: relative;
      border-left: 2px solid rgba(206, 202, 190, 0.2);
      box-sizing: border-box;
      cursor: pointer;
      .fastMsgItemIcon {
        position: absolute;
        left: -6px;
        top: 0;
        width: 12px;
        height: 11px;
      }

      .fastMsgItemTxt {
        font-size: 15px;
        font-weight: 600;
        color: #000;
        line-height: 21px;
        margin-bottom: 7px;
        text-align: justify;
      }

      .fastMsgItemTime {
        font-size: 12px;
        font-weight: 500;
        color: #4a4a4a;
      }

      &:last-child {
        border: none;
      }
      &:hover {
        .fastMsgItemTxt {
          color: #737373;
        }
        .fastMsgItemTime { 
          color: #9c9c9c;
        }
      }
    }
  }
  .loadingView {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      color: #ccc;
      text-align: center;
    }
  }
  .moreFastMsg {
    width: 285px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 11px;
    line-height: 38px;
    font-size: 15px;
    font-weight: 600;
    color: #9C9C9C;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    cursor: pointer;
  }
}</style>
