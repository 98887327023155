<template>
  <div class="deep_h5_page">
    <div class="top_bar">
      <topInput />
    </div>
    <topTab :tabList="tabList" :top="64" @clickTab="clickTab"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
     
      <div v-if="listData.list.length">
        <van-list
          v-model="listData.loading"
          :finished="!listData.hasNext"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="deep_list">
            <div class="deep_item" v-for="item in listData.list" :key="item.id" @click="goDetail(item)">
              <listItem :info="item" :canGoAuthor="true"/>
            </div>
          </div>
        </van-list>
      </div>
      <div class="listNodata" style="height: 200px;" v-else>
        <van-loading color="#f0b200" v-if="listData.loading" :size="34"/>
        <p v-else>暂无数据</p>
      </div>
    </van-pull-refresh>
    
  </div>
</template>
<script>
import topInput from './../components/topInput';
import topTab from './../components/topTab';
import listItem from './component/listItem';
import { getCategories, getList } from '@/api/index';
export default {
  name: "detail",
  components: {
    topInput,
    topTab,
    listItem
  },
  data() {
    return {
      tabIndex: '',
      tabList: [],
      listData: {
        list: [],
        page: 1,
        size: 30,
        loading: true,
        hasNext: false,
      },
      loadMore: false,
      refreshing: false,
    };
  },
  watch: {
    tabIndex(newVal,oldVal) {
      if(oldVal!=='') {
        this.listData = {
          list: [],
          page: 1,
          size: 30,
          loading: true
        }
        this.$nextTick(()=>{
          this.getListData();
        })
      }
    },
  },
  mounted() {
    this.getTabs();
  },
  activated() {
    const value = sessionStorage.getItem('deeepPosition') || 0;
    console.log(value)
    setTimeout(() => {
      document.documentElement.scrollTop = value;
    }, 0);
  },
  methods: {
    clickTab(val) {
      this.tabIndex = this.tabList[val].id;
    },
    goDetail(item) {
      const scrollTop = document.documentElement.scrollTop
      sessionStorage.setItem('deeepPosition',scrollTop)
      this.$router.push('/h5/deepDetail/'+item.id)
    },
    getListData(loadMore) {
      if(!loadMore) {
        this.listData.loading = true;
      }
      let params = {
          page: this.listData.page,
          size: this.listData.size,
          category: this.tabIndex
      }
      if(this.tabIndex !== null ) {
        params.category = this.tabIndex
      }
      getList('article',params)
      .then(res=> {
        const data = res.data;
        if(loadMore) {
          this.listData.list = this.listData.list.concat(data.items);
          this.loadMore = false;
        } else {
          this.listData.list = data.items;
        }
        this.listData.page = this.listData.page+1;
        this.listData.hasNext = data.hasNext;
        this.listData.loading = false;
        this.refreshing = false;
      })
    },
    getTabs() {
      getCategories('article')
      .then(res=> {
        const { data } = res;
        if(data) {
          if(data.items.length) {
            this.tabList = [{name:'全部',id: null}].concat(data.items);
            this.tabIndex = null;
          }
          this.getListData();
          
        }
      })
    },
    onLoad() {
      if(!this.loadMore && this.listData.page!=1) {
        this.loadMore = true;
        this.getListData(true)
      }
    },
    onRefresh() {
      this.listData.page = 1;
      this.loadMore = false;
      this.$nextTick(()=>{
        this.getListData();
      })
    },
  },
 
};
</script>
<style lang="less">
.deep_h5_page {
  padding-top:64px;
  padding-bottom: 68px;
  .top_bar {
    width: 100%;
    height:64px;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .deep_list {
    padding-left: 16px;
    padding-right: 14px;
    .deep_item {

    }
  }
}
</style>
  