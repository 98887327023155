<template>
  <div class="h5home">
    <router-view />
    <div class="tabBar">
      <van-tabbar v-model="active">
      <van-tabbar-item replace to="/h5/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? homeIcon.active : homeIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/h5/deep">
        <span>深度</span>
        <template #icon="props">
          <img :src="props.active ? deepIcon.active : deepIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/h5/project" id="specialTabItem">
        <span>项目库</span>
        <template #icon="props">
          <img :src="props.active ? proIcon.active : proIcon.inactive" style="width: 19px"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/h5/organization">
        <span>机构</span>
        <template #icon="props">
          <img :src="props.active ? orgIcon.active : orgIcon.inactive" style="width: 19px"/>
        </template>
      </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5home",
  components: {
  },
  data() {
    return {
      active: 0,
      homeIcon: {
        active: require('./../../assets/h5Image/tabbar/home2.png'),
        inactive: require('./../../assets/h5Image/tabbar/home1.png'),
      },
      deepIcon: {
        active: require('./../../assets/h5Image/tabbar/deep2.png'),
        inactive: require('./../../assets/h5Image/tabbar/deep1.png'),
      },
      orgIcon: {
        active: require('./../../assets/h5Image/tabbar/topic2.png'),
        inactive: require('./../../assets/h5Image/tabbar/topic1.png'),
      },
      proIcon: {
        active: require('./../../assets/h5Image/tabbar/data2.png'),
        inactive: require('./../../assets/h5Image/tabbar/data1.png'),
      },
    };
  },
  created() {
    // 

  },
  watch: {
    "$route.path"() {
      let routeName = this.$route.name;
      console.log(routeName)
      // if(routeName.includes('pcsearch')) { // 
      //   routeName = localStorage.getItem('ROUTE_NAME') || 'pchome';
      // }
      if(routeName.includes('h5home')) {
        this.active = 0;
      }
      if(routeName.includes('h5deep')) {
        this.active = 1;
      }
      if(routeName.includes('h5project')) {
        this.active = 2;
      }
      if(routeName.includes('h5organization')) {
        this.active = 3;
      }
    }
  },
  mounted() {
    this.getRoute();
  },
  methods: { 
    getRoute() {
      let routeName = this.$route.name;
      console.log(routeName)
      // if(routeName.includes('pcsearch')) { // 
      //   routeName = localStorage.getItem('ROUTE_NAME') || 'pchome';
      // }
      if(routeName.includes('h5home')) {
        this.active = 0;
      }
      if(routeName.includes('h5deep')) {
        this.active = 1;
      }
      if(routeName.includes('h5project')) {
        this.active = 2;
      }
      if(routeName.includes('h5organization')) {
        this.active = 3;
      }
    },
  },
  
};
</script>

<style lang="less">
.h5home {
  width: 100%;
  min-height: 100vh;
  .tabBar {
    .van-tabbar {
      height: 68px;
    }
    .van-tabbar-item__icon {
      margin-bottom: 8px;
      img {
        width: 18px;
        height: auto;
      }
    }
    #specialTabItem {
      .van-tabbar-item__icon {
        margin-bottom: 6px;
      }
    }
    .van-tabbar-item {
      font-size: 10px;
      font-weight: 600;
      color: #444444;
    }
    .van-tabbar-item--active {
      font-size: 10px;
      font-weight: 600;
      color: #F0B200;
    }
  }

  
  
}
</style>