
const utils = {
  chunk(list, size) {
    let len = list.length;
    if (size < 1 || !len) {
        return [];
    }
    if (size > len) {
        return [list];
    }
    // 最终返回数组
    let res = [];
    // 向下取整
    let integer = Math.floor(len / size);
    // 取余
    let rest = len % size;
    for (let i = 1; i <= integer; i++) {
        res.push(list.splice(0, size));
    }
    if (rest) {
        res.push(list.splice(0, rest));
    }
    return res;
  },
  copy (text,callback) {
    // text是复制文本
    // 创建input元素
    const el = document.createElement('input')
    // 给input元素赋值需要复制的文本
    el.setAttribute('value', text)
    // 将input元素插入页面
    document.body.appendChild(el)
    // 选中input元素的文本
    el.select()
    // 复制内容到剪贴板
    document.execCommand('copy')
    // 删除input元素
    document.body.removeChild(el)
    callback();
  },
  shareWX({title,intro,img,url}) {
    var metaTitle = document.querySelector('meta[property="og:title"]');
    var metaDesc = document.querySelector('meta[property="og:description"]');
    var metaImage = document.querySelector('meta[property="og:image"]');
    var metaUrl = document.querySelector('meta[property="og:url"]');
    var metaSiteName = document.querySelector('meta[property="og:site_name"]');

    metaTitle.setAttribute('content', title);
    metaDesc.setAttribute('content', intro);
    metaImage.setAttribute('content', img);
    metaUrl.setAttribute('content', url);
    metaSiteName.setAttribute('content', title);
  },
}
export default Object.freeze(utils)