<template>
  <div class="article_h5_home">
   <div class="article_h5_content">
      <p class="article_h5_content_txt">{{info.title}}</p>
      <img :src="info.img" v-if="info.img" alt=""  class="article_h5_content_img"/>
   </div>
   <p class="article_h5_author">{{info.author}}·{{info.time}}</p>
  </div>
</template>

<script>
export default {
  name: "article",
  props: {
    info: {
      default: {},
      propsType: Object
    }
  },
  data() {
    return {
      kx: require('./../../../../assets/h5Image/homekxicon.png'),
      sd: require('./../../../../assets/h5Image/homesdicon.png'),
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
  
};
</script>
<style lang="less">
.article_h5_home {
  padding-top: 16px;
  padding-bottom: 13px;
  width: 100%;
  border-top: 1px solid rgba(206,202,190,0.2);
  .article_h5_typeIcon {
    width: 63px;
    height: 15px;
    margin-bottom: 11px;
  }
  .article_h5_content {
    display: flex;
    margin-bottom: 5px;
    .article_h5_content_txt {
      font-size: 15px;
      
      font-weight: 600;
      color: #444444;
      line-height: 24px;
      flex: 1;
      word-break: break-all;
    }
    .article_h5_content_img {
      flex-shrink: 0;
      border-radius: 10px;
      width: 114px;
      height: 75px;
      margin-left: 10px;
    }
  }
  .article_h5_author {
    font-size: 12px;
    font-family: SFProText-Medium, SFProText;
    font-weight: 500;
    color: #CCCCCB;
    line-height: 13px;
  }

}
</style>
